import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FjdBackdrop, FjdSpacer, FjdSpinner } from "fjd-react-components";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { Stack } from "../../../../../common/components/Stack/Stack";
import { Heading } from "../../../../../common/components/Heading/Heading";
import { Dropdown } from "../../../../../common/components/Dropdown/Dropdown";
import { Button } from "../../../../../common/components/Button/Button";
import { capitalizeFirstLetter } from "../../../../../common/utils/StringUtils";
import { Modal } from "../../../../../common/components/Modal/Modal";
import { useNotification } from "../../../../../common/libs/notifications/hooks/useNotification";
import "./AddRightOfUseModal.css";
import { addRightOfUse } from "../../../../api/rightOfUse/rightOfUseApi";
import { useFetchOnlineDiensteTemplates } from "../../../../api/onlinedienste/hooks/useFetchOnlineDiensteTemplates";
import { fetchArsData } from "../../../../api/catalog/catalogApi";
import { MunicipalDirectoryRecord } from "../../../../api/catalog/Catalog";
import { UserContext } from "../../../../../common/libs/authentication/context/UserContext";
import { SearchFilterBar } from "../../../../../common/components/SearchFilterBar/SearchFilterBar";
import { OnlineServiceTemplateFlat } from "../../../../api/onlinedienste/OnlineServicesV1";
import { formatRegionalKeyAndPopulation } from "../../../../../common/utils/MunicipalDirectoryUtils";
import { Operator } from "../../../../api/organizations/Organizations";
import { fetchOperators } from "../../../../api/organizations/organizationsApi";

interface FormResult {
  operator?: Operator | null;
  onlineService?: OnlineServiceTemplateFlat | null;
  municipalDirectoryRecord?: MunicipalDirectoryRecord | null;
}

interface ModalProps {
  isModalVisible: boolean;
  handleModalClose: () => void;
  initialFormValues?: FormResult;
}

export function AddRightOfUseModal({ isModalVisible, handleModalClose, initialFormValues }: ModalProps) {
  const { t } = useTranslation();
  const [formResult, setFormResult] = useState<FormResult | null>(null);

  const { notifyUserFromResponse, notifyUser } = useNotification();
  const { selectedOrganizationId } = useContext(UserContext);

  const onSearchComplete = (
    key: keyof FormResult,
    object: OnlineServiceTemplateFlat | MunicipalDirectoryRecord | null
  ) => {
    setFormResult({ ...formResult, [key]: object });
  };

  const closeModal = () => {
    setFormResult(null);
    handleModalClose();
  };

  const { data: onlineDiensteTemplates } = useFetchOnlineDiensteTemplates();

  const { data: operators } = useQuery<Operator[], AxiosError>({
    retryDelay: 5000,
    queryKey: [fetchOperators.name],
    queryFn: fetchOperators
  });

  const { data: arsData } = useQuery({
    queryKey: [fetchArsData.name],
    queryFn: fetchArsData
  });

  const { isLoading: isCreateRightOfUseLoading, mutate: createRightOfUse } = useMutation({
    mutationKey: [addRightOfUse],
    mutationFn: addRightOfUse,
    onSettled: notifyUserFromResponse({ successMessage: t("rightsOfUse.successfullyCreated") }),
    onSuccess: closeModal
  });

  const addRightOfUseCandidate = () => {
    if (!formResult?.operator) notifyUser({ title: t("rightsOfUse.operatorIsMissing"), intent: "error" });
    else if (!formResult?.onlineService) notifyUser({ title: t("rightsOfUse.identifierIsMissing"), intent: "error" });
    else if (!formResult?.municipalDirectoryRecord)
      notifyUser({ title: t("rightsOfUse.areaOfUseInputFieldIsEmpty"), intent: "error" });
    else if (!selectedOrganizationId) notifyUser({ title: t("user.selectedOrganizationIsMissing"), intent: "error" });
    else {
      createRightOfUse({
        onlineServiceId: formResult.onlineService.id,
        operatorId: formResult.operator.id,
        regionalKey: formResult.municipalDirectoryRecord.regionalKey,
        producerId: selectedOrganizationId
      });
    }
  };

  return (
    <Modal
      closable
      onClose={closeModal}
      open={isModalVisible}
      closeOnBackdropClick
      heading={t("rightsOfUse.modalHeading")}
      primaryButtonLabel={t("rightsOfUse.createRightOfUse")}
      onPrimaryButtonClick={addRightOfUseCandidate}
      secondaryButtonLabel={t("rightsOfUse.modalAboard")}
      onSecondaryButtonClick={closeModal}
    >
      <Stack spacing="xxl">
        <Stack spacing="s">
          <Heading level={3} text={t("rightsOfUse.modalOperator")} />
          <Dropdown
            label={formResult?.operator?.name || t("rightsOfUse.modalChoose")}
            icon="caret-down"
            className="chooseOperator"
          >
            {operators?.map((operator: Operator) => (
              <Button
                key={operator.id}
                className="chooseOperatorButton"
                appearance="primary-link"
                label={capitalizeFirstLetter(operator.name)}
                onClick={() => setFormResult({ ...formResult, operator })}
              />
            ))}
          </Dropdown>
        </Stack>

        <Stack spacing="s">
          <Heading level={3} text={t("rightsOfUse.modalOnlineServices")} />
          <SearchFilterBar<OnlineServiceTemplateFlat>
            searchIn={["identifier"]}
            initialSearchInput={formResult?.onlineService?.identifier}
            placeholder={t("rightsOfUse.searchPlaceholderOnlineServices")}
            data={onlineDiensteTemplates ?? ([] as OnlineServiceTemplateFlat[])}
            initialMenuItemToSelect={(item) =>
              initialFormValues?.onlineService?.id ? item.id === initialFormValues.onlineService.id : false
            }
            menuItemLabel={(result) => `${result.identifier}`}
            onMenuItemSelected={(result) => onSearchComplete("onlineService", result)}
            searchDelay={200}
            appearance="relative"
          />
        </Stack>
        <Stack spacing="s">
          <Heading level={3} text={t("rightsOfUse.areaOfUse")} />
          <SearchFilterBar<MunicipalDirectoryRecord>
            searchIn={["municipalName", "regionalKey"]}
            initialSearchInput={formResult?.municipalDirectoryRecord?.municipalName}
            placeholder={t("rightsOfUse.searchPlaceholderAreaOfUse")}
            data={arsData ?? ([] as MunicipalDirectoryRecord[])}
            menuItemLabel={(result) =>
              `${result.textIndicatorDescription?.concat(" ") ?? ""}${
                result.municipalName
              } ${formatRegionalKeyAndPopulation(result.regionalKey, result.population, result.zipCode)}`
            }
            searchDelay={200}
            appearance="relative"
            onMenuItemSelected={(result) => onSearchComplete("municipalDirectoryRecord", result)}
          />
        </Stack>
        <FjdSpacer size="l" />
        <FjdSpacer size="l" />
      </Stack>
      {isCreateRightOfUseLoading && (
        <FjdBackdrop>
          <FjdSpinner size="m" />
        </FjdBackdrop>
      )}
    </Modal>
  );
}
