import React, { ChangeEventHandler, useState } from "react";
import { FjdTextInput } from "fjd-react-components";
import { Stack } from "../Stack/Stack";
import { Button } from "../Button/Button";

export interface HidableTextInputProps {
  actualValue?: string;
  placeHolder?: string;
  onHide?: () => void;
  onUnhide?: () => void;
  label: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

/**
 * This component does not support writing to it. It is a disabled text input that can show only the value provided
 * by the hidableValueProvider. The value is hidden by default and can be shown by clicking the unhide button.
 *
 * @param hidableValueProvider the value provider shown when not hidden
 * @param label the label of the field
 * @constructor
 */
function HidableTextInput({
  actualValue,
  placeHolder = "**********",
  onUnhide,
  onHide,
  label,
  disabled = true,
  onChange
}: HidableTextInputProps) {
  const [isHidden, setIsHidden] = useState(true);
  const sanitizedActualValue = actualValue ?? "";
  return (
    <Stack orientation="horizontal">
      {isHidden ? (
        <FjdTextInput
          id={`hidable-text-input-${label}`}
          value={"*".repeat(sanitizedActualValue.length)}
          label={label}
          testId={`hidable-text-input-${label}`}
          disabled
          placeholder={placeHolder}
        />
      ) : (
        <FjdTextInput
          id={`hidable-text-input-${label}`}
          value={sanitizedActualValue}
          onChange={onChange}
          label={label}
          testId={`hidable-text-input-${label}`}
          disabled={disabled}
          placeholder={placeHolder}
        />
      )}
      <Button
        label={`unhide-button-${label}`}
        onClick={() => {
          if (isHidden) {
            setIsHidden(false);
            onUnhide?.();
          } else {
            setIsHidden(true);
            onHide?.();
          }
        }}
        hideLabel
        size="m"
        iconLeft={isHidden ? "view-filled" : "view"}
        appearance="primary-link"
        testId={`unhide-button-${label}`}
      />
    </Stack>
  );
}

export const MktHidableTextInput = HidableTextInput;
