import React, { ReactNode } from "react";
import { FjdButtonAppearance, FjdButtonSize } from "fjd-react-components/build/components/Button/Button";
import { Placement } from "@popperjs/core";
import { FjdFlyoutButton } from "fjd-react-components";
import { FjdIconGlyph } from "fjd-react-components/build/components/Icon/Icon";

export interface FlyoutButtonProps {
  appearance?: FjdButtonAppearance;
  flyout: ReactNode;
  flyoutLabel: string;
  label: string;
  keepOpenIfFocused?: boolean;
  placement?: Placement;
  iconLeft?: FjdIconGlyph;
  hideLabel?: boolean;
  testid?: string;
  className?: string;
  size?: FjdButtonSize;
}

export function FlyoutButton({
  appearance,
  flyout,
  flyoutLabel,
  label,
  keepOpenIfFocused,
  placement,
  iconLeft,
  hideLabel,
  testid,
  className,
  size
}: FlyoutButtonProps) {
  return (
    <div className={className}>
      <FjdFlyoutButton
        appearance={appearance}
        flyout={flyout}
        flyoutLabel={flyoutLabel}
        label={label}
        keepOpenIfFocused={keepOpenIfFocused}
        placement={placement}
        iconLeft={iconLeft}
        hideLabel={hideLabel}
        data-testid={testid}
        size={size}
      />
    </div>
  );
}
