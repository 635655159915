import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../common/libs/authentication/context/UserContext";
import { mapOrganizationToUrlPath } from "../Routes";
import { useMarketplaceAuthentication } from "../../common/libs/authentication/hooks/useMarketplaceAuthentication";

export function LoginRedirectPage() {
  const auth = useMarketplaceAuthentication();
  const navigate = useNavigate();
  const { setSelectedOrganization, availableOrganizations } = useContext(UserContext);

  const redirectOnSuccessfullLogin = () => {
    if (availableOrganizations && auth.isAuthenticated) {
      const defaultOrganization = Object.entries(availableOrganizations).find(
        ([, organization]) => organization.roles.length > 0
      );
      if (defaultOrganization) {
        setSelectedOrganization({
          role: defaultOrganization[1].roles[0],
          name: defaultOrganization[1].name,
          uuid: defaultOrganization[0]
        });
        navigate(mapOrganizationToUrlPath(defaultOrganization[1].roles[0], defaultOrganization[0]));
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(redirectOnSuccessfullLogin, [auth.isAuthenticated]);

  return <div />;
}
