import { FjdSectionHeader } from "fjd-react-components";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { AxiosError } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Heading } from "../../../../common/components/Heading/Heading";
import { FlexSpacer } from "../../../../common/components/FlexSpacer/FlexSpacer";
import { Stack } from "../../../../common/components/Stack/Stack";
import "./RightsOfUsePage.css";
import { Button } from "../../../../common/components/Button/Button";
import { AddRightOfUseModal } from "./addRightOfUseModal/AddRightOfUseModal";
import { useNotification } from "../../../../common/libs/notifications/hooks/useNotification";
import { MktTable, RowProps } from "../../../../common/components/Table/Table";
import { PrivateRightOfUse, RightOfUse } from "../../../api/rightOfUse/RightOfUse";
import {
  deletePrivateRightOfUseById,
  deleteRightOfUseById,
  fetchRightsOfUse,
  getPrivateRightsOfUse
} from "../../../api/rightOfUse/rightOfUseApi";
import { KeyValueList } from "../../../../common/components/KeyValueList/KeyValueList";
import { convertTimestampToLocaleDate } from "../../../../common/utils/TimeUtils";
import { UserContext } from "../../../../common/libs/authentication/context/UserContext";
import { RightOfUseInformationModal } from "../../operatorPages/platforms/Dialogs/RightOfUseInformationModal";

export function RightsOfUsePage() {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rightOfUseSelected, selectRightOfUse] = useState<RightOfUse | null>(null);
  // const [privateRightOfUseSelected, selectPrivateRightOfUse] = useState<PrivateRightOfUse | null>(null);
  const { notifyUserFromResponse, confirmByUser } = useNotification();

  const { selectedOrganizationId } = useContext(UserContext);

  const { data: rightsOfUse, refetch: refetchRightsOfUse } = useQuery<RightOfUse[], AxiosError>({
    queryKey: [fetchRightsOfUse.name, selectedOrganizationId],
    queryFn: () => fetchRightsOfUse({ producerId: selectedOrganizationId ?? undefined })
  });

  const { data: privateRightsOfUse, refetch: refetchPrivateRightsOfUse } = useQuery<PrivateRightOfUse[], AxiosError>({
    queryKey: [getPrivateRightsOfUse.name, selectedOrganizationId],
    queryFn: () => getPrivateRightsOfUse({ producerId: selectedOrganizationId ?? undefined })
  });

  const { mutate: deleteRightOfUse } = useMutation({
    mutationKey: [deleteRightOfUseById.name],
    mutationFn: deleteRightOfUseById,
    onSettled: notifyUserFromResponse({ successMessage: t("rightsOfUse.actions.delete.successMessage") }),
    onSuccess: () => refetchRightsOfUse()
  });

  const { mutate: deletePrivateRightOfUse } = useMutation({
    mutationKey: [deletePrivateRightOfUseById.name],
    mutationFn: deletePrivateRightOfUseById,
    onSettled: notifyUserFromResponse({ successMessage: t("rightsOfUse.actions.delete.successMessage") }),
    onSuccess: () => refetchPrivateRightsOfUse()
  });

  const handleModalClose = () => {
    refetchRightsOfUse().finally(() => setIsModalVisible(false));
  };

  const menuButton = (row: RowProps<RightOfUse>) => (
    <MktTable.MenuButton
      identifier={row.row.original.id}
      menuItems={[
        {
          testId: `rights-of-use-show-details-${row.row.original.id}`,
          icon: "information-outline",
          label: t("rightsOfUse.actions.details.buttonLabel"),
          onClick: () => selectRightOfUse(row.row.original)
        },
        {
          testId: `rights-of-use-action-delete-${row.row.original.id}`,
          icon: "trashcan",
          label: t("rightsOfUse.actions.delete.buttonLabel"),
          onClick: () =>
            confirmByUser({
              callback: () => deleteRightOfUse({ id: row.row.original.id, areaOfUseId: row.row.original.areaOfUseId }),
              title: t("rightsOfUse.actions.delete.confirmationHeading"),
              confirmationButtonLabel: t("rightsOfUse.actions.delete.confirmationButtonLabel"),
              content: (
                <KeyValueList heading="">
                  <KeyValueList.Section sectionHeader="">
                    <KeyValueList.ListItem
                      term={t("common.onlineService")}
                      value={row.row.original.onlineServiceIdentifier}
                    />
                    <KeyValueList.ListItem
                      term={t("rightsOfUse.modalOperator")}
                      value={row.row.original.operatorName}
                    />
                  </KeyValueList.Section>
                </KeyValueList>
              )
            })
        }
      ]}
    />
  );

  const privateRightOfUseMenuButton = (row: RowProps<PrivateRightOfUse>) => (
    <MktTable.MenuButton
      identifier={row.row.original.id}
      menuItems={[
        {
          testId: `private-rights-of-use-action-delete-${row.row.original.id}`,
          icon: "trashcan",
          label: t("rightsOfUse.actions.delete.buttonLabel"),
          onClick: () =>
            confirmByUser({
              callback: () => deletePrivateRightOfUse({ id: row.row.original.id }),
              title: t("rightsOfUse.actions.delete.confirmationHeading"),
              confirmationButtonLabel: t("rightsOfUse.actions.delete.confirmationButtonLabel"),
              content: (
                <KeyValueList heading="">
                  <KeyValueList.Section sectionHeader="">
                    <KeyValueList.ListItem
                      term={t("common.onlineService")}
                      value={row.row.original.onlineServiceIdentifier}
                    />
                    <KeyValueList.ListItem
                      term={t("rightsOfUse.modalOperator")}
                      value={row.row.original.operatorName}
                    />
                  </KeyValueList.Section>
                </KeyValueList>
              )
            })
        }
      ]}
    />
  );

  return (
    <>
      <Stack orientation="horizontal">
        <FjdSectionHeader heading={<Heading level={1} text={t("rightsOfUse.header")} />} />
        <FlexSpacer />
        <span>
          <Button onClick={() => setIsModalVisible(true)} iconLeft="add" label={t("rightsOfUse.addButton")} size="m" />
        </span>
      </Stack>
      <Stack spacing="5xl">
        {rightsOfUse && (
          <MktTable
            data={rightsOfUse}
            className="RightOfUseTable"
            clickableRows={false}
            columns={[
              {
                Header: `${t("rightsOfUse.table.identifier")}`,
                accessor: "onlineServiceIdentifier"
              },
              {
                Header: `${t("rightsOfUse.table.area")}`,
                accessor: "rightOfUseArea"
              },
              {
                Header: `${t("rightsOfUse.table.operator")}`,
                accessor: "operatorName"
              },
              {
                Header: `${t("rightsOfUse.table.platform")}`,
                accessor: "platformName"
              },
              {
                Header: `${t("rightsOfUse.table.createdAt")}`,
                accessor: "createdAt",
                Cell: ({ value }: { value: string }) => (value ? convertTimestampToLocaleDate(value) : "")
              },
              {
                Header: "",
                accessor: "id",
                Cell: menuButton
              }
            ]}
          />
        )}
        {privateRightsOfUse && (
          <>
            <FjdSectionHeader heading={<Heading level={1} text={t("rightsOfUse.privateRightsOfUse")} />} />
            <MktTable
              data={privateRightsOfUse}
              className="RightOfUseTable"
              clickableRows={false}
              columns={[
                {
                  Header: `${t("rightsOfUse.table.identifier")}`,
                  accessor: "onlineServiceIdentifier"
                },
                {
                  Header: `${t("rightsOfUse.table.operator")}`,
                  accessor: "operatorName"
                },
                {
                  Header: `${t("rightsOfUse.table.platform")}`,
                  accessor: "platformName"
                },
                {
                  Header: `${t("rightsOfUse.accessKey")}`,
                  accessor: "accessKey"
                },
                {
                  Header: `${t("rightsOfUse.table.createdAt")}`,
                  accessor: "createdAt",
                  Cell: ({ value }: { value: string }) => (value ? convertTimestampToLocaleDate(value) : "")
                },
                {
                  Header: "",
                  accessor: "id",
                  Cell: privateRightOfUseMenuButton
                }
              ]}
            />
          </>
        )}
      </Stack>
      {isModalVisible && <AddRightOfUseModal isModalVisible={isModalVisible} handleModalClose={handleModalClose} />}
      {rightOfUseSelected && (
        <RightOfUseInformationModal
          rightOfUse={rightOfUseSelected}
          isModalVisible
          onClose={() => selectRightOfUse(null)}
        />
      )}
    </>
  );
}
