import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FjdBackdrop, FjdHeading, FjdSpinner } from "fjd-react-components";
import { Stack } from "../../../../common/components/Stack/Stack";
import { MktTable, RowProps } from "../../../../common/components/Table/Table";
import { Button } from "../../../../common/components/Button/Button";
import { AddMarketplaceAnbindungZugangsdatenModal } from "./AddMarketplaceAnbindungZugangsdatenModal";
import { UserContext } from "../../../../common/libs/authentication/context/UserContext";
import {
  appAccessCredentials,
  deleteAppAccess,
  DeleteAppAccessRequest,
  listAppAccess,
  ProducerAppAccessView,
  regenerateAppAccessCredentials
} from "../../../api/appAccessConfiguration/producerAppAccessConfigurationApi";
import { useNotification } from "../../../../common/libs/notifications/hooks/useNotification";
import { copyTextToClipboard } from "../../../../common/utils/StringUtils";

export function MarketplaceAnbindung() {
  const { t } = useTranslation();
  const [createOrUpdateAppAccessModalProps, setCreateOrUpdateAppAccessModalProps] = useState<{
    open: boolean;
    modalData?: ProducerAppAccessView;
  }>({ open: false });
  const [isLoading, setIsLoading] = useState<boolean>();
  const { selectedOrganizationId } = useContext(UserContext);
  const { notifyUserFromResponse, notifyUser, confirmByUser } = useNotification();

  const { data: appAccesses, refetch } = useQuery({
    queryKey: [listAppAccess.name, selectedOrganizationId],
    queryFn: () => listAppAccess(selectedOrganizationId ?? ""),
    enabled: !!selectedOrganizationId,
    refetchOnMount: false,
    onSettled: notifyUserFromResponse(),
    select: (data) =>
      data.map((bla) => ({
        ...bla,
        createdAt: new Date(bla.createdAt * 1000).toLocaleString(),
        updatedAt: new Date(bla.updatedAt * 1000).toLocaleTimeString()
      }))
  });

  const { mutate: deleteAccess } = useMutation({
    mutationKey: [listAppAccess.name, selectedOrganizationId],
    mutationFn: (req: DeleteAppAccessRequest) => deleteAppAccess(req),
    onSettled: notifyUserFromResponse({ successMessage: t("Zugansdaten gelöscht.") }),
    onSuccess: () => refetch()
  });

  const menuButton = (row: RowProps<ProducerAppAccessView>) => (
    <MktTable.MenuButton
      identifier={row.row.original.clientId}
      key={row.row.original.clientId}
      menuItems={[
        {
          label: "Bearbeiten",
          icon: "edit",
          onClick: () => setCreateOrUpdateAppAccessModalProps({ open: true, modalData: row.row.original })
        },
        {
          label: "Client-Secret kopieren",
          icon: "copy",
          onClick: () => {
            setIsLoading(true);
            appAccessCredentials({ clientId: row.row.original.clientId, producerId: selectedOrganizationId! })
              .then((value) => copyTextToClipboard(value.clientSecret))
              .then(() => notifyUser({ intent: "success", title: t("appsConfiguration.apiCredentialsKopiertSuccess") }))
              .catch(() => {
                notifyUser({ intent: "error", title: t("appsConfiguration.apiCredentialsKopiertError") });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }
        },
        {
          label: "Client-Secret neu generieren",
          icon: "reset",
          onClick: () => {
            setIsLoading(true);
            regenerateAppAccessCredentials({ clientId: row.row.original.clientId, producerId: selectedOrganizationId! })
              .then(() =>
                notifyUser({ intent: "success", title: t("appsConfiguration.apiCredentialsErneuertSuccess") })
              )
              .catch(() => notifyUser({ intent: "error", title: t("appsConfiguration.apiCredentialsErneuertError") }))
              .finally(() => setIsLoading(false));
          }
        },
        {
          label: t("appsConfiguration.deleteCredentials"),
          onClick: () =>
            confirmByUser({
              callback: () =>
                deleteAccess({ clientId: row.row.original.clientId, producerId: selectedOrganizationId! }),
              title: t("appsConfiguration.deleteCredentialsConfirmation", { name: row.row.original.name })
            })
        }
      ]}
    />
  );
  return isLoading ? (
    <FjdBackdrop>
      <FjdSpinner size="m" />
    </FjdBackdrop>
  ) : (
    <>
      <Stack spacing="5xl">
        <Stack orientation="vertical" spacing="m">
          <FjdHeading text={t("appsConfiguration.apiCredentials")} level={2} />
          <MktTable
            data={appAccesses ?? []}
            enableGlobalFilter={false}
            clickableRows={false}
            columns={[
              {
                Header: "Name",
                accessor: "name"
              },
              {
                Header: "Client-Id",
                accessor: "clientId"
              },
              {
                Header: "Client-Secret",
                accessor: "clientSecret",
                Cell: () => "*****"
              },
              {
                Header: "Angelegt von",
                accessor: "createdBy"
              },
              {
                Header: "Angelegt am",
                accessor: "createdAt"
              },
              {
                Header: "",
                accessor: "id",
                Cell: menuButton
              }
            ]}
          />
        </Stack>
        <Button
          label={t("appsConfiguration.addApiCredentials")}
          iconLeft="add-outline"
          onClick={() => setCreateOrUpdateAppAccessModalProps({ open: true })}
        />
      </Stack>
      {createOrUpdateAppAccessModalProps.open && (
        <AddMarketplaceAnbindungZugangsdatenModal
          onSuccess={() => {
            setCreateOrUpdateAppAccessModalProps({ open: false });
            refetch().catch(() => {});
          }}
          appAccessZugangsdaten={createOrUpdateAppAccessModalProps.modalData}
          onClose={() => setCreateOrUpdateAppAccessModalProps({ open: false })}
        />
      )}
    </>
  );
}
