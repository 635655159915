/*
 Values takes from https://gitlab.dev.fjd.de/efast/services/component-library/-/blob/main/src/styles/_media-queries.css
 */
export const FjdDeviceDisplay = {
  M: 768,
  L: 1024,
  XL: 1400,
  XXL: 1600
} as const;

export const FjdDeviceDisplayPx = {
  M: `${FjdDeviceDisplay.M}px`,
  L: `${FjdDeviceDisplay.L}px`,
  XL: `${FjdDeviceDisplay.XL}px`,
  XXL: `${FjdDeviceDisplay.XXL}px`
};
