import React, { ChangeEvent, createContext, useMemo, useState } from "react";
import { Stack } from "../Stack/Stack";
import "./TabBar.css";

interface TabBarContextProps {
  onTabChange: (tabIndex: ChangeEvent<HTMLInputElement>) => void;
  activeTabIndex: number;
}
export const TabBarContext = createContext<TabBarContextProps>({
  onTabChange: () => {},
  activeTabIndex: 0
});

interface TabProps {
  id: string;
  label: string;
  index: number;
  name?: string;
}

function Tab({ id, label, index, name }: TabProps) {
  const { onTabChange, activeTabIndex } = React.useContext(TabBarContext);
  return (
    <div className="tab">
      <input
        type="radio"
        id={id}
        data-testid={id}
        name={name ?? label}
        value={index}
        onChange={onTabChange}
        checked={index === activeTabIndex}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
}

interface PanelProps {
  index: number;
  children: JSX.Element | JSX.Element[];
}

function Panel({ children, index }: PanelProps) {
  return (
    <Stack key={index} orientation="vertical" spacing="m">
      {children}
    </Stack>
  );
}

interface TabBarComponentProps {
  defaultTabIndex?: number;
  children: JSX.Element | JSX.Element[];
}
function TabBarComponent({ defaultTabIndex, children }: TabBarComponentProps) {
  const [tabIndex, setTabIndex] = useState(defaultTabIndex ?? 0);

  const onTabChange = (value: ChangeEvent<HTMLInputElement>) => {
    setTabIndex(Number(value.target.value));
  };

  const tabBarContextApi = useMemo(
    () => ({
      onTabChange,
      activeTabIndex: tabIndex
    }),
    [tabIndex]
  );
  return (
    <TabBarContext.Provider value={tabBarContextApi}>
      <Stack className="radio-toolbar" spacing="m" orientation="horizontal">
        {children && children.constructor === Array && children.filter((child) => child.type === Tab)}
      </Stack>
      {children &&
        children.constructor === Array &&
        children.filter((child) => child.type === Panel && (child.props as PanelProps).index === tabIndex)}
    </TabBarContext.Provider>
  );
}

TabBarComponent.Panel = Panel;
TabBarComponent.Tab = Tab;
export const TabBar = TabBarComponent;
