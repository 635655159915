import { Outlet } from "react-router-dom";
import React, { useContext } from "react";
import { UserContext } from "../../common/libs/authentication/context/UserContext";
import { OrganizationRole } from "../../common/libs/authentication/types/Organization";

export function ProtectedRoleLayout({ allowedRole }: { allowedRole: OrganizationRole }) {
  const { selectedOrganization } = useContext(UserContext);

  if (selectedOrganization?.role !== allowedRole) return <Outlet />; // Replace Outlet with --> <Navigate to="/" replace />;

  return <Outlet />;
}
