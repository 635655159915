import {
  FjdBackdrop,
  FjdButton,
  FjdFileInput,
  FjdFlexSpacer,
  FjdHeading,
  FjdSectionHeader,
  FjdSpinner,
  FjdStack
} from "fjd-react-components";
import React, { ChangeEvent, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { Stack } from "../../../../common/components/Stack/Stack";
import { Heading } from "../../../../common/components/Heading/Heading";
import { FileDescription } from "../../../../common/components/FileDescription/FileDescription";
import { OnlineServicesV1 } from "../../../api/onlinedienste/OnlineServicesV1";
import { useFetchOnlineDienste } from "../../../api/onlinedienste/hooks/useFetchOnlineDienste";
import { FlexSpacer } from "../../../../common/components/FlexSpacer/FlexSpacer";
import { Modal } from "../../../../common/components/Modal/Modal";
import { useNotification } from "../../../../common/libs/notifications/hooks/useNotification";
import { deleteOnlineServiceById } from "../../../api/rightOfUse/rightOfUseApi";
import { OnlineDiensteVorlagenPage } from "./OnlineDiensteVorlagen/OnlineDiensteVorlagenPage";
import { downloadCatalog, fetchOnlineServicesMetadata, uploadCatalogFile } from "../../../api/catalog/catalogApi";
import { TabBar } from "../../../../common/components/TabBar/TabBar";
import {
  CatalogApiPathType,
  Catalog,
  ONLINE_SERVICES_CATALOG_API_PATH,
  CatalogFileMetadata
} from "../../../api/catalog/Catalog";
import { SearchFilterBar } from "../../../../common/components/SearchFilterBar/SearchFilterBar";
import { MktTable, RowProps } from "../../../../common/components/Table/Table";
import { convertTimestampToLocaleDate } from "../../../../common/utils/TimeUtils";

export function OnlineDiensteDashboardPage() {
  const { t } = useTranslation();
  const [uploadCandidate, setUploadCandidate] = useState<Record<string, File> | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchResults, setSearchResults] = useState<OnlineServicesV1[]>([]);

  const { notifyUserFromResponse, confirmByUser } = useNotification();

  const { data, refetch: refetchOnlineServicesMetaData } = useQuery<Record<string, CatalogFileMetadata>, AxiosError>({
    queryKey: [fetchOnlineServicesMetadata],
    queryFn: fetchOnlineServicesMetadata,
    retry: false
  });

  const onSearchComplete = (result: OnlineServicesV1[]) => {
    setSearchResults(result);
  };

  const { data: onlineServices, refetch: refetchOnlineServices } = useFetchOnlineDienste(onSearchComplete);

  const onlineDienste = useMemo(() => onlineServices ?? [], [onlineServices]);

  const { isLoading: isFileUploading, mutate: uploadFile } = useMutation({
    mutationKey: [uploadCatalogFile],
    mutationFn: uploadCatalogFile,
    onSuccess: () => {
      refetchOnlineServicesMetaData().catch(() => {});
      refetchOnlineServices().catch(() => {});
    },
    onSettled: notifyUserFromResponse()
  });

  const { mutate: deleteOnlineDienstById } = useMutation({
    mutationKey: [deleteOnlineServiceById],
    mutationFn: deleteOnlineServiceById,
    onSuccess: (_, variables) => {
      setSearchResults(searchResults.filter((result) => result.id !== variables));
    },
    onSettled: notifyUserFromResponse()
  });

  const { onlineDiensteFile } = useMemo(() => data ?? {}, [data]);

  const handleDeleteOnlineDienstDialogBox = ({ id, kennung }: OnlineServicesV1) => {
    confirmByUser({
      callback: () => deleteOnlineDienstById(id),
      confirmationButtonLabel: t("onlineServices.modalAcceptDeleteOnlineDienst"),
      title: t("onlineServices.modalQuestionDeleteOnlineDienst", { kennung }),
      abortButtonLabel: t("onlineServices.modalNo")
    });
  };

  const addUploadCandidate = (type: Catalog) => (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (file) setUploadCandidate({ ...uploadCandidate, [type]: file });
  };

  const handleModalClose = () => {
    setUploadCandidate(null);
    setIsModalVisible(false);
  };

  const handleClickOnFileDownload = (file: CatalogApiPathType) => () => {
    if (file === ONLINE_SERVICES_CATALOG_API_PATH) downloadCatalog(file).catch(() => {});
  };

  const uploadCandidates = () => {
    if (uploadCandidate) {
      uploadFile(uploadCandidate);
    }
    refetchOnlineServicesMetaData()
      .then(handleModalClose)
      .catch(() => {});
  };

  const menuButton = (row: RowProps<OnlineServicesV1>) => (
    <FjdButton
      data-testid={row.row.original.id}
      label=""
      hideLabel
      iconLeft="trashcan"
      appearance="primary-link"
      onClick={() => handleDeleteOnlineDienstDialogBox(row.row.original)}
      size="s"
    />
  );

  if (isFileUploading)
    return (
      <FjdBackdrop>
        <FjdSpinner size="m" />
      </FjdBackdrop>
    );

  return (
    <>
      <Stack spacing="5xl">
        <FjdSectionHeader
          heading={
            <Heading
              level={1}
              text={t("onlineServices.onlineServicesHeadline", { onlineDiensteCount: searchResults?.length ?? "-" })}
            />
          }
        />
        <TabBar>
          <TabBar.Tab label={t("onlineServices.futureContent")} index={0} id="futureContent" />
          <TabBar.Tab label={t("onlineServices.currentContent")} index={1} id="currentContent" />
          <TabBar.Panel index={0}>
            <OnlineDiensteVorlagenPage />
          </TabBar.Panel>
          <TabBar.Panel index={1}>
            <Stack orientation="horizontal">
              <FlexSpacer />
              <span>
                <FjdButton
                  onClick={() => setIsModalVisible(true)}
                  appearance="outline"
                  iconLeft="document-import"
                  label={t("onlineServices.import")}
                  size="m"
                />
              </span>
            </Stack>
            <Stack orientation="vertical" spacing="xs">
              <FjdHeading text={t("onlineServices.filterBarHeadline")} level={2} />
              <SearchFilterBar<OnlineServicesV1>
                searchIn={["titel", "kennung", "fimId"]}
                columnFilters={["status"]}
                placeholder={t("onlineServices.searchBarPlaceholder")}
                menuItemLabel={(result) => `${result.titel}`}
                data={onlineDienste || ([] as OnlineServicesV1[])}
                onSearchCompleted={onSearchComplete}
                searchDelay={200}
              />
              {searchResults && (
                <MktTable
                  data={searchResults}
                  enableColumnFilter={false}
                  enableGlobalFilter={false}
                  sortBy={[{ id: "titel", desc: true } as { id: keyof OnlineServicesV1; desc: boolean }]}
                  columns={[
                    {
                      Header: "Kennung",
                      accessor: "kennung"
                    },
                    {
                      Header: "Titel",
                      accessor: "titel"
                    },
                    {
                      Header: "Angelegt am",
                      accessor: "createdAt",
                      Cell: ({ value }: { value: string }) => (value ? convertTimestampToLocaleDate(value) : "")
                    },
                    {
                      Header: "Zuletzt geändert am",
                      accessor: "updatedAt",
                      Cell: ({ value }: { value: string }) => (value ? convertTimestampToLocaleDate(value) : "")
                    },
                    {
                      Header: "Status",
                      accessor: "status"
                    },
                    {
                      Header: "",
                      accessor: "version",
                      Cell: menuButton
                    }
                  ]}
                />
              )}
            </Stack>
          </TabBar.Panel>
        </TabBar>
      </Stack>
      <Modal
        closable
        onClose={handleModalClose}
        open={isModalVisible}
        closeOnBackdropClick
        heading={t("onlineServices.modalHeading")}
        primaryButtonLabel={t("onlineServices.modalSave")}
        onPrimaryButtonClick={uploadCandidates}
        secondaryButtonLabel={t("onlineServices.modalAboard")}
        onSecondaryButtonClick={handleModalClose}
      >
        <FjdStack spacing="l">
          <FjdHeading level={3} text={t("onlineServices.modalJiraUpload")} />
          <FileDescription
            filename={onlineDiensteFile?.name ?? "-"}
            onClick={handleClickOnFileDownload(ONLINE_SERVICES_CATALOG_API_PATH)}
          />
          <FjdFlexSpacer />
          <FjdStack orientation="horizontal" spacing="s">
            <FjdFileInput
              id="onlineServicesCsvFile"
              accept=".csv"
              label={t("onlineServices.fileUpload")}
              disabled={isFileUploading}
              onChange={addUploadCandidate("onlineDiensteFile")}
            />
            <span>
              {uploadCandidate && "onlineDiensteFile" in uploadCandidate ? uploadCandidate.onlineDiensteFile?.name : ""}
            </span>
          </FjdStack>
        </FjdStack>
      </Modal>
    </>
  );
}
