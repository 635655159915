import { useEffect, useState } from "react";

export const useFocusListener = (ref: React.RefObject<HTMLElement> | null) => {
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    const focusInHandler = () => {
      setHasFocus(true);
    };
    const focusOutHandler = () => {
      setHasFocus(false);
    };
    if (ref?.current) {
      ref.current.addEventListener("focusin", focusInHandler);
      ref.current.addEventListener("focusout", focusOutHandler);
    }
    return () => {
      if (ref?.current) {
        ref.current.removeEventListener("focusin", focusInHandler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ref.current.removeEventListener("focusout", focusOutHandler);
      }
    };
  }, [ref]);

  return hasFocus;
};
