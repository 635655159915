import React from "react";
import { FjdButton, FjdHeader, FjdMenu, FjdMenuItem } from "fjd-react-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FlyoutButton } from "../FlyoutButton/FlyoutButton";
import { FJD_OZG_CONTACT_MAIL, FJD_OZG_CONTACT_MAILTO, FJD_OZG_CONTACT_PHONE_NUMBER } from "../../contact";
import "./Header.css";
import Logo from "../../../assets/img/logo.svg";

const LOGIN_LOCALE_KEY = "header.menuItems.login.signIn";
const LOGOUT_LOCALE_KEY = "header.menuItems.login.logout";

function ContactButton({ contactButtonLabel }: { contactButtonLabel: string }) {
  return (
    <FlyoutButton
      appearance="primary-link"
      flyout={
        <FjdMenu>
          <FjdMenuItem
            appearance="light"
            icon="email"
            size="m"
            label={FJD_OZG_CONTACT_MAIL}
            onClick={() => window.open(FJD_OZG_CONTACT_MAILTO)}
          />
          <FjdMenuItem
            appearance="light"
            icon="chat"
            size="m"
            label={FJD_OZG_CONTACT_PHONE_NUMBER}
            onClick={() => window.open(`tel:${FJD_OZG_CONTACT_PHONE_NUMBER}`)}
          />
        </FjdMenu>
      }
      flyoutLabel="button"
      label={contactButtonLabel}
      keepOpenIfFocused
      placement="bottom-end"
    />
  );
}

function MobileMenu({
  isAuthenticated,
  onLogout,
  onLogin,
  loginLabel,
  logoutLabel
}: {
  isAuthenticated: boolean;
  onLogin: () => void;
  onLogout: () => void;
  loginLabel: string;
  logoutLabel: string;
}) {
  return (
    <FjdMenu>
      {isAuthenticated ? (
        <FjdMenuItem appearance="light" icon="logout" size="m" label={logoutLabel} onClick={onLogout} />
      ) : (
        <FjdMenuItem appearance="light" icon="login" size="m" label={loginLabel} onClick={onLogin} />
      )}
    </FjdMenu>
  );
}

function Menu({
  isAuthenticated,
  userName,
  onLogout,
  onLogin,
  loginLabel,
  logoutLabel
}: {
  isAuthenticated: boolean;
  userName: string;
  onLogin: () => void;
  onLogout: () => void;
  loginLabel: string;
  logoutLabel: string;
}) {
  return isAuthenticated ? (
    <FlyoutButton
      appearance="primary-link"
      flyout={
        <FjdMenu>
          <FjdMenuItem appearance="light" icon="logout" size="m" label={logoutLabel} onClick={onLogout} />
        </FjdMenu>
      }
      flyoutLabel="button"
      label={userName}
      keepOpenIfFocused
      placement="bottom-end"
      iconLeft="user-avatar"
    />
  ) : (
    <FjdButton label={loginLabel} type="button" appearance="primary-link" onClick={onLogin} iconLeft="login" />
  );
}

interface HeaderProps {
  login: () => void;
  logout: () => void;
  userName: string;
  isAuthenticated: boolean;
}

/**
 * Due to FjdHeader Component it does not makes any sense to refactor this component to a compound componend or similiar manner. Therefore it is easier to keep the logic within the component.
 */
export function Header({ logout, login, userName, isAuthenticated }: HeaderProps) {
  const { t } = useTranslation();
  return (
    <FjdHeader
      logo={
        <Link className="marketplace-header" to="/">
          <img alt="Logo" src={Logo} className="marketplace-header-logo" />
        </Link>
      }
      menu={
        <MobileMenu
          isAuthenticated={isAuthenticated}
          onLogin={login}
          onLogout={logout}
          loginLabel={t(LOGIN_LOCALE_KEY)}
          logoutLabel={t(LOGOUT_LOCALE_KEY)}
        />
      }
      navigation={
        <>
          <ContactButton contactButtonLabel={t("header.menuItems.contact")} />
          <Menu
            isAuthenticated={isAuthenticated}
            onLogin={login}
            onLogout={logout}
            userName={userName}
            loginLabel={t(LOGIN_LOCALE_KEY)}
            logoutLabel={t(LOGOUT_LOCALE_KEY)}
          />
        </>
      }
    />
  );
}
