import { MARKETPLACE_URL_BASE, mktClient } from "../../../common/libs/axiosClient";
import { Operator, Producer } from "./Organizations";

export const ORGANISATIONS_URL = `${MARKETPLACE_URL_BASE}/organisations`;
export const OPERATORS_URL = `${ORGANISATIONS_URL}/operators`;
export const PRODUCERS_URL = `${ORGANISATIONS_URL}/producers`;

export const fetchOperators = () =>
  mktClient.get<Operator[]>(OPERATORS_URL.toString()).then<Operator[]>((response) => response.data);

export const fetchProducers = () =>
  mktClient.get<Producer[]>(PRODUCERS_URL.toString()).then<Producer[]>((response) => response.data);
