import React from "react";

interface SearchInputProps {
  value?: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onChange, value, placeholder, onKeyDown }, ref) => (
    <input
      data-testid="searchFilterBar"
      ref={ref}
      id="searchFilterBar"
      className="search-input"
      onChange={onChange}
      value={value}
      type="text"
      placeholder={placeholder}
      onKeyDown={onKeyDown}
    />
  )
);
