import { AxiosResponse } from "axios";

// eslint-disable-next-line import/prefer-default-export
export function extractFileName(contentDispositionHeader: string): string | undefined {
  if (contentDispositionHeader && contentDispositionHeader.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDispositionHeader);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
  }
  return undefined;
}

/**
 * Extracts the filename from content-disposition header and initiates a download process in the browser
 * @param response the axios response. Header needs to contain content-disposition header otherwise no browser download
 * is triggered
 */
export function downloadFileViaContentDisposition(response: AxiosResponse<Blob>) {
  if (response) {
    const { data, headers } = response;
    const contentDispositionHeader = headers["content-disposition"] as string;
    if (contentDispositionHeader) {
      const fileName = extractFileName(contentDispositionHeader);
      if (fileName) {
        const href = URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    }
  }
}

export function isSuccessful(statusCode: number) {
  return statusCode >= 200 && statusCode < 300;
}
