/*
eslint-disable import/prefer-default-export
*/

/**
 * Converts a UTC (ISO 8601) formatted timestamp into a local date time string WITHOUT milliseconds and seconds.
 * @example
 *  de: 2022-12-01T10:04:36.855227Z -> 01.12.2022, 11:04 Uhr
 *  en: 2022-12-01T10:04:36.855227Z -> 12/01/2022, 11:04 AM
 * @param timestamp
 * @param languageTag
 */
export function convertTimestampToLocaleDateTime(timestamp: string, languageTag?: string) {
  const theLanguageTag = languageTag || window.navigator.language;
  const localDateTime = new Date(timestamp).toLocaleDateString(theLanguageTag, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
  return theLanguageTag.startsWith("de") ? `${localDateTime} Uhr` : localDateTime;
}

/**
 * Converts a UTC (ISO 8601) formatted timestamp into a local date string .
 * @example
 *  de: 2022-12-01T10:04:36.855227Z -> 01.12.2022
 *  en: 2022-12-01T10:04:36.855227Z -> 12/01/2022
 * @param timestamp
 * @param languageTag
 */
export function convertTimestampToLocaleDate(timestamp: string, languageTag?: string) {
  const theLanguageTag = languageTag || window.navigator.language;
  return new Date(timestamp).toLocaleDateString(theLanguageTag, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
}
