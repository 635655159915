import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FjdBackdrop, FjdButton, FjdHeading, FjdIcon, FjdSpinner, FjdStack } from "fjd-react-components";
import { Modal } from "../../../../../common/components/Modal/Modal";
import {
  deletePlatformById,
  fetchClientSecret,
  fetchPlatformData,
  regenerateClientSecret
} from "../../../../api/platforms/platformApi";
import { UserContext } from "../../../../../common/libs/authentication/context/UserContext";
import { useNotification } from "../../../../../common/libs/notifications/hooks/useNotification";
import { CreateOrUpdatePlatformModal } from "./CreateOrUpdatePlatformModal";
import { Platform, PlatformEnvironment } from "../../../../api/platforms/Platform";
import { MktTable } from "../../../../../common/components/Table/Table";
import { Stack } from "../../../../../common/components/Stack/Stack";
import { copyTextToClipboard } from "../../../../../common/utils/StringUtils";
import { KeyValueList } from "../../../../../common/components/KeyValueList/KeyValueList";
import { Markdown } from "../../../../../common/components/Markdown/Markdown";
import { Spacer } from "../../../../../common/components/Spacer/Spacer";

interface ModalProps {
  isModalVisible: boolean;
  handleModalClose: () => void;
}

export function MaintainPlatformModal({ isModalVisible, handleModalClose }: ModalProps) {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useContext(UserContext);
  const { notifyUserFromResponse, confirmByUser } = useNotification();

  const [openAddOrUpdatePlatformModalVisible, setOpenAddOrUpdatePlatformModalVisible] = useState<null | "Add" | "Edit">(
    null
  );
  const [selectedPlatformEnvironment, setSelectedPlatformEnvironment] = useState<PlatformEnvironment>(
    PlatformEnvironment.Prod
  );
  const [updatePlatformData, setUpdatePlatformData] = useState<null | Platform>(null);

  const { data: fetchedPlatforms, refetch: refetchPlatformData } = useQuery({
    queryKey: [fetchPlatformData.name],
    queryFn: fetchPlatformData,
    select: (data) => {
      const platformsByOperator = data?.filter((platform) => platform?.operatorId === selectedOrganizationId);
      const prodPlatforms = platformsByOperator?.filter(
        (platform) => platform?.environment === PlatformEnvironment.Prod
      );
      const testPlatforms = platformsByOperator?.filter(
        (platform) => platform?.environment === PlatformEnvironment.Test
      );
      return { prodPlatforms, testPlatforms };
    }
  });

  const { mutate: platformDeleteById, isLoading: isDeletingPlatform } = useMutation({
    mutationKey: [deletePlatformById.name],
    mutationFn: deletePlatformById,
    onSuccess: () => refetchPlatformData(),
    onSettled: notifyUserFromResponse({
      successMessage: t("platform.successMessage.platformDeleted")
    })
  });

  const { mutate: doRegenerateSecret, isLoading: isRegneratingSecret } = useMutation({
    mutationKey: [regenerateClientSecret.name],
    mutationFn: regenerateClientSecret,
    onSettled: notifyUserFromResponse({
      successMessage: t("platform.successMessage.secretRegenerated")
    })
  });

  const handleModalCloseAndRefetch = () => {
    setOpenAddOrUpdatePlatformModalVisible(null);
    refetchPlatformData().catch(() => {});
  };

  const menuButton = (row: {
    row: {
      original: Platform;
    };
  }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isFetching, refetch: refetchSecret } = useQuery({
      queryKey: [fetchClientSecret.name, row.row.original.id],
      queryFn: () => fetchClientSecret({ platformId: row.row.original.id }),
      onSettled: notifyUserFromResponse({
        successMessage: t("platform.successMessage.secretCopied"),
        errorMessage: t("platform.errorMessage.failedToCopySecret")
      }),
      onSuccess: (data) => {
        copyTextToClipboard(data.data.clientSecret).catch(() => {});
      },
      enabled: false
    });
    return isFetching ? (
      <FjdBackdrop>
        <FjdSpinner size="m" />
      </FjdBackdrop>
    ) : (
      <Stack orientation="horizontal">
        <MktTable.MenuButton
          identifier={row.row.original.id}
          menuItems={[
            {
              testId: `platform-action-copy-secret-${row.row.original.id}`,
              icon: "copy",
              label: t("platform.modalManagePlatforms.copySecret"),
              onClick: () => {
                refetchSecret().catch(() => {});
              }
            },
            {
              testId: `platform-action-regenerate-secret-${row.row.original.id}`,
              icon: "reset",
              label: t("platform.modalManagePlatforms.regenerateClientSecret"),
              onClick: () => {
                confirmByUser({
                  callback: () => doRegenerateSecret({ platformId: row.row.original.id }),
                  title: t("platform.regenerateClientSecretConfirmation.heading"),
                  content: (
                    <Stack orientation="vertical">
                      <KeyValueList heading="">
                        <KeyValueList.Section>
                          <KeyValueList.ListItem
                            term={t("platform.regenerateClientSecretConfirmation.platformTerm")}
                            value={row.row.original.name}
                          />
                          <KeyValueList.ListItem
                            term={t("platform.regenerateClientSecretConfirmation.clientIdTerm")}
                            value={row.row.original.clientId}
                          />
                        </KeyValueList.Section>
                      </KeyValueList>
                      <Spacer />
                      <Markdown source={t("platform.regenerateClientSecretConfirmation.note")} />
                    </Stack>
                  ),
                  confirmationButtonLabel: t("platform.regenerateClientSecretConfirmation.confirmButtonLabel")
                });
              }
            },
            {
              testId: `platform-action-edit-${row.row.original.id}`,
              icon: "edit",
              label: t("platform.modalManagePlatforms.editDetails"),
              onClick: () => {
                setOpenAddOrUpdatePlatformModalVisible("Edit");
                const platforms = fetchedPlatforms
                  ? [...fetchedPlatforms.testPlatforms, ...fetchedPlatforms.prodPlatforms]
                  : [];
                setUpdatePlatformData(platforms.find((platform) => platform.id === row.row.original?.id) ?? null);
              }
            },
            {
              testId: `platform-action-delete-${row.row.original.id}`,
              icon: "trashcan",
              label: t("platform.modalManagePlatforms.deletePlatform"),
              onClick: () =>
                confirmByUser({
                  callback: () => platformDeleteById(row.row.original.id),
                  title: t("platform.dialogBox.heading", { platformname: row.row.original?.name }),
                  confirmationButtonLabel: t("platform.dialogBox.acceptDelete")
                })
            }
          ]}
        />
      </Stack>
    );
  };

  return (
    <Modal
      closable
      onClose={handleModalClose}
      open={isModalVisible}
      closeOnBackdropClick
      heading={t("platform.modalManagePlatforms.managePlatforms")}
      onSecondaryButtonClick={handleModalClose}
    >
      <FjdStack spacing="l">
        <div>
          <FjdHeading level={3} text={t("platform.modalManagePlatforms.operatingPlatformsProd")} />
          <FjdStack spacing="s">
            {fetchedPlatforms?.prodPlatforms && (
              <MktTable
                data={fetchedPlatforms?.prodPlatforms ?? []}
                enableGlobalFilter={false}
                clickableRows={false}
                columns={[
                  {
                    Header: "Platform",
                    accessor: "name"
                  },
                  {
                    Header: t("platform.modalManagePlatforms.clientIdColumn"),
                    accessor: "clientId"
                  },
                  {
                    Header: "",
                    accessor: "id",
                    Cell: menuButton
                  }
                ]}
              />
            )}
            <FjdButton
              label={t("platform.modalManagePlatforms.addOperatingPlatformsProd")}
              iconLeft="add"
              appearance="primary-link"
              onClick={() => {
                setSelectedPlatformEnvironment(PlatformEnvironment.Prod);
                setOpenAddOrUpdatePlatformModalVisible("Add");
              }}
              testId="button-open-add-platform-modal"
            />
          </FjdStack>
        </div>

        <div>
          <FjdHeading level={3} text={t("platform.modalManagePlatforms.operatingPlatformsTest")} />
          <FjdStack spacing="s">
            {fetchedPlatforms?.testPlatforms && (
              <MktTable
                data={fetchedPlatforms?.testPlatforms ?? []}
                enableGlobalFilter={false}
                clickableRows={false}
                columns={[
                  {
                    Header: "Platform",
                    accessor: "name"
                  },
                  {
                    Header: t("platform.modalManagePlatforms.clientIdColumn"),
                    accessor: "clientId"
                  },
                  {
                    Header: t("platform.modalManagePlatforms.shouldUseWorkingVersion"),
                    accessor: "shouldUseWorkingVersion",
                    // eslint-disable-next-line react/no-unstable-nested-components
                    Cell: (row: {
                      row: {
                        original: Platform;
                      };
                    }) =>
                      !!row.row.original.workingVersionsRequested && (
                        <div className="center-content-horizontally">
                          <FjdIcon glyph="checkmark-outline" appearance="success" />
                        </div>
                      )
                  },
                  {
                    Header: "",
                    accessor: "id",
                    Cell: menuButton
                  }
                ]}
              />
            )}
            <FjdButton
              label={t("platform.modalManagePlatforms.addOperatingPlatformsTest")}
              iconLeft="add"
              appearance="primary-link"
              onClick={() => {
                setOpenAddOrUpdatePlatformModalVisible("Add");
                setSelectedPlatformEnvironment(PlatformEnvironment.Test);
              }}
              testId="button-open-add-platform-modal"
            />
          </FjdStack>
        </div>
      </FjdStack>

      {openAddOrUpdatePlatformModalVisible !== null && selectedOrganizationId !== null && (
        <CreateOrUpdatePlatformModal
          selectedOrganizationUuid={selectedOrganizationId}
          platform={openAddOrUpdatePlatformModalVisible === "Edit" ? updatePlatformData : null}
          type={openAddOrUpdatePlatformModalVisible}
          environment={selectedPlatformEnvironment}
          isModalVisible
          handleModalClose={handleModalCloseAndRefetch}
        />
      )}

      {(isRegneratingSecret || isDeletingPlatform) && (
        <FjdBackdrop>
          <FjdSpinner size="m" />
        </FjdBackdrop>
      )}
    </Modal>
  );
}
