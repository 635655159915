import { OrganizationRole } from "../common/libs/authentication/types/Organization";

export const BETREIBER_INDEX_PATH = (orgId?: string) => `/betreiber/${orgId ?? ":orgId"}`;
export const BEHOERDEN_INDEX_PATH = (orgId?: string) => `/behoerde/${orgId ?? ":orgId"}`;
export const HERSTELLER_INDEX_PATH = (orgId?: string) => `/hersteller/${orgId ?? ":orgId"}`;
export const ONLINE_SERVICES_PATH = "/online-services";
export const APPS_CONFIGURATION_PATH = "/apps-configuration";
export const RIGHTS_OF_USE_PATH = "/right-of-use";
export const CATALOG_PATH = "/catalogs";
export const REDIRECT_PATH = "/redirect";

/*
  Hersteller
 */
export const ONLINE_SERVICES_OF_PRODUCER_PATH = (orgId?: string) => HERSTELLER_INDEX_PATH(orgId) + ONLINE_SERVICES_PATH;
export const RIGHTS_OF_USE_OF_PRODUCER_PATH = (orgId?: string) => HERSTELLER_INDEX_PATH(orgId) + RIGHTS_OF_USE_PATH;
export const CATALOG_OF_PRODUCER_PATH = (orgId?: string) => HERSTELLER_INDEX_PATH(orgId) + CATALOG_PATH;
export const APPS_CONFIGURATION_OF_PRODUCER_PATH: (orgId?: string) => string = (orgId?: string) =>
  HERSTELLER_INDEX_PATH(orgId) + APPS_CONFIGURATION_PATH;

/*
  Betreiber
 */
export const RIGHTS_OF_USE_OF_OPERATOR_PATH = (orgId?: string) => BETREIBER_INDEX_PATH(orgId) + RIGHTS_OF_USE_PATH;

export const mapOrganizationToUrlPath = (role: OrganizationRole, orgId: string) => {
  switch (role) {
    case "hersteller":
      return ONLINE_SERVICES_OF_PRODUCER_PATH(orgId);
    case "betreiber":
      return RIGHTS_OF_USE_OF_OPERATOR_PATH(orgId);
    case "behoerde":
      return BEHOERDEN_INDEX_PATH(orgId);
    default:
      return "/";
  }
};
