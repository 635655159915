import React, { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { FjdMultiSelectOption } from "fjd-react-components/build/components/MultiSelect/MultiSelect";
import "./LandingPage.css";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { FjdButton, FjdCheckbox, FjdIcon, FjdMultiSelect, FjdTooltip } from "fjd-react-components";
import "../../../common/components/TabBar/TabBar.css";
import {
  FilterValues,
  OnlineServiceStatus,
  OnlineServiceV2FlatendLeikaIds
} from "../../api/onlinedienste/OnlineServiceV2";
import { useFetchOnlineServicesV2 } from "../../api/onlinedienste/hooks/useFetchOnlineDienste";
import { Stack } from "../../../common/components/Stack/Stack";
import { Banner } from "../../../common/components/Banner/Banner";
import { URL_BACKGROUND_BANNER } from "../../../common/url";
import { NachnutzungsIntro } from "./NachnutzungsIntro/NachnutzungsIntro";
import { Heading } from "../../../common/components/Heading/Heading";
import { SearchFilterBar } from "../../../common/components/SearchFilterBar/SearchFilterBar";
import { OnlineServiceList } from "../../../common/components/OnlineServiceList/OnlineServiceList";
import { FlexSpacer } from "../../../common/components/FlexSpacer/FlexSpacer";
import { CallToAction } from "../../../common/components/CallToAction/CallToAction";
import { FJD_OZG_CONTACT_MAILTO } from "../../../common/contact";
import {
  OnlineServiceInformationModal,
  OzgRequestDetailsData
} from "./OnlineServiceInformation/OnlineServiceInformationModal";
import { fetchAllPlatformsByOperator } from "../../api/platforms/platformApi";
import { AfterUseModel, PricingModel } from "../../api/rightOfUse/RightOfUse";
import { useCountOfAvailableOnlineServices } from "../../api/onlinedienste/hooks/useFetchOnlineDiensteTemplates";

const PAGE_SIZE = 20;

function LandingPageV2Component() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [searchResults, setSearchResults] = useState<OnlineServiceV2FlatendLeikaIds[]>([]);
  const [isOnlineServiceInformationOpen, setIsOnlineServiceInformationOpen] = useState(false);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    afterUseModel: undefined,
    pricingModel: undefined,
    platformIds: undefined
  } as FilterValues);
  const [ozgRequestDetailsData, setOzgRequestDetailsData] = useState<OzgRequestDetailsData>({
    fimId: "",
    leikaIds: null,
    ozgId: null,
    serviceTitle: ""
  });

  const { data: platformFilters } = useQuery({
    queryKey: [fetchAllPlatformsByOperator.name, filterValues.afterUseModel],
    queryFn: () => fetchAllPlatformsByOperator(filterValues.afterUseModel),
    select: (data) =>
      data
        ?.flatMap((entry) => entry.platforms)
        .map(
          (platform) =>
            ({
              label: platform.name,
              value: platform.id
            } as FjdMultiSelectOption)
        )
  });

  const onSearchComplete = (data: OnlineServiceV2FlatendLeikaIds[]) => {
    setSearchResults(data);
  };

  const { data: countOfAvailableOnlineService } = useCountOfAvailableOnlineServices();
  const { data: onlineServices } = useFetchOnlineServicesV2(filterValues);

  const onlineDienste = useMemo(() => onlineServices ?? [], [onlineServices]);

  const openOnlineServiceInformationModal = (ozgDetails: OzgRequestDetailsData) => {
    setOzgRequestDetailsData(ozgDetails);
    setIsOnlineServiceInformationOpen(true);
  };

  const closeOnlineServiceInformationModal = () => {
    setIsOnlineServiceInformationOpen(false);
  };

  function computeSubHeadline(element: OnlineServiceV2FlatendLeikaIds) {
    const theArray: string[] = [];
    if (element.topic != null) theArray.push(`${t("landingPageV2.topic")}: ${element.topic}`);
    if (element.ozgId != null) theArray.push(`${t("onlineServiceList.ozgId")}: ${element.ozgId}`);
    if (element.leikaIds != null) theArray.push(`${t("landingPageV2.leikaIds")}: ${element.leikaIds}`);
    return theArray;
  }

  const updateFilterValues = (valuesToUpdate: FilterValues) => {
    setFilterValues((prevState) => ({
      ...prevState,
      ...valuesToUpdate
    }));
  };

  return (
    <Stack spacing="xxl" orientation="vertical">
      <Banner
        heading={t("banner.heading")}
        imageUrl={URL_BACKGROUND_BANNER}
        subheading={t("banner.subHeading")}
        hint={`${countOfAvailableOnlineService ?? 0} ${t("banner.countOnlineServices")}`}
      />
      <NachnutzungsIntro />
      <Heading text={t("landingPage.searchHeadline")} className="search-headline" level={2} />
      <SearchFilterBar<OnlineServiceV2FlatendLeikaIds>
        searchIn={["title", "leikaIds", "ozgId", "topic"]}
        columnFilters={["topic"]}
        placeholder={t("landingPageV2.searchBarPlaceholder")}
        data={onlineDienste}
        menuItemLabel={(result) => `${result.title}`}
        initialColumnFilter={searchParams.get("searchTag")}
        onSearchCompleted={onSearchComplete}
        searchDelay={200}
      />
      <Stack orientation="horizontal" className="online-service-filter-bar" data-testid="online-service-filter-bar">
        <FjdMultiSelect
          id="platformFilter"
          label={t("platform.platforms")}
          onChange={(value) => updateFilterValues({ platformIds: value.length !== 0 ? value : undefined })}
          options={platformFilters ?? []}
        />
        <Stack orientation="horizontal" className="tooltipped-item">
          <FjdCheckbox
            id="pricingModelFilter"
            label={t("landingPageV2.checkboxLabelPricingModelFree")}
            onChange={(value) =>
              updateFilterValues({ pricingModel: value.target.checked ? PricingModel.FREE : undefined })
            }
            disabled={!filterValues.platformIds}
          />
          <FjdTooltip
            // !! nicht das attribute 'showOnClick' setzen, da sonst der inline Link nicht funktioniert. !!
            tooltip={
              <span>
                <Trans i18nKey="landingPageV2.tooltipPricingModelFree">
                  <strong />
                  <br />
                  <a href={FJD_OZG_CONTACT_MAILTO}>Starten Sie das Onboarding</a>
                </Trans>
              </span>
            }
          >
            <FjdIcon glyph="information-outline" />
          </FjdTooltip>
        </Stack>
        <FjdCheckbox
          id="afterUseModelFilter"
          label={t("landingPageV2.checkboxLabelAfterUseModel")}
          onChange={(value) =>
            setFilterValues({ afterUseModel: value.target.checked ? AfterUseModel.MODEL_A : undefined })
          }
        />
      </Stack>

      <OnlineServiceList>
        <OnlineServiceList.Headline>
          {searchResults.length} {t("onlineServiceList.onlineServicesForYourSearch")}
        </OnlineServiceList.Headline>
        <OnlineServiceList.List
          visibleItems={PAGE_SIZE}
          loadAdditionItemsLabel={t("landingPage.loadAdditionOnlineServicesButtonLabel")}
        >
          {searchResults.map((item) => (
            <OnlineServiceList.ListItem
              key={item.onlineServiceId}
              listItemKey={item.onlineServiceId}
              onPreviewButtonClick={() => {
                window.open(item.previewLink, "_blank");
              }}
              onDetailsButtonClick={() =>
                openOnlineServiceInformationModal({
                  serviceTitle: item.title,
                  ozgId: item.ozgId,
                  leikaIds: item.leikaIds,
                  fimId: item.fimId,
                  identifier: item.identifier,
                  topic: item.topic
                })
              }
              previewButtonTooltipLabel={t("onlineServiceList.preview")}
              detailButtonTooltipLabel={t("onlineServiceList.details")}
              preparationTooltipLabel={t("onlineServiceList.inPreparation")}
              headline={item.title}
              isEnabled={item.status !== OnlineServiceStatus.Released}
              subHeadlines={computeSubHeadline(item)}
              subHeadlineBadges={item.afterUseModel?.includes("MODEL_A") ? ["EfA-Leistung"] : []}
            />
          ))}
        </OnlineServiceList.List>
      </OnlineServiceList>
      <FlexSpacer />
      <FlexSpacer />
      <CallToAction
        appeal={t("landingPage.callToAction.appeal")}
        note={t("landingPage.callToAction.note")}
        action={
          <FjdButton
            appearance="outline"
            label={t("landingPage.callToAction.buttonLabel")}
            href={FJD_OZG_CONTACT_MAILTO}
          />
        }
      />
      <FlexSpacer />
      <FlexSpacer />
      {isOnlineServiceInformationOpen && (
        <OnlineServiceInformationModal
          isOpen
          handleModal={closeOnlineServiceInformationModal}
          onlineService={ozgRequestDetailsData}
        />
      )}
    </Stack>
  );
}

export const LandingPageV2 = LandingPageV2Component;
