export const URL_CONTACT = "https://www.fjd.de/kontakt";
export const URL_LEGAL_NOTICE = "https://www.fjd.de/impressum";
export const URL_DATA_POLICY = "https://www.fjd.de/datenschutz";
export const URL_GTC = "https://www.fjd.de/agb";
export const URL_NACHNUTZUNG_MODELS =
  "https://www.onlinezugangsgesetz.de/Webs/OZG/DE/grundlagen/nachnutzung/nachnutzung-node.html";
export const URL_OZG_PLATFORM = "https://informationsplattform.ozg-umsetzung.de/";
export const URL_STATISTISCHES_BUNDESAMT =
  "https://www.destatis.de/DE/Themen/Laender-Regionen/Regionales/Gemeindeverzeichnis/_inhalt.html";
export const URL_BACKGROUND_BANNER = "banner.png";
