import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FjdSelectOption } from "fjd-react-components/build/components/Selects/Select.types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  FjdButtonSelectGroup,
  FjdCheckbox,
  FjdFormControl,
  FjdRadio,
  FjdSelectWithModal,
  FjdStack
} from "fjd-react-components";
import { Modal } from "../../../../../common/components/Modal/Modal";
import { UserContext } from "../../../../../common/libs/authentication/context/UserContext";
import {
  createOrUpdateProducerRightOfPlatformAssignment,
  getProducerRightsOfPlatformAssignment,
  updateRightOfUse
} from "../../../../api/rightOfUse/rightOfUseApi";
import { fetchPlatformData } from "../../../../api/platforms/platformApi";
import { AfterUseModel, RightOfPlatformAssignment, PricingModel } from "../../../../api/rightOfUse/RightOfUse";
import { useNotification } from "../../../../../common/libs/notifications/hooks/useNotification";
import { PlatformEnvironment } from "../../../../api/platforms/Platform";
import { fetchProducers } from "../../../../api/organizations/organizationsApi";

interface ModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

export function ProducerRightOfPlatformAssignmentModal({ onClose, onSuccess }: ModalProps) {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useContext(UserContext);
  const queryClient = useQueryClient();
  const { notifyUserFromResponse, notifyUser } = useNotification();
  const [formData, setFormData] = useState<RightOfPlatformAssignment>({
    id: "",
    platformId: "",
    producerId: "",
    pricingModel: PricingModel.NOT_SPECIFIED,
    afterUseModel: null,
    operatorId: selectedOrganizationId ?? ""
  });

  const { data: fetchedPlatforms } = useQuery({
    queryKey: [fetchPlatformData.name],
    queryFn: fetchPlatformData,
    select: (data) => data?.filter((platform) => platform?.operatorId === selectedOrganizationId)
  });

  const { data: producers } = useQuery({
    queryKey: [fetchProducers.name],
    queryFn: fetchProducers
  });

  const { mutate: createOrUpdateRightOfPlatformAssignment } = useMutation({
    mutationKey: [updateRightOfUse.name],
    mutationFn: createOrUpdateProducerRightOfPlatformAssignment,
    onSettled: notifyUserFromResponse({ successMessage: t("rightsOfUse.successfullyCreated") }),
    onSuccess: () => {
      queryClient
        .invalidateQueries([getProducerRightsOfPlatformAssignment.name, selectedOrganizationId])
        .catch(() => {})
        .finally(() => onSuccess());
    }
  });

  return (
    <Modal
      closable
      onClose={onClose}
      open
      closeOnBackdropClick
      heading={t("rightsOfUse.producerAssignmentRights.modal.heading")}
      onSecondaryButtonClick={onClose}
      secondaryButtonLabel={t("abort")}
      primaryButtonLabel={t("save")}
      onPrimaryButtonClick={() => {
        if (formData.producerId?.length === 0) {
          notifyUser({
            intent: "error",
            title: t("rightsOfUse.producerAssignmentRights.modal.missingHerstellerAssignment")
          });
          return;
        }
        if (!formData.platformId || formData.platformId.trim().length < 1) {
          notifyUser({
            intent: "error",
            title: t("rightsOfUse.producerAssignmentRights.modal.missingPlatformAssignment")
          });
          return;
        }
        createOrUpdateRightOfPlatformAssignment(formData);
      }}
    >
      <FjdStack spacing="xl">
        <FjdFormControl inputId="producerId" label={t("common.producer")}>
          <FjdSelectWithModal
            id="producerId"
            options={
              producers?.map(
                (value) =>
                  ({
                    label: value.name,
                    value: value.id,
                    valueObject: value
                  } as FjdSelectOption)
              ) ?? []
            }
            placeholder={t("rightsOfUse.producerAssignmentRights.modal.selectProducerPlaceholder")}
            onChange={(option) => setFormData({ ...formData, producerId: option[0].value })}
          />
        </FjdFormControl>
        <FjdFormControl label={t("platform.assignPlatformModal.pricingModel")}>
          <FjdButtonSelectGroup
            size="m"
            id="pricingModel"
            onChange={(event) => {
              if (
                event.target.value === PricingModel.NOT_SPECIFIED ||
                event.target.value === PricingModel.FREE ||
                event.target.value === PricingModel.CHARGEABLE
              )
                setFormData({ ...formData, pricingModel: event.target.value });
            }}
            options={[
              {
                text: t("platform.assignPlatformModal.pricingModelNotSpecified"),
                value: PricingModel.NOT_SPECIFIED,
                selected: !formData || formData.pricingModel === PricingModel.NOT_SPECIFIED
              },
              {
                text: t("platform.assignPlatformModal.pricingModelFree"),
                value: PricingModel.FREE,
                selected: formData.pricingModel === PricingModel.FREE
              },
              {
                text: t("platform.assignPlatformModal.pricingModelChargeable"),
                value: PricingModel.CHARGEABLE,
                selected: formData.pricingModel === PricingModel.CHARGEABLE
              }
            ]}
          />
        </FjdFormControl>
        <FjdFormControl label={t("platform.assignPlatformModal.afterUseModel")}>
          <FjdCheckbox
            id="pricingModel"
            data-testid="pricingModel"
            label={t("platform.assignPlatformModal.efaModel")}
            onChange={(e) =>
              setFormData({ ...formData, afterUseModel: e.target.checked ? AfterUseModel.MODEL_A : null })
            }
          />
        </FjdFormControl>
        <FjdFormControl label={t("platform.assignPlatformModal.platform")}>
          <div className="fjd-form-control__inputs" style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {(fetchedPlatforms?.length ?? 0) > 0 &&
              fetchedPlatforms
                ?.filter((platform) => platform.environment === PlatformEnvironment.Prod)
                .map((platform) => (
                  <FjdRadio
                    key={platform.name}
                    onChange={(e) => setFormData({ ...formData, platformId: e.target.value })}
                    id={platform.name}
                    name="fetchedPlatforms"
                    label={platform.name}
                    value={platform.id}
                  />
                ))}
          </div>
        </FjdFormControl>
      </FjdStack>
    </Modal>
  );
}
