import React, { useEffect, useRef, useState } from "react";
import { SearchInput } from "./SearchInput/SearchInput";
import { SearchMenu } from "./SearchMenu/SearchMenu";
import { BadgeList, Label } from "./SearchMenu/BadgeList";
import "./SearchFilterBar.css";
import { StringMap } from "../../utils/StringMap";
import { RemoveIcon } from "../RemoveIcon/RemoveIcon";
import { Divider } from "../Divider/Divider";
import { useFocusListener } from "../../hooks/useFocusListener";
import {
  excludeActivatedFiltersFromFoundFilters,
  executeSearch,
  findAllAvailableFilter,
  SearchableContentType,
  searchForAvailableFilter
} from "./SearchAlgorithmUtils";
import { useDelayedEffectHook } from "../../hooks/useDelayedEffectHook";
import { isRuntimeTest } from "../../utils/environmentUtils";

interface SearchFilterBarProps<T extends SearchableContentType> {
  data: T[];
  columnFilters?: (keyof T)[];
  searchIn: (keyof T)[];
  menuItemLabel: (result: T) => string;
  menuItemKeyAccessor?: (item: T) => string;
  initialSearchInput?: string;
  initialColumnFilter?: string | null;
  initialMenuItemToSelect?: (menuItem: T) => boolean;
  searchDelay: number;
  placeholder?: string;
  appearance?: "sticky" | "relative";
  onSearchCompleted?: (results: T[]) => void;
  onMenuItemSelected?: (result: T | null) => void;
  children?: React.ReactElement;
}

export const LEAST_SEARCH_LENGTH = 1;
const MAX_AMOUNT_SHOWN_SEARCH_RESULTS = 100;

let timeHandler: NodeJS.Timeout | null = null;

const mapRemainingFilterToLabels = <T extends SearchableContentType>(remainingFilter: StringMap<T>) =>
  Object.entries(remainingFilter).flatMap(([columnName, columnValues]) =>
    columnValues.map(
      (value) =>
        ({
          key: `${columnName}.${value}`,
          displayName: value
        } as Label)
    )
  );

/**
 * TBD
 * @param data
 * @param placeholder
 * @param onComplete
 * @param menuItemKeyAccessor necessary to
 * @param onMenuItemSelected
 * @param columnFilters
 * @param searchIn
 * @param initialSearchInput
 * @param initialColumnFilter will only be set, when the available filters of the data matches the initialColumnFilter
 * @param searchDelay
 * @param appearance
 * @constructor
 */
export function SearchFilterBar<T extends SearchableContentType>({
  data,
  placeholder,
  menuItemKeyAccessor,
  onSearchCompleted,
  onMenuItemSelected,
  columnFilters,
  searchIn,
  menuItemLabel,
  initialSearchInput,
  initialColumnFilter,
  initialMenuItemToSelect,
  searchDelay,
  appearance = "sticky",
  children
}: SearchFilterBarProps<T>) {
  const searchBarRef = useRef<HTMLInputElement>(null);
  const searchFilterBarRef = useRef<HTMLInputElement>(null);

  const [menuItems, setMenuItems] = useState<T[]>([]);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>(initialSearchInput ?? "");
  const [selectedResult, setSelectedResult] = useState<T | null>(null);

  const [availableFilter, setAvailableFilter] = useState<StringMap<T> | null>(null);
  const [activatedFilter, setActivatedFilter] = useState<StringMap<T> | null>(null);
  const [activatedFilterForView, setActivatedFilterForView] = useState<Array<Label>>([]);
  const [remainingFilter, setRemainingFilter] = useState<StringMap<T> | null>(null);

  const hasAnySearchBarComponentFocus =
    searchFilterBarRef && searchFilterBarRef.current
      ? searchFilterBarRef.current.contains(document.activeElement)
      : false;
  const hasSearchBarFocus = useFocusListener(searchFilterBarRef) || hasAnySearchBarComponentFocus;

  const delay = isRuntimeTest ? 0 : searchDelay;

  const handleMenuFocus = () => {
    if (hasSearchBarFocus && menuItems.length > 0 && inputValue.length > 0 && selectedResult === null)
      setIsMenuVisible(true);
    if (!hasSearchBarFocus) {
      setIsMenuVisible(false);
      if (selectedResult === null && inputValue.length >= LEAST_SEARCH_LENGTH && onMenuItemSelected) {
        setInputValue("");
      }
    }
  };

  const search = (searchInput: string, activatedFilters: StringMap<T> | null) => {
    const searchResults = executeSearch(data, searchInput, searchIn, activatedFilters);
    setMenuItems(searchResults.slice(0, MAX_AMOUNT_SHOWN_SEARCH_RESULTS));
    if (onSearchCompleted) {
      onSearchCompleted(searchResults);
    }

    if (availableFilter) {
      const foundFilters = searchForAvailableFilter(searchInput, availableFilter);
      const remainingFilters = excludeActivatedFiltersFromFoundFilters(activatedFilters, foundFilters);
      setRemainingFilter(remainingFilters);
    }
  };

  useDelayedEffectHook(handleMenuFocus, [hasSearchBarFocus, selectedResult, inputValue], delay + 10);

  const handleSearchInputChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (timeHandler) clearTimeout(timeHandler);
    timeHandler = setTimeout(() => search(e.target.value.toLowerCase(), activatedFilter), delay);

    setInputValue(e.target.value);
    setSelectedResult(null);
    if (onMenuItemSelected && selectedResult !== null) {
      onMenuItemSelected(null);
    }
  };

  const handleClickOnSuggestionBadge: (label: Label) => React.MouseEventHandler =
    ({ key, displayName }: Label) =>
    () => {
      const filterTopic = key.split(".").at(0);
      if (filterTopic === undefined) return;
      let newFilter = { [filterTopic]: [displayName] } as StringMap<T>;

      if (activatedFilter && Object.hasOwn(activatedFilter, filterTopic)) {
        newFilter = { [filterTopic]: [...activatedFilter[filterTopic], displayName] } as StringMap<T>;
      }
      const newActivatedFilters = activatedFilter ? { ...activatedFilter, ...newFilter } : newFilter;
      setActivatedFilter(newActivatedFilters);
      search("", newActivatedFilters);

      setActivatedFilterForView([...activatedFilterForView, { key, displayName }]);
      setInputValue("");

      if (searchBarRef.current) searchBarRef.current.focus();
    };

  const handleClickOnMenuItem = (searchResult: T) => () => {
    if (onMenuItemSelected) {
      onMenuItemSelected(searchResult);
    }
    if (onSearchCompleted) {
      onSearchCompleted([searchResult]);
    }

    setIsMenuVisible(false);
    setInputValue(menuItemLabel(searchResult));
    setSelectedResult(searchResult);
  };

  const selectMenuItem = handleClickOnMenuItem;

  const handleClickOnActiveBadge: (label: Label) => React.MouseEventHandler =
    ({ key, displayName }: Label) =>
    () => {
      if (activatedFilter) {
        const filterTopic = key.split(".").at(0);
        if (filterTopic === undefined) return;

        let newFilter = { [filterTopic]: [displayName] };
        if (Object.hasOwn(activatedFilter, filterTopic))
          newFilter = {
            [filterTopic]: [...activatedFilter[filterTopic].filter((filterBadge) => !filterBadge.includes(displayName))]
          };

        const newActivatedFilter = { ...activatedFilter, ...newFilter };
        setActivatedFilter(newActivatedFilter);
        setActivatedFilterForView([...activatedFilterForView.filter((filterBadge) => !filterBadge.key.includes(key))]);

        search("", newActivatedFilter);
      }

      if (searchBarRef.current) searchBarRef.current.focus();
    };

  const handleClearButton: React.MouseEventHandler = () => {
    setIsMenuVisible(false);
    setActivatedFilter(null);
    setActivatedFilterForView([]);
    setInputValue("");
    setSelectedResult(null);

    if (onSearchCompleted) onSearchCompleted(data);
    if (onMenuItemSelected && selectedResult !== null) onMenuItemSelected(null);
  };

  const removeFocusOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchBarRef.current) searchBarRef.current.blur();
  };

  const initiliazeFilters = () => {
    const availableFilterBadges = columnFilters ? findAllAvailableFilter(data, columnFilters) : null;
    setAvailableFilter(availableFilterBadges);

    let activeFilter: StringMap<T> | null = activatedFilter;
    let searchInput = inputValue;

    if (availableFilterBadges && initialColumnFilter && initialColumnFilter.length > 0 && data.length > 0) {
      const matchingFilters: { badgeTopic: string; badgeName: string }[] = [];
      Object.entries<string[]>(availableFilterBadges).forEach(([badgeTopic, badges]) =>
        badges.forEach(
          (badge) => badge === initialColumnFilter && matchingFilters.push({ badgeTopic, badgeName: badge })
        )
      );
      if (matchingFilters.length === 1) {
        activeFilter = { [matchingFilters[0].badgeTopic]: [matchingFilters[0].badgeName] } as StringMap<T>;
        setActivatedFilter(activeFilter);
        setActivatedFilterForView([
          {
            key: `${matchingFilters[0].badgeTopic}.${matchingFilters[0].badgeName}`,
            displayName: matchingFilters[0].badgeName
          }
        ]);
      } else {
        setInputValue(initialColumnFilter);
        searchInput = initialColumnFilter;
      }
    }

    search(searchInput, activeFilter);

    if (onMenuItemSelected && initialMenuItemToSelect && data.length > 0) {
      const menuItem = data.find(initialMenuItemToSelect);
      if (menuItem) {
        selectMenuItem(menuItem)();
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initiliazeFilters, [data]);

  return (
    <div className={`search-filter-bar-wrapper search-filter-bar-wrapper-${appearance}`} ref={searchFilterBarRef}>
      <div className="search-filter-bar">
        <span className="search-filter-bar-icon" />
        <BadgeList clickOnBadge={handleClickOnActiveBadge} labels={activatedFilterForView} hasRemoveIcon>
          <SearchInput
            ref={searchBarRef}
            placeholder={placeholder ?? ""}
            value={inputValue}
            onChange={handleSearchInputChanged}
            onKeyDown={removeFocusOnEnter}
          />
        </BadgeList>

        {(activatedFilter || inputValue.length > 0) && (
          <button type="button" className="clear-all" onClick={handleClearButton} data-testid="clearAllButton">
            <RemoveIcon />
          </button>
        )}
      </div>
      {isMenuVisible && (
        <SearchMenu>
          {remainingFilter && Object.values(remainingFilter).flatMap((entry) => entry).length > 0 && (
            <>
              <BadgeList
                clickOnBadge={handleClickOnSuggestionBadge}
                labels={mapRemainingFilterToLabels(remainingFilter)}
                hasRemoveIcon={false}
              />
              <Divider />
            </>
          )}
          {menuItems.map((result) => (
            <SearchMenu.SearchMenuItem
              key={menuItemKeyAccessor ? menuItemKeyAccessor(result) : menuItemLabel(result)}
              onClick={handleClickOnMenuItem(result)}
            >
              {menuItemLabel(result)}
            </SearchMenu.SearchMenuItem>
          ))}
        </SearchMenu>
      )}
      {children}
    </div>
  );
}
