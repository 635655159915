import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  FjdButtonSelectGroup,
  FjdCheckbox,
  FjdFormControl,
  FjdHeading,
  FjdNewSelect,
  FjdRadio,
  FjdStack
} from "fjd-react-components";
import { Modal } from "../../../../../common/components/Modal/Modal";
import { UserContext } from "../../../../../common/libs/authentication/context/UserContext";
import { MktTable } from "../../../../../common/components/Table/Table";
import { fetchRightsOfUse, updateRightOfUse } from "../../../../api/rightOfUse/rightOfUseApi";
import { fetchPlatformData } from "../../../../api/platforms/platformApi";
import {
  AfterUseModel,
  PricingModel,
  RightOfUse,
  PlatformAssignment,
  AreaOfUseIdentifier
} from "../../../../api/rightOfUse/RightOfUse";
import { useNotification } from "../../../../../common/libs/notifications/hooks/useNotification";
import { PlatformEnvironment } from "../../../../api/platforms/Platform";

interface ModalProps {
  isModalVisible: boolean;
  handleModalClose: () => void;
}

interface AssignPlatformForm extends PlatformAssignment {
  rightOfUse: AreaOfUseIdentifier[];
}

export function AssignPlatformToRightOfUseModal({ isModalVisible, handleModalClose }: ModalProps) {
  const [filteredRightsOfUse, setFilteredRightsOfUse] = useState<RightOfUse[]>([]);
  const [platformForm, setPlatformForm] = useState<AssignPlatformForm>({
    rightOfUse: [],
    afterUseModel: null,
    pricingModel: PricingModel.NOT_SPECIFIED,
    platformId: ""
  });

  const { t } = useTranslation();
  const { selectedOrganizationId } = useContext(UserContext);
  const { notifyUserFromResponse, notifyUser } = useNotification();

  const { data: rightsOfUse } = useQuery({
    queryKey: [fetchRightsOfUse.name, selectedOrganizationId],
    queryFn: () => fetchRightsOfUse({ operatorId: selectedOrganizationId ?? undefined }),
    select: (data) => data.filter((rightOfUse) => (rightOfUse.platformName?.length ?? 0) === 0),
    onSuccess: (data) => setFilteredRightsOfUse(data)
  });

  const { data: fetchedPlatforms } = useQuery({
    queryKey: [fetchPlatformData.name],
    queryFn: fetchPlatformData,
    select: (data) => data?.filter((platform) => platform?.operatorId === selectedOrganizationId)
  });

  const { mutate: mutateRightOfUse } = useMutation({
    mutationKey: [updateRightOfUse.name],
    mutationFn: updateRightOfUse,
    onSettled: notifyUserFromResponse({ successMessage: t("rightsOfUse.successfullyCreated") }),
    onSuccess: handleModalClose
  });

  return (
    <Modal
      closable
      onClose={handleModalClose}
      open={isModalVisible}
      closeOnBackdropClick
      heading={t("platform.assignPlatformModal.heading")}
      onSecondaryButtonClick={handleModalClose}
      secondaryButtonLabel={t("platform.assignPlatformModal.secondaryButton")}
      primaryButtonLabel={t("platform.assignPlatformModal.primaryButton")}
      onPrimaryButtonClick={() => {
        if (platformForm.rightOfUse.length === 0) {
          notifyUser({ intent: "error", title: t("platform.assignPlatformModal.missingRightOfUseAssignment") });
          return;
        }
        if (!platformForm.platformId) {
          notifyUser({ intent: "error", title: t("platform.assignPlatformModal.missingPlatformAssignment") });
          return;
        }
        mutateRightOfUse(platformForm.rightOfUse.map((rightOfUseId) => ({ ...rightOfUseId, ...platformForm })));
      }}
    >
      <FjdStack spacing="xl">
        <FjdFormControl>
          <FjdNewSelect
            id="rightOfUseAreaSelect"
            searchText={t("platform.assignPlatformModal.rightOfUseAreaSelect")}
            canDelete
            onChange={(selectedItem) => {
              let filteredItems: RightOfUse[] = [];
              if (rightsOfUse && selectedItem.length === 1) {
                filteredItems = rightsOfUse.filter(
                  (filteredRightOfUse) => filteredRightOfUse.rightOfUseArea === selectedItem[0].value
                );
              } else if (rightsOfUse && selectedItem.length === 0) {
                filteredItems = rightsOfUse;
              }
              setFilteredRightsOfUse(filteredItems);
            }}
            options={
              rightsOfUse?.map((rightOfUse) => ({
                label: rightOfUse.rightOfUseArea,
                value: rightOfUse.rightOfUseArea
              })) ?? []
            }
          />
        </FjdFormControl>

        <FjdHeading level={4} text={t("platform.assignPlatformModal.tableHeading")} />
        {filteredRightsOfUse && (
          <MktTable
            multiSelect
            data={filteredRightsOfUse}
            onMultiSelectionChange={(data: RightOfUse[]) => {
              setPlatformForm({
                ...platformForm,
                rightOfUse: data.map((rightOfUse) => ({
                  rightOfUseId: rightOfUse.id,
                  areaOfUseId: rightOfUse.areaOfUseId
                }))
              });
            }}
            className="RightOfUseTable"
            columns={[
              {
                Header: `${t("rightsOfUse.table.identifier")}`,
                accessor: "onlineServiceIdentifier"
              },
              {
                Header: `${t("rightsOfUse.table.area")}`,
                accessor: "rightOfUseArea"
              },
              {
                Header: "",
                accessor: "_id"
              }
            ]}
          />
        )}
        <FjdFormControl label={t("platform.assignPlatformModal.pricingModel")}>
          <FjdButtonSelectGroup
            size="m"
            id="pricingModel"
            onChange={(event) => {
              if (
                event.target.value === PricingModel.NOT_SPECIFIED ||
                event.target.value === PricingModel.FREE ||
                event.target.value === PricingModel.CHARGEABLE
              )
                setPlatformForm({ ...platformForm, pricingModel: event.target.value });
            }}
            options={[
              {
                text: t("platform.assignPlatformModal.pricingModelNotSpecified"),
                value: PricingModel.NOT_SPECIFIED,
                selected: platformForm.pricingModel === PricingModel.NOT_SPECIFIED
              },
              {
                text: t("platform.assignPlatformModal.pricingModelFree"),
                value: PricingModel.FREE,
                selected: platformForm.pricingModel === PricingModel.FREE
              },
              {
                text: t("platform.assignPlatformModal.pricingModelChargeable"),
                value: PricingModel.CHARGEABLE,
                selected: platformForm.pricingModel === PricingModel.CHARGEABLE
              }
            ]}
          />
        </FjdFormControl>
        <FjdFormControl label={t("platform.assignPlatformModal.afterUseModel")}>
          <FjdCheckbox
            id="pricingModel"
            data-testid="pricingModel"
            label={t("platform.assignPlatformModal.efaModel")}
            onChange={(e) =>
              setPlatformForm({ ...platformForm, afterUseModel: e.target.checked ? AfterUseModel.MODEL_A : null })
            }
          />
        </FjdFormControl>
        <FjdFormControl label={t("platform.assignPlatformModal.platform")}>
          <div className="fjd-form-control__inputs" style={{ flexDirection: "row", flexWrap: "wrap" }}>
            {(fetchedPlatforms?.length ?? 0) > 0 &&
              fetchedPlatforms
                ?.filter((platform) => platform.environment === PlatformEnvironment.Prod)
                .map((platform) => (
                  <FjdRadio
                    key={platform.name}
                    onChange={(e) => setPlatformForm({ ...platformForm, platformId: e.target.value })}
                    id={platform.name}
                    name="fetchedPlatforms"
                    label={platform.name}
                    value={platform.id}
                  />
                ))}
          </div>
        </FjdFormControl>
      </FjdStack>
    </Modal>
  );
}
