import React, { ErrorInfo } from "react";

export class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="error-page">
          <h1>Oops!</h1>
          <span>Es ist ein unerwarteter Fehler aufgetreten. Bitte laden sie die Seite neu.</span>
        </div>
      );
    }

    return this.props.children;
  }
}
