import React from "react";
import { FjdModalProps } from "fjd-react-components/build/components/Modal/Modal";
import { FjdModal } from "fjd-react-components";

export function Modal({
  appElement,
  backButtonLabel,
  children,
  closable,
  closeOnBackdropClick,
  data,
  heading,
  id,
  labels,
  loading,
  onBackButtonClick,
  onClose,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  open,
  parentSelector,
  primaryButtonDisabled,
  primaryButtonLabel,
  ariaPrimaryButtonLabel,
  reducedHeight,
  secondaryButtonDisabled,
  secondaryButtonLabel,
  ariaSecondaryButtonLabel,
  "data-testid": dataTestId,
  width
}: FjdModalProps) {
  return (
    <FjdModal
      appElement={appElement ?? document.body}
      backButtonLabel={backButtonLabel}
      closable={closable ?? true}
      closeOnBackdropClick={closeOnBackdropClick ?? true}
      data={data}
      heading={heading}
      id={id}
      labels={labels}
      loading={loading}
      onBackButtonClick={onBackButtonClick}
      onClose={onClose}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      open={open}
      parentSelector={parentSelector}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonLabel={primaryButtonLabel}
      ariaPrimaryButtonLabel={ariaPrimaryButtonLabel}
      reducedHeight={reducedHeight}
      secondaryButtonDisabled={secondaryButtonDisabled}
      secondaryButtonLabel={secondaryButtonLabel}
      ariaSecondaryButtonLabel={ariaSecondaryButtonLabel}
      data-testid={dataTestId}
      width={width}
    >
      {children}
    </FjdModal>
  );
}
