import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { FjdFormControl, FjdTextInput } from "fjd-react-components";
import { Modal } from "../../../../common/components/Modal/Modal";
import {
  createOrUpdateAppAccess,
  ProducerAppAccessView
} from "../../../api/appAccessConfiguration/producerAppAccessConfigurationApi";
import { UserContext } from "../../../../common/libs/authentication/context/UserContext";
import { useNotification } from "../../../../common/libs/notifications/hooks/useNotification";

interface AddMarketplaceAppAccessModalProps {
  onSuccess: () => void;
  appAccessZugangsdaten?: ProducerAppAccessView;
  onClose: () => void;
}

interface formData {
  name: string;
}

export function AddMarketplaceAnbindungZugangsdatenModal({
  onSuccess,
  appAccessZugangsdaten,
  onClose
}: AddMarketplaceAppAccessModalProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<formData>({ name: appAccessZugangsdaten?.name } as formData);
  const { selectedOrganizationId } = useContext(UserContext);
  const { notifyUserFromResponse } = useNotification();

  const { mutate } = useMutation({
    mutationKey: [createOrUpdateAppAccess.name, selectedOrganizationId],
    mutationFn: () =>
      createOrUpdateAppAccess({
        name: formData.name,
        producerId: appAccessZugangsdaten?.producerId ?? selectedOrganizationId ?? "",
        clientId: appAccessZugangsdaten?.clientId
      }),
    onSettled: notifyUserFromResponse(),
    onSuccess: () => onSuccess()
  });

  return (
    <Modal
      open
      heading={t("appsConfiguration.zugangsDatenModalHeading")}
      onClose={() => {
        onClose();
      }}
      primaryButtonLabel={t("save")}
      secondaryButtonLabel={t("abort")}
      onPrimaryButtonClick={() => {
        mutate();
      }}
      onSecondaryButtonClick={() => onClose()}
    >
      <FjdFormControl inputId="name" label={t("Name")}>
        <FjdTextInput
          id="name"
          testId="name"
          label={t("appsConfiguration.apiCredentialsName")}
          placeholder={t("appsConfiguration.apiCredentialsName")}
          value={formData.name}
          onChange={(changeEvent) => setFormData({ name: changeEvent.target.value })}
        />
      </FjdFormControl>
    </Modal>
  );
}
