import React from "react";
import { Modal } from "../Modal/Modal";

export interface ConfirmationModalProps {
  confirmationButtonLabel: string;
  abortButtonLabel: string;
  confirmationHeading: string;
  onConfirm: () => void;
  onAbort: () => void;
  isConfirmationModalVisible: boolean;
  "data-testid"?: string;
  content?: React.ReactNode;
}

export function ConfirmationModal({
  confirmationHeading,
  confirmationButtonLabel,
  abortButtonLabel,
  onConfirm,
  onAbort,
  isConfirmationModalVisible,
  "data-testid": dataTestId,
  content
}: ConfirmationModalProps) {
  return (
    <Modal
      closable
      closeOnBackdropClick
      appElement={document.body}
      onClose={onAbort}
      open={isConfirmationModalVisible}
      primaryButtonLabel={confirmationButtonLabel}
      onPrimaryButtonClick={onConfirm}
      secondaryButtonLabel={abortButtonLabel}
      onSecondaryButtonClick={onAbort}
      heading={confirmationHeading}
      data-testid={dataTestId ?? "confirmation-modal"}
    >
      {content}
    </Modal>
  );
}
