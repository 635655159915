import React from "react";
import { FjdStack } from "fjd-react-components";
import { FjdStackProps } from "fjd-react-components/build/components/Stack/Stack";

export function Stack({ children, elRef, orientation, spacing, role, id, className }: FjdStackProps) {
  return (
    <FjdStack
      key={id}
      elRef={elRef}
      orientation={orientation}
      spacing={spacing}
      role={role}
      id={id}
      className={className}
    >
      {children}
    </FjdStack>
  );
}
