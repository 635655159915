import React from "react";
import "./FileDescription.css";
import { FjdIcon, FjdLink } from "fjd-react-components";
import { Tab } from "../Tab/Tab";

interface FileDescriptionProps {
  filename: string;
  description?: string;
  onClick: () => void;
  "data-testId"?: string;
  className?: string;
}

export function FileDescription({
  filename,
  onClick,
  description,
  className,
  "data-testId": testId
}: FileDescriptionProps): JSX.Element {
  return (
    <div>
      <div className={className}>
        <FjdLink data-testid={testId} onClick={onClick}>
          <span className="file-description__button-line">
            <span>{filename}</span>
            <Tab rems={0.5} />
            <span>
              <FjdIcon appearance="default" glyph="download" size="s" />
            </span>
          </span>
        </FjdLink>
      </div>
      <span className="file-description__meta-info">{description}</span>
    </div>
  );
}
