import FormData from "form-data";
import { MARKETPLACE_URL_BASE, mktClient } from "../../../common/libs/axiosClient";
import {
  NewWorkingVersionProps,
  NewOnlineDienstProps,
  ValidationResult,
  OnlineServiceTemplateFlat,
  OnlineServiceTemplate,
  OnlineServiceVersionCreateOrUpdateProps
} from "./OnlineServicesV1";
import { makeEmptyStringsUndefined } from "../../../common/utils/StringUtils";
import { AvailableOnlineServicesCount } from "./OnlineServiceV2";
import { downloadFileViaContentDisposition } from "../../../common/utils/HttpUtils";

export const ONLINE_SERVICE_URL = `${MARKETPLACE_URL_BASE}/online-service-templates`;
export const ONLINE_SERVICE_COUNT_URL = `${ONLINE_SERVICE_URL}/count-of-released-templates`;
export const ONLINE_SERVICE_BY_ID = (id: string) => `${ONLINE_SERVICE_URL.toString()}/${id}`;

const MULITPART_ONLINE_DIENST_PACKAGE = "package";
const MULTIPART_BODY = "body";
const RELEASE_NOTE_ATTACHMENT = "release-note-attachment";

export const createNewOnlineDienst = ({
  newOnlineServiceProps,
  onlineServicePackage,
  releaseNoteAttachments
}: {
  newOnlineServiceProps: NewOnlineDienstProps;
  onlineServicePackage: File;
  releaseNoteAttachments?: File[] | null;
}) => {
  const form = new FormData();
  form.append(MULTIPART_BODY, new Blob([JSON.stringify(newOnlineServiceProps)]));
  form.append(MULITPART_ONLINE_DIENST_PACKAGE, onlineServicePackage, onlineServicePackage.name);
  if (releaseNoteAttachments) {
    releaseNoteAttachments.forEach((file) => {
      form.append(RELEASE_NOTE_ATTACHMENT, file, file.name);
    });
  }
  return mktClient.post<string>(`${ONLINE_SERVICE_URL.toString()}`, form).then((response) => response.data);
};

export const createNewWorkingversion = ({
  onlineServiceId,
  newWorkingVersionProps,
  onlineServicePackage,
  releaseNoteAttachments
}: {
  onlineServiceId: string;
  newWorkingVersionProps: NewWorkingVersionProps;
  onlineServicePackage: File;
  releaseNoteAttachments?: File[] | null;
}) => {
  const form = new FormData();
  form.append(MULTIPART_BODY, new Blob([JSON.stringify(newWorkingVersionProps)]));
  form.append(MULITPART_ONLINE_DIENST_PACKAGE, onlineServicePackage);

  if (releaseNoteAttachments) {
    releaseNoteAttachments.forEach((file) => {
      form.append(RELEASE_NOTE_ATTACHMENT, file, file.name);
    });
  }
  return mktClient.put<unknown>(`${ONLINE_SERVICE_URL}/${onlineServiceId}`, form).then((response) => response.data);
};

export const updateTemplateVersion = ({
  templateId,
  versionId,
  formData,
  releaseNoteAttachments
}: {
  templateId: string;
  versionId: string;
  formData: OnlineServiceVersionCreateOrUpdateProps;
  releaseNoteAttachments?: File[];
}) => {
  const form = new FormData();
  form.append(MULTIPART_BODY, new Blob([JSON.stringify(makeEmptyStringsUndefined(formData))]));
  if (releaseNoteAttachments) {
    releaseNoteAttachments.forEach((file) => {
      form.append(RELEASE_NOTE_ATTACHMENT, file, file.name);
    });
  }

  return mktClient.put<unknown>(`${ONLINE_SERVICE_URL}/${templateId}/versions/${versionId}`, form, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*"
    }
  });
};

export const fetchOnlineServiceTemplatesFlat = () =>
  mktClient
    .get<OnlineServiceTemplateFlat[]>(ONLINE_SERVICE_URL.toString(), { params: { flat: true } })
    .then<OnlineServiceTemplateFlat[]>((response) => response.data);

export const fetchOnlineServiceTemplateById = (id: string) =>
  mktClient
    .get<OnlineServiceTemplate>(ONLINE_SERVICE_BY_ID(id))
    .then<OnlineServiceTemplate>((response) => response.data);

export const downloadOnlineServiceTemplateReleaseNoteAttachmentsById = ({
  fileId,
  onlineServiceId,
  templateVersionId
}: {
  fileId: string;
  onlineServiceId: string;
  templateVersionId: string;
}) =>
  mktClient
    .get<File>(
      `${ONLINE_SERVICE_URL.toString()}/${onlineServiceId}/version/${templateVersionId}/release-note-attachments/${fileId}`,
      { responseType: "blob" }
    )
    .then(downloadFileViaContentDisposition);

interface ReleaseTemplateByIdRequestBody {
  notifyAuthoritiesAboutRelease: boolean;
}
export const releaseTemplateById = ({
  onlineServiceId,
  requestBody
}: {
  onlineServiceId: string;
  requestBody: ReleaseTemplateByIdRequestBody;
}) => mktClient.put(`${ONLINE_SERVICE_URL.toString()}/${onlineServiceId}/release`, requestBody);

export const revokeReleaseById = ({
  onlineServiceId,
  onlineServiceVersionId
}: {
  onlineServiceId: string;
  onlineServiceVersionId: string;
}) =>
  mktClient.put(`${ONLINE_SERVICE_URL.toString()}/${onlineServiceId}/version/${onlineServiceVersionId}/revoke-release`);

export const deleteOnlineDienstAndVersionsById = ({ onlineServiceId }: { onlineServiceId: string }) =>
  mktClient.delete(`${ONLINE_SERVICE_URL.toString()}/${onlineServiceId}`);

export const extractAndValidateMetaInfo = (thePackage: File) => {
  const data = new FormData();
  data.append("package", thePackage);
  return mktClient.post<ValidationResult>(`${ONLINE_SERVICE_URL}/extract-meta-info`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*"
    }
  });
};

export const extractAndValidateMetaInfoRegardingTemplate = ({
  thePackage,
  onlineServiceId
}: {
  thePackage: File;
  onlineServiceId: string;
}) => {
  const data = new FormData();
  data.append("package", thePackage);
  return mktClient.post<ValidationResult>(
    `${ONLINE_SERVICE_URL}/${onlineServiceId}/validate-and-extract-package`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*"
      }
    }
  );
};

export const fetchCountOfAvailableOnlineServices = () =>
  mktClient
    .get<AvailableOnlineServicesCount>(ONLINE_SERVICE_COUNT_URL)
    .then<AvailableOnlineServicesCount>((response) => response.data);
