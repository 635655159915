import { AfterUseModel, PricingModel } from "../rightOfUse/RightOfUse";

export enum OnlineServiceStatus {
  WorkedOn = "WORKED_ON",
  Released = "RELEASED"
}

export interface OnlineServiceV2 {
  fimId: string;
  identifier: string;
  leikaIds: string[] | null;
  onlineServiceId: string;
  ozgId: string | null;
  previewLink: string;
  status: OnlineServiceStatus;
  title: string;
  topic: string;
  version: string;
  pricing: string[];
  afterUseModel: string[];
}

export type OnlineServiceV2FlatendLeikaIds = Omit<OnlineServiceV2, "leikaIds"> & { leikaIds: string | null };

export interface AvailableOnlineServicesCount {
  count: number;
}

export interface FilterValues {
  platformIds?: string[];
  pricingModel?: PricingModel;
  afterUseModel?: AfterUseModel;
}

export interface PlatformDetails {
  id: string;
  name: string;
}

export interface PlatformByOperators {
  operatorId: string;
  operatorName: string;
  platforms: PlatformDetails[];
}
