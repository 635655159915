import React from "react";
import { FjdMarkdownProps } from "fjd-react-components/build/components/Markdown/Markdown";
import { FjdMarkdown } from "fjd-react-components";

export interface MarkdownProps extends FjdMarkdownProps {
  className?: string;
}

export function Markdown({
  allowHTML,
  "aria-label": ariaLabel,
  "aria-hidden": ariaHidden,
  color,
  customTagsToReplace,
  disabled,
  formatElementIds,
  maxLines,
  source,
  useParagraphs,
  tag: Tag,
  id,
  testId,
  className
}: MarkdownProps) {
  return (
    <div className={className}>
      <FjdMarkdown
        allowHTML={allowHTML}
        aria-label={ariaLabel}
        aria-hidden={ariaHidden}
        color={color}
        customTagsToReplace={customTagsToReplace}
        disabled={disabled}
        formatElementIds={formatElementIds}
        maxLines={maxLines}
        source={source}
        useParagraphs={useParagraphs}
        tag={Tag}
        id={id}
        testId={testId}
      />
    </div>
  );
}
