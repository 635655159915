import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FjdIcon, FjdMarkdown, FjdTooltip } from "fjd-react-components";
import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { Modal } from "../../../../common/components/Modal/Modal";
import { Stack } from "../../../../common/components/Stack/Stack";
import "./OnlineServiceInformationModal.css";
import { fetchLeikaData, fetchOzgData } from "../../../api/catalog/catalogApi";
import { LeikaAndEntityCollection } from "../../../api/catalog/Catalog";
import { KeyValueList } from "../../../../common/components/KeyValueList/KeyValueList";
import { TabBar } from "../../../../common/components/TabBar/TabBar";

// TODO: instead of using a custom interface we should pass the desired service directly without abstraction
export interface OzgRequestDetailsData {
  serviceTitle: string;
  ozgId: string | null;
  leikaIds: string | null; // Could be comma separated ids like: 3123123,123213,13122
  fimId: string;
  identifier?: string;
  topic?: string;
  state?: string;
}

interface ModalProps {
  isOpen: boolean;
  handleModal: () => void;
  onlineService: OzgRequestDetailsData;
}

export function OnlineServiceInformationModal({ isOpen, handleModal, onlineService }: ModalProps) {
  const { t } = useTranslation();

  const unknownOnlineDiensteInformation = t("onlineServiceInformation.businessInformationContent.notKnown");
  const loadingData = t("onlineServiceInformation.businessInformationContent.isLoading");

  const { isFetching: isLoadingLeikaEntity, data: leikaAndOzgEntities } = useQuery<
    LeikaAndEntityCollection,
    AxiosError
  >({
    queryKey: [fetchOzgData.name, onlineService.ozgId, onlineService.leikaIds],
    queryFn: () => fetchLeikaData(onlineService.leikaIds ?? ""),
    enabled: (onlineService.leikaIds?.split(",")?.length ?? 0) > 0
  });

  const { isFetching: isLoadingOzgEntity, data: ozgEntity } = useQuery({
    queryKey: [fetchOzgData.name, onlineService.ozgId],
    queryFn: () => fetchOzgData(onlineService.ozgId ?? ""),
    enabled: onlineService.ozgId !== null
  });

  const onlineServiceInformationLabel = useMemo(() => {
    let leikaDescription: string | React.ReactNode = unknownOnlineDiensteInformation;

    if (isLoadingLeikaEntity) {
      leikaDescription = loadingData;
    } else {
      if (leikaAndOzgEntities && (leikaAndOzgEntities?.leikaEntities?.length ?? 0) > 1)
        leikaDescription = (
          <ul>
            {leikaAndOzgEntities.leikaEntities.map((leikaEntity) => (
              <li key={leikaEntity.id}>{`${leikaEntity?.description ?? ""} (LeiKa-ID ${leikaEntity.id ?? ""})`}</li>
            ))}
          </ul>
        );
      if (leikaAndOzgEntities && (leikaAndOzgEntities?.leikaEntities?.length ?? 0) === 1) {
        leikaDescription = `${leikaAndOzgEntities.leikaEntities[0].description ?? ""} (LeiKa-ID ${
          leikaAndOzgEntities.leikaEntities[0].id ?? ""
        })`;
      }
    }

    const ozg = ozgEntity ?? leikaAndOzgEntities?.ozgEntity;
    let ozgDescription = unknownOnlineDiensteInformation;
    let ozgThemenfeld = unknownOnlineDiensteInformation;
    if (isLoadingOzgEntity) {
      ozgDescription = loadingData;
      ozgThemenfeld = loadingData;
    } else if (ozg) {
      ozgDescription = `${ozg.description} (OZG-ID ${ozg.id})`;
      ozgThemenfeld = ozg.topic;
    }

    return {
      leikaDescription,
      ozgDescription,
      ozgThemenfeld
    };
  }, [
    loadingData,
    leikaAndOzgEntities,
    unknownOnlineDiensteInformation,
    isLoadingLeikaEntity,
    isLoadingOzgEntity,
    ozgEntity
  ]);

  return (
    <Modal
      open={isOpen}
      data-testid="OnlineServiceInformation"
      onClose={handleModal}
      closable
      width="wide"
      closeOnBackdropClick
      data={{
        test: "OnlineServiceInformation"
      }}
      heading={onlineService.serviceTitle}
    >
      <Stack orientation="vertical" spacing="4xl">
        <TabBar>
          <TabBar.Tab label={t("onlineServiceInformation.businessInformation")} index={0} id="businessInformation" />
          <TabBar.Tab label={t("onlineServiceInformation.features")} index={1} id="features" />
          <TabBar.Panel index={0}>
            <Stack orientation="vertical" spacing="xl">
              <KeyValueList>
                <KeyValueList.Section
                  key="classification"
                  sectionHeader={t("onlineServiceInformation.businessInformationContent.classification")}
                >
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.businessInformationContent.topicField")}
                    value={onlineServiceInformationLabel.ozgThemenfeld}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.businessInformationContent.ogzDescription")}
                    value={onlineServiceInformationLabel.ozgDescription}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.businessInformationContent.leikaDescription")}
                    value={onlineServiceInformationLabel.leikaDescription}
                  />
                </KeyValueList.Section>
                <KeyValueList.Section
                  key="version"
                  sectionHeader={t("onlineServiceInformation.businessInformationContent.version")}
                >
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.businessInformationContent.fimId")}
                    value={onlineService.fimId}
                    hideIfNoValue
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.businessInformationContent.producer")}
                    value={t("onlineServiceInformation.businessInformationContent.fjd")}
                  />
                </KeyValueList.Section>
              </KeyValueList>
            </Stack>
          </TabBar.Panel>
          <TabBar.Panel index={1}>
            <Stack orientation="vertical" spacing="xl">
              <Stack orientation="horizontal" spacing="m" className="hint">
                <FjdIcon appearance="primary" glyph="information-outline" />
                <FjdMarkdown source={t(`onlineServiceInformation.featureContent.hint`)} />
              </Stack>
              <KeyValueList>
                <KeyValueList.Section
                  key="servicesAndInterfaces"
                  sectionHeader={t("onlineServiceInformation.featureContent.servicesAndInterfaces")}
                >
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.destinationPlatforms")}
                    value={t("onlineServiceInformation.featureContent.govOs")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.interfaces")}
                    value={t("onlineServiceInformation.featureContent.interfaceDetails")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.serviceAccounts")}
                    value={t("onlineServiceInformation.featureContent.serviceAccountsDetails")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.payServices")}
                    value={t("onlineServiceInformation.featureContent.payServicesDetails")}
                  />
                </KeyValueList.Section>
                <KeyValueList.Section
                  key="configurationOptions"
                  sectionHeader={t("onlineServiceInformation.featureContent.configurationOptions")}
                >
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.authenticationLevel")}
                    value={
                      <Trans i18nKey="onlineServiceInformation.featureContent.authenticationLevelDetails">
                        {[
                          "onlineServiceInformation.featureContent.substantialTooltip",
                          "onlineServiceInformation.featureContent.highTooltip"
                        ].map((tip) => (
                          <FjdTooltip
                            key="tooltip"
                            placement="top"
                            tooltip={
                              <div>
                                <Trans i18nKey={tip} components={{ bold: <strong /> }} />
                              </div>
                            }
                          >
                            <FjdIcon glyph="help-outline" size="s" appearance="primary" />
                          </FjdTooltip>
                        ))}
                      </Trans>
                    }
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.writtenFormRequirements")}
                    value={t("onlineServiceInformation.featureContent.writtenFormRequirementsDetails")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.titleAntrag")}
                    value={t("onlineServiceInformation.featureContent.titleAntragDetails")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.serviceDescription")}
                    value={t("onlineServiceInformation.featureContent.serviceDescriptionDetail")}
                  />
                </KeyValueList.Section>
                <KeyValueList.Section
                  key="qualityStandards"
                  sectionHeader={t("onlineServiceInformation.featureContent.qualityStandards")}
                >
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.intuitiveOperation")}
                    value={t("onlineServiceInformation.featureContent.intuitiveOperationDetails")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.understandableLanguage")}
                    value={t("onlineServiceInformation.featureContent.understandableLanguageDetails")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.accessibility")}
                    value={t("onlineServiceInformation.featureContent.accessibilityDetails")}
                  />
                  <KeyValueList.ListItem
                    term={t("onlineServiceInformation.featureContent.dsgvoCompliance")}
                    value={t("onlineServiceInformation.featureContent.dsgvoComplianceDetails")}
                  />
                </KeyValueList.Section>
              </KeyValueList>
            </Stack>
          </TabBar.Panel>
        </TabBar>
      </Stack>
    </Modal>
  );
}
