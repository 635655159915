import { FjdTableProps } from "fjd-react-components/build/components/Table/Table";
import { FjdMenu, FjdTable } from "fjd-react-components";
import { useState } from "react";
import { FjdIconGlyph } from "fjd-react-components/build/components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { FlyoutButton } from "../FlyoutButton/FlyoutButton";
import { MenuItem } from "../MenuItem/MenuItem";

export interface TableProps<T extends object> extends FjdTableProps<T> {
  className?: string;
}

function Table<T extends object>({
  clickableRows,
  columns,
  data,
  disableSortBy,
  expandableRows,
  isRowDisabled,
  labels,
  multiSelect,
  onMultiSelectionChange,
  onPageSizeOptionClicked,
  onRowClick,
  onSingleSelectionChange,
  onSortByChange,
  orderByFn,
  pageSizeOptions,
  renderExpansion,
  singleSelect,
  sortBy,
  enableGlobalFilter,
  enableColumnFilter,
  globalFilterPlaceholder,
  clearButtonLabel,
  customGlobalFilter,
  setCustomGlobalFilter,
  clearButton,
  onClear,
  className
}: TableProps<T>) {
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();

  return (
    <div className={className}>
      <FjdTable
        clickableRows={clickableRows ?? true}
        columns={columns}
        data={data}
        disableSortBy={disableSortBy ?? false}
        expandableRows={expandableRows ?? false}
        isRowDisabled={isRowDisabled}
        labels={
          labels ?? {
            currentPage: t("commonComponents.table.currentPage"),
            detailButton: t("commonComponents.table.detailButton"),
            emptyData: t("commonComponents.table.emptyData"),
            nextPage: t("commonComponents.table.nextPage"),
            of: t("commonComponents.table.of"),
            page: t("commonComponents.table.page"),
            pageSize: t("commonComponents.table.pageSize"),
            previousPage: t("commonComponents.table.previousPage"),
            rows: t("commonComponents.table.rows")
          }
        }
        multiSelect={multiSelect}
        onMultiSelectionChange={onMultiSelectionChange}
        onPageSizeOptionClicked={onPageSizeOptionClicked ?? ((size: number) => setPageSize(size))}
        onRowClick={onRowClick}
        onSingleSelectionChange={onSingleSelectionChange}
        onSortByChange={onSortByChange}
        orderByFn={orderByFn}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions ?? [10, 20, 50]}
        renderExpansion={renderExpansion}
        singleSelect={singleSelect}
        sortBy={sortBy}
        enableGlobalFilter={enableGlobalFilter ?? true}
        enableColumnFilter={enableColumnFilter ?? true}
        globalFilterPlaceholder={globalFilterPlaceholder}
        clearButtonLabel={clearButtonLabel}
        customGlobalFilter={customGlobalFilter}
        setCustomGlobalFilter={setCustomGlobalFilter}
        clearButton={clearButton}
        onClear={onClear}
      />
    </div>
  );
}

export interface MenuItemActionProps {
  label: string;
  onClick: () => unknown;
  icon?: FjdIconGlyph;
  identifier?: string;
  testId?: string;
  hidden?: boolean;
}

function MenuButton({ menuItems, identifier }: { menuItems: MenuItemActionProps[]; identifier: string }) {
  const areAllMenuItemsVisible = menuItems.filter((value) => value.hidden === true).length === menuItems.length;
  return !areAllMenuItemsVisible ? (
    <FlyoutButton
      label={`row-option-button-${identifier}`}
      testid={`row-option-button-${identifier}`}
      iconLeft="overflow-menu-vertical"
      appearance="primary-link"
      className="mkt-table_row-menu-button"
      hideLabel
      flyoutLabel=""
      flyout={
        <FjdMenu data-testid={`row-option-menu-${identifier}`}>
          {menuItems.map((item) => (
            <MenuItem
              key={item.label}
              icon={item.icon ?? "trashcan"}
              label={item.label}
              onClick={item.onClick}
              testid={item?.testId ?? item.label}
              hidden={item?.hidden}
            />
          ))}
        </FjdMenu>
      }
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
}

export interface RowProps<T> {
  row: {
    original: T;
  };
}

Table.MenuButton = MenuButton;

export const MktTable = Table;
