import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FjdButton, FjdMarkdown, FjdSpacer } from "fjd-react-components";
import "./NachnutzungsIntro.css";
import { Stack } from "../../../../common/components/Stack/Stack";
import { Heading } from "../../../../common/components/Heading/Heading";
import { NachnutzungsModal } from "./NachnutzungsModal";
import { CircledCharacter } from "../../../../common/components/CircledCharacter/CircledCharacter";

export function NachnutzungsIntro() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => setIsOpen(!isOpen);

  return (
    <section>
      <Heading text={t("nachnutzungsIntro.sectionHeader")} />
      <FjdSpacer size="s" />
      <Stack className="step-container">
        {Array(3)
          .fill(null)
          .map((_item, index) => (
            <Stack
              className="step-content"
              orientation="vertical"
              spacing="m"
              key={t(`nachnutzungsIntro.header${index + 1}`)}
            >
              <CircledCharacter character={`${index + 1}`} size={1.3} />
              <Heading className="heading" text={t(`nachnutzungsIntro.header${index + 1}`)} level={3} />
              <FjdMarkdown source={t(`nachnutzungsIntro.stepDescription${index + 1}`)} />
              {index === 1 && (
                <Stack>
                  <FjdButton
                    appearance="primary-link"
                    cursor="pointer"
                    label={t("nachnutzungsIntro.modelsInComparison")}
                    onClick={handleModal}
                  />
                  <NachnutzungsModal isOpen={isOpen} handleModal={handleModal} />
                </Stack>
              )}
            </Stack>
          ))}
      </Stack>
    </section>
  );
}
