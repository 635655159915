import React from "react";
import "./common/libs/i18n";
import { QueryProvider } from "./common/libs/QueryProvider";
import { Router } from "./app/Router";
import { UserProvider } from "./common/libs/authentication/context/UserContext";
import { ErrorBoundary } from "./app/pages/commonPages/ErrorBoundary";

export function App() {
  return (
    <ErrorBoundary>
      <QueryProvider>
        <UserProvider>
          <Router />
        </UserProvider>
      </QueryProvider>
    </ErrorBoundary>
  );
}
