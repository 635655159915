import { FjdMenuItem } from "fjd-react-components";
import React, { MouseEventHandler } from "react";
import { FjdIconGlyph } from "fjd-react-components/build/components/Icon/Icon";

export interface MenuItemProps<T = Element> {
  hidden?: boolean;
  href?: string;
  icon?: FjdIconGlyph;
  label: string;
  onClick?: MouseEventHandler<T>;
  testid?: string;
}

export function MenuItem({ hidden, href, icon, label, onClick, testid }: MenuItemProps) {
  return hidden ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  ) : (
    <FjdMenuItem
      appearance="light"
      icon={icon}
      size="m"
      role="menuitem"
      href={href}
      label={label}
      onClick={onClick}
      data-testid={testid}
    />
  );
}
