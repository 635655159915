enum RegionalKey {
  STATE_END_INDEX = 2,
  GOVERNORATE_END_INDEX = 3,
  DISTRICT_END_INDEX = 5,
  COMMUNITY_ASSOCIATION_END_INDEX = 9,
  COMMUNITY_END_INDEX = 12
}

export const formatRegionalKey = (regionalKey: string) =>
  `${regionalKey.substring(0, RegionalKey.STATE_END_INDEX)}-${regionalKey.substring(
    RegionalKey.STATE_END_INDEX,
    RegionalKey.GOVERNORATE_END_INDEX
  )}-${regionalKey.substring(
    RegionalKey.GOVERNORATE_END_INDEX,
    RegionalKey.DISTRICT_END_INDEX
  )}-${regionalKey.substring(
    RegionalKey.DISTRICT_END_INDEX,
    RegionalKey.COMMUNITY_ASSOCIATION_END_INDEX
  )}-${regionalKey.substring(RegionalKey.COMMUNITY_ASSOCIATION_END_INDEX, RegionalKey.COMMUNITY_END_INDEX)}`;

export const formatRegionalKeyAndPopulation = (regionalKey: string, population?: number, zipCode?: string) => {
  const formattedRegion = `ARS: ${formatRegionalKey(regionalKey)}`;
  const formattedPopulation = population ? `, EW: ${population}` : "";
  const formattedZipCode = zipCode ? `, PLZ: ${zipCode}` : "";
  return `(${formattedRegion}${formattedPopulation}${formattedZipCode})`;
};
