export const BEHOERDE = "behoerde";
export const BETREIBER = "betreiber";
export const HERSTELLER = "hersteller";

export type OrganizationRole = typeof BEHOERDE | typeof BETREIBER | typeof HERSTELLER;

export interface Organization {
  roles: OrganizationRole[];
  name: string;
}

export interface Organizations {
  [key: string]: Organization;
}

export interface SelectedOrganization {
  uuid: string;
  name: string;
  role: OrganizationRole;
}
