const ozgKennung = /^[+ 0-9]{5}$/;
export const isValidOzgKennung = (ozgKennungToValidate: string): boolean => ozgKennung.test(ozgKennungToValidate);

const leikaKennung = /^[+ 0-9]{14}$/;
export const isValidLeikaKennung = (leikaKennungToValidate: string): boolean =>
  leikaKennung.test(leikaKennungToValidate);

export type FileType = "image/png" | "image/jpeg" | "text/xml" | "text/csv" | "application/zip" | "application/pdf";

export function checkFileType(files: FileList, validTypes: FileType[]): boolean {
  return Array.from(files).every((file) => validTypes.includes(file.type as FileType));
}
