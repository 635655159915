import React from "react";

const DEFAULT_TAB_SIZE = 1;

interface TabProps {
  rems?: number;
}

export function Tab({ rems }: TabProps) {
  return <span data-testid="tab" style={{ paddingLeft: `${rems || DEFAULT_TAB_SIZE}rem` }} />;
}
