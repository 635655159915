import React, { useState } from "react";
import { FjdRadio } from "fjd-react-components";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Stack } from "../../../../../../common/components/Stack/Stack";
import { Modal } from "../../../../../../common/components/Modal/Modal";
import { Heading } from "../../../../../../common/components/Heading/Heading";
import {
  fetchOnlineServiceTemplateById,
  downloadOnlineServiceTemplateReleaseNoteAttachmentsById,
  releaseTemplateById
} from "../../../../../api/onlinedienste/onlineServiceTemplateApi";
import { Button } from "../../../../../../common/components/Button/Button";
import { EditModal } from "./EditVersionsDialog";
import { FileDescription } from "../../../../../../common/components/FileDescription/FileDescription";
import { useNotification } from "../../../../../../common/libs/notifications/hooks/useNotification";
import { OnlineServiceTemplateVersion } from "../../../../../api/onlinedienste/OnlineServicesV1";

interface ReleaseArbeitsversionDialogProps {
  onlineServiceId: string;
  identifier: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirmed: () => void;
  workingVersion: string;
  releasedVersion?: string;
}
export function ReleaseWorkingVersionDialog({
  isOpen,
  identifier,
  onlineServiceId,
  onClose,
  onConfirmed,
  workingVersion,
  releasedVersion
}: ReleaseArbeitsversionDialogProps) {
  const { t } = useTranslation();
  const { notifyUserFromResponse, notifyUser } = useNotification();
  const [selectedWorkingVersion, setSelectedWorkingVersion] = useState<OnlineServiceTemplateVersion | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [shouldBeInformedByEmail, setShouldBeInformedByEmail] = useState(false);

  const { refetch: refetchTemplate } = useQuery({
    queryKey: [fetchOnlineServiceTemplateById, onlineServiceId],
    queryFn: () => fetchOnlineServiceTemplateById(onlineServiceId),
    onSettled: (data) => {
      const foundWorkingVersion = data?.versions?.find((version) => version.status === "WORKED_ON");
      if (foundWorkingVersion) {
        setSelectedWorkingVersion(foundWorkingVersion);
      } else {
        notifyUser({
          intent: "error",
          title: t("onlineServiceTemplates.releaseWorkingVersionDialog.workingVersionNotFound")
        });
        onClose();
      }
    }
  });

  const handleClickOnFileDownload = (fileId: string) => () => {
    if (selectedWorkingVersion) {
      const { templateVersionId } = selectedWorkingVersion;
      downloadOnlineServiceTemplateReleaseNoteAttachmentsById({ fileId, onlineServiceId, templateVersionId }).catch(
        () => {}
      );
    }
  };

  const { mutate: release } = useMutation({
    mutationKey: [releaseTemplateById.name],
    mutationFn: releaseTemplateById,
    onSettled: () => {
      notifyUserFromResponse({ successMessage: t("onlineServiceTemplates.actions.release.successMessage") });
    },
    onSuccess: () => onConfirmed()
  });

  return (
    <>
      <Modal
        data-testid="release-working-version-dialog"
        closable
        onClose={onClose}
        open={isOpen}
        closeOnBackdropClick
        heading={t("onlineServiceTemplates.actions.release.confirmationHeading", { workingVersion })}
        primaryButtonLabel={t("onlineServiceTemplates.actions.release.confirmationButtonLabel")}
        onPrimaryButtonClick={() => {
          release({
            onlineServiceId,
            requestBody: { notifyAuthoritiesAboutRelease: shouldBeInformedByEmail }
          });
        }}
        secondaryButtonLabel={t("onlineServiceTemplates.actions.release.abort")}
        onSecondaryButtonClick={onClose}
      >
        <Stack spacing="5xl">
          <p>
            {t("onlineServiceTemplates.actions.release.confirmationHint", {
              identifier,
              releasedVersion,
              workingVersion
            })}
          </p>
          <Stack>
            <Stack orientation="horizontal" spacing="xs">
              <Heading
                level={2}
                visualLevel={3}
                text={t("onlineServiceTemplates.actions.release.shouldAuthorityInformedByEmail")}
              />
            </Stack>
            <FjdRadio
              description=""
              id="input"
              label={t("onlineServiceTemplates.actions.release.yesInformAuthority")}
              name="group"
              onChange={() => setShouldBeInformedByEmail(true)}
              value="Value"
              checked={shouldBeInformedByEmail}
            />
            <FjdRadio
              description=""
              id="input"
              label={t("onlineServiceTemplates.actions.release.dontInformAuthority")}
              name="group"
              onChange={() => setShouldBeInformedByEmail(false)}
              value="Value"
              checked={!shouldBeInformedByEmail}
            />
            <Stack orientation="horizontal" spacing="xs">
              <Heading level={2} visualLevel={3} text={t("onlineServiceTemplates.actions.release.releaseNote")} />
              {selectedWorkingVersion && (
                <Button
                  label="edit"
                  iconLeft="edit"
                  hideLabel
                  appearance="primary-link"
                  size="m"
                  testId={`release-working-version-${selectedWorkingVersion.templateVersionId}-releaseNote`}
                  onClick={() => {
                    setIsEditModalVisible(true);
                  }}
                />
              )}
            </Stack>
            <p>{selectedWorkingVersion?.releaseNote}</p>
            <Stack orientation="horizontal" spacing="xs">
              <Heading level={2} visualLevel={3} text="Anhang für Release Notes" />
              {selectedWorkingVersion && (
                <Button
                  label="edit"
                  iconLeft="edit"
                  hideLabel
                  appearance="primary-link"
                  size="m"
                  testId={`release-working-version-${selectedWorkingVersion.templateVersionId}-releaseNoteAttachment`}
                  onClick={() => {
                    setIsEditModalVisible(true);
                  }}
                />
              )}
            </Stack>
            {selectedWorkingVersion &&
              selectedWorkingVersion.releaseNoteAttachments?.map((file) => (
                <FileDescription
                  key={file.fileId}
                  data-testId={file.fileId}
                  filename={file.fileName}
                  onClick={handleClickOnFileDownload(file.fileId ?? "")}
                />
              ))}
          </Stack>
        </Stack>
      </Modal>
      {selectedWorkingVersion && isEditModalVisible && (
        <EditModal
          isOpen
          identifier={identifier}
          onlineServiceId={onlineServiceId}
          onClose={() => {
            setIsEditModalVisible(false);
            refetchTemplate()
              .then(() => {})
              .catch(() => {});
          }}
          version={selectedWorkingVersion}
        />
      )}
    </>
  );
}
