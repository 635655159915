import React from "react";
import { FjdDescriptionListItem } from "fjd-react-components";
import { Stack } from "../Stack/Stack";
import { Heading } from "../Heading/Heading";

interface SectionProps {
  children: JSX.Element | JSX.Element[];
  sectionHeader?: string;
}

interface KeyValueListComponentProps {
  children: React.ReactElement<SectionProps> | React.ReactElement<SectionProps>[];
  heading?: string;
}

function KeyValueListComponent({ children, heading }: KeyValueListComponentProps) {
  return (
    <div className="key-value-list">
      {heading && <Heading text={heading} level={1} />}
      {children.constructor === Array ? (
        <Stack spacing="5xl">
          {children.map((child) => (
            <React.Fragment key={child.props.sectionHeader}>{child}</React.Fragment>
          ))}
        </Stack>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
}

function Section({ children, sectionHeader }: SectionProps) {
  return (
    <div className="key-value-list_section">
      <Stack>
        <Heading key={sectionHeader} text={sectionHeader ?? ""} level={2} />
        <Stack spacing="m">{children}</Stack>
      </Stack>
    </div>
  );
}

interface ListItemProps {
  term: React.ReactNode | React.ReactNode[];
  value: React.ReactNode | React.ReactNode[];
  hideIfNoValue?: boolean;
}

function ListItem({ term, value, hideIfNoValue = false }: ListItemProps) {
  if (hideIfNoValue && (value === null || value === undefined)) return null;
  return <FjdDescriptionListItem useMarkdown={false} description={value} term={term} />;
}

KeyValueListComponent.ListItem = ListItem;
KeyValueListComponent.Section = Section;

export const KeyValueList = KeyValueListComponent;
