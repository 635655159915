import { useContext } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "../context/NotificationContext";
import { OnlineDiensteApiErrorResponse } from "../../../../app/api/ApiErrorResponse";

export type HTTP_STATUS = 200 | 201 | 204 | 400 | 401 | 403 | 404 | 413 | 500 | 501 | 503 | 504;
const SUCCESS_CODE = 2;
const CLIENT_ERROR_CODE = 4;
const SERVER_ERROR_CODE = 5;
const MAX_FILE_SIZE_UPLOAD_EXCEEDED = 413;

function evaluateHTTPCode(status: HTTP_STATUS) {
  return {
    isSuccess: Math.trunc(status / 100) === SUCCESS_CODE,
    isClientError: Math.trunc(status / 100) === CLIENT_ERROR_CODE,
    isServerError: Math.trunc(status / 100) === SERVER_ERROR_CODE,
    isMaxFileSizeUploadExceeded: status === MAX_FILE_SIZE_UPLOAD_EXCEEDED
  };
}

/**
 * Mapping Layer between API and UI
 * */
export function useNotification() {
  const { notifyUser, confirmByUser } = useContext(NotificationContext);
  const { t } = useTranslation();

  /**
   *  This functions maps from React-Query + Axios to the notification system. BE responses will be mapped from response -> (statusCode, message).
   * @param message Use this param to override the default AND response message from server side
   */
  const notifyUserFromResponse =
    (message?: { successMessage?: string; errorMessage?: string }) =>
    <TData, TError>(data: TData, error: TError) => {
      if (error && error instanceof AxiosError) {
        if (error.response) {
          const serverResponse = error.response as AxiosResponse<OnlineDiensteApiErrorResponse>;
          if (serverResponse.status) {
            const { isServerError, isMaxFileSizeUploadExceeded: isMaxFileUploadExceeded } = evaluateHTTPCode(
              error.status as HTTP_STATUS
            );
            if (isMaxFileUploadExceeded) {
              notifyUser({ intent: "error", title: t("globalNotifications.maxFileSizeUploadExceeded") });
            }
            if (isServerError) notifyUser({ intent: "error", title: t("globalNotifications.alertServerError") });
            else notifyUser({ intent: "error", title: message?.errorMessage ?? serverResponse?.data?.error });
          }
        } else notifyUser({ intent: "error", title: t("api.error.serviceNotAvailable") });
      } else if (message && message.successMessage) {
        notifyUser({ intent: "success", title: message.successMessage });
      }
      return { data, error } as { data: TData; error: TError };
    };

  return { confirmByUser, notifyUserFromResponse, notifyUser };
}
