import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import { Layout } from "./layout/Layout";
import { ErrorPage } from "./pages/commonPages/ErrorPage";
import { NotFoundPage } from "./pages/commonPages/NotFoundPage";
import { LandingPage } from "./pages/landingpage/LandingPage";
import { ProtectedLayout } from "./layout/ProtectedLayout";
import { OnlineDiensteDashboardPage } from "./pages/producerPages/onlinedienste/OnlineDiensteDashboardPage";
import { ProtectedRoleLayout } from "./layout/ProtectedRoleLayout";
import {
  APPS_CONFIGURATION_OF_PRODUCER_PATH,
  BEHOERDEN_INDEX_PATH,
  CATALOG_OF_PRODUCER_PATH,
  ONLINE_SERVICES_OF_PRODUCER_PATH,
  ONLINE_SERVICES_PATH,
  REDIRECT_PATH,
  RIGHTS_OF_USE_OF_OPERATOR_PATH,
  RIGHTS_OF_USE_OF_PRODUCER_PATH
} from "./Routes";
import { CatalogsPage } from "./pages/producerPages/catalogs/CatalogsPage";
import { RightsOfUsePage as OperatorRightsOfUsePage } from "./pages/operatorPages/platforms/RightsOfUsePage";
import { RightsOfUsePage as ProducerRightsOfUsePage } from "./pages/producerPages/rightsOfUse/RightsOfUsePage";
import { BEHOERDE, BETREIBER, HERSTELLER } from "../common/libs/authentication/types/Organization";
import { LandingPageV2 } from "./pages/landingpage/LandingPageV2";
import { LoginRedirectPage } from "./pages/LoginRedirectPage";
import { MarketplaceAnbindung } from "./pages/producerPages/appAccessConfiguration/MarketplaceAnbindung";

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />} errorElement={<ErrorPage />}>
          <Route path="*" element={<NotFoundPage />} />
          {/*  PUBLIC ROUTES */}
          <Route index element={<LandingPage />} />
          <Route path={ONLINE_SERVICES_PATH} element={<LandingPageV2 />} />
          {/* PROTECTED ROUTES */}
          <Route element={<ProtectedLayout />}>
            <Route path={REDIRECT_PATH} element={<LoginRedirectPage />} />
            <Route path={BEHOERDEN_INDEX_PATH()} element={<ProtectedRoleLayout allowedRole={BEHOERDE} />} />
            <Route element={<ProtectedRoleLayout allowedRole={BETREIBER} />}>
              <Route path={RIGHTS_OF_USE_OF_OPERATOR_PATH()} element={<OperatorRightsOfUsePage />} />
            </Route>
            <Route element={<ProtectedRoleLayout allowedRole={HERSTELLER} />}>
              <Route path={ONLINE_SERVICES_OF_PRODUCER_PATH()} element={<OnlineDiensteDashboardPage />} />
              <Route path={RIGHTS_OF_USE_OF_PRODUCER_PATH()} element={<ProducerRightsOfUsePage />} />
              <Route path={CATALOG_OF_PRODUCER_PATH()} element={<CatalogsPage />} />
              <Route path={APPS_CONFIGURATION_OF_PRODUCER_PATH()} element={<MarketplaceAnbindung />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
