import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { fetchCountOfAvailableOnlineServices, fetchOnlineServiceTemplatesFlat } from "../onlineServiceTemplateApi";
import { AvailableOnlineServicesCount } from "../OnlineServiceV2";

export const useFetchOnlineDiensteTemplates = () =>
  useQuery({
    queryKey: [fetchOnlineServiceTemplatesFlat.name, "flat"],
    queryFn: fetchOnlineServiceTemplatesFlat
  });

export const useCountOfAvailableOnlineServices = () =>
  useQuery<AvailableOnlineServicesCount, AxiosError, number>({
    retryDelay: 5000,
    queryKey: [fetchCountOfAvailableOnlineServices.name],
    queryFn: fetchCountOfAvailableOnlineServices,
    select: (data) => data.count
  });
