import { MARKETPLACE_URL_BASE, mktClient } from "../../../common/libs/axiosClient";
import { OnlineServicesV1 } from "./OnlineServicesV1";
import { FilterValues, OnlineServiceV2 } from "./OnlineServiceV2";

export const ONLINE_SERVICES_URL = `${MARKETPLACE_URL_BASE}/online-services`;
export const ONLINE_SERVICES_ALL_URL = `${ONLINE_SERVICES_URL}/all`;
export const ONLINE_SERVICES_V2_URL = `${ONLINE_SERVICES_URL}/v2/all`;
export const fetchOnlineServices = () =>
  mktClient
    .get<OnlineServicesV1[]>(ONLINE_SERVICES_ALL_URL.toString())
    .then<OnlineServicesV1[]>((response) => response.data);

export const fetchOnlineServicesV2 = (queryParams: FilterValues) => {
  const { platformIds, ...remainingQueryParams } = queryParams;
  return mktClient
    .get<OnlineServiceV2[]>(ONLINE_SERVICES_V2_URL.toString(), {
      params: { ...remainingQueryParams, platformIds: platformIds?.join(",") }
    })
    .then<OnlineServiceV2[]>((response) => response.data);
};
