import {
  FjdBackdrop,
  FjdButton,
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdFileInput,
  FjdFilePreview,
  FjdHeading,
  FjdIcon,
  FjdSectionHeader,
  FjdSpinner,
  FjdStack,
  FjdTooltip
} from "fjd-react-components";
import React, { ChangeEvent, MouseEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { convertTimestampToLocaleDateTime } from "../../../../common/utils/TimeUtils";
import { FileDescription } from "../../../../common/components/FileDescription/FileDescription";
import { Stack } from "../../../../common/components/Stack/Stack";

import { FlexSpacer } from "../../../../common/components/FlexSpacer/FlexSpacer";
import { Heading } from "../../../../common/components/Heading/Heading";
import { Modal } from "../../../../common/components/Modal/Modal";
import { useNotification } from "../../../../common/libs/notifications/hooks/useNotification";
import { URL_OZG_PLATFORM, URL_STATISTISCHES_BUNDESAMT } from "../../../../common/url";
import "./CatalogsPage.css";
import { downloadCatalog, fetchOnlineServicesMetadata, uploadCatalogFile } from "../../../api/catalog/catalogApi";
import {
  MUNICIPAL_DIRECTORY_CATALOG_API_PATH,
  Catalog,
  CatalogApiPathType,
  LEIKA_CATALOG,
  LEIKA_CATALOG_API_PATH,
  MUNICIPAL_DIRECTORY_CATALOG,
  OZG_CATALOG,
  OZG_CATALOG_API_PATH,
  CatalogFileMetadata
} from "../../../api/catalog/Catalog";

export function CatalogsPage() {
  const { t } = useTranslation();
  const [uploadCandidate, setUploadCandidate] = useState<Record<string, File> | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { notifyUserFromResponse } = useNotification();
  const { data, refetch: refetchOnlineServicesMetaData } = useQuery<Record<Catalog, CatalogFileMetadata>, AxiosError>({
    queryKey: [fetchOnlineServicesMetadata],
    queryFn: fetchOnlineServicesMetadata
  });

  const {
    [MUNICIPAL_DIRECTORY_CATALOG]: municipalDirectoryFile,
    [LEIKA_CATALOG]: leikaFile,
    [OZG_CATALOG]: ozgFile
  } = useMemo(
    () => data ?? { [MUNICIPAL_DIRECTORY_CATALOG]: undefined, [LEIKA_CATALOG]: undefined, [OZG_CATALOG]: undefined },
    [data]
  );
  const addUploadCandidate = (type: Catalog) => (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (file) setUploadCandidate({ ...uploadCandidate, [type]: file });
  };

  const deleteUploadCandidate = (type: Catalog) => (event: MouseEvent) => {
    if (uploadCandidate && event) {
      const tempUploadCandidate: Record<string, File> = { ...uploadCandidate };
      delete tempUploadCandidate[type];
      setUploadCandidate(tempUploadCandidate);
    }
  };
  const handleModalClose = () => {
    setUploadCandidate(null);
    setIsModalVisible(false);
  };

  const handleClickOnFileDownload = (file: CatalogApiPathType) => () => {
    downloadCatalog(file).catch(() => {});
  };

  const { isLoading: isFileUploading, mutate: uploadFile } = useMutation({
    mutationKey: [uploadCatalogFile],
    mutationFn: uploadCatalogFile,
    onSuccess: () => {
      refetchOnlineServicesMetaData()
        .then(handleModalClose)
        .catch(() => {});
    },
    onSettled: notifyUserFromResponse()
  });

  const uploadCandidates = () => {
    if (uploadCandidate) {
      uploadFile(uploadCandidate);
    }
  };

  return (
    <>
      <Stack spacing="5xl">
        <Stack orientation="horizontal">
          <FjdSectionHeader heading={<Heading level={1} text={t("catalogs.catalogs")} />} />
          <FlexSpacer />
          <span>
            <FjdButton
              onClick={() => setIsModalVisible(true)}
              iconLeft="renew"
              label={t("catalogs.catalogImportButton")}
              size="m"
            />
          </span>
        </Stack>
        <Stack orientation="vertical" spacing="m">
          <FjdHeading text={t("catalogs.ozgLeikaHeadline")} level={2} />
          <FjdDescriptionList>
            <FjdDescriptionListItem
              description={
                <FileDescription
                  filename={leikaFile?.name ?? "-"}
                  description={t("catalogs.fileDescription", {
                    uploadedAt: convertTimestampToLocaleDateTime(leikaFile?.createdAt ?? "-"),
                    uploadedBy: leikaFile?.uploadedBy ?? "-"
                  })}
                  onClick={handleClickOnFileDownload(LEIKA_CATALOG_API_PATH)}
                />
              }
              term={t("catalogs.leikaUpload")}
            />
            <FjdDescriptionListItem
              description={
                <FileDescription
                  filename={ozgFile?.name ?? "-"}
                  description={t("catalogs.fileDescription", {
                    uploadedAt: convertTimestampToLocaleDateTime(ozgFile?.createdAt ?? "-"),
                    uploadedBy: ozgFile?.uploadedBy ?? "-"
                  })}
                  onClick={handleClickOnFileDownload(OZG_CATALOG_API_PATH)}
                />
              }
              term={t("catalogs.ozgUpload")}
            />
            <FjdDescriptionListItem
              description={
                <>
                  <div className="officialSourceLink">
                    <FjdButton
                      size="s"
                      iconLeft="launch"
                      label={t("catalogs.ozgSource")}
                      appearance="primary-link"
                      onClick={() => window.open(URL_OZG_PLATFORM, "_blank", "noreferrer,noopener")}
                    />
                  </div>
                  <FjdTooltip key="tooltip" placement="top" tooltip={<div>{t("catalogs.ozgSourceTooltip")}</div>}>
                    <FjdIcon glyph="information-outline" size="s" appearance="primary" />
                  </FjdTooltip>
                </>
              }
              term={t("catalogs.officialSource")}
            />
          </FjdDescriptionList>
        </Stack>
        <Stack orientation="vertical" spacing="m">
          <FjdHeading text={t("catalogs.communityDirectoryHeadline")} level={2} />
          <FjdDescriptionList>
            <FjdDescriptionListItem
              description={
                <FileDescription
                  filename={municipalDirectoryFile?.name ?? "-"}
                  description={t("catalogs.fileDescription", {
                    uploadedAt: convertTimestampToLocaleDateTime(municipalDirectoryFile?.createdAt ?? "-"),
                    uploadedBy: municipalDirectoryFile?.uploadedBy ?? "-"
                  })}
                  onClick={handleClickOnFileDownload(MUNICIPAL_DIRECTORY_CATALOG_API_PATH)}
                />
              }
              term={t("catalogs.municipalDirectoryUpload")}
            />
            <FjdDescriptionListItem
              description={
                <div className="officialSourceLink">
                  <FjdButton
                    size="s"
                    iconLeft="launch"
                    label={t("catalogs.municipalDirectorySource")}
                    appearance="primary-link"
                    onClick={() => window.open(URL_STATISTISCHES_BUNDESAMT, "_blank", "noreferrer,noopener")}
                  />
                </div>
              }
              term={t("catalogs.officialSource")}
            />
          </FjdDescriptionList>
        </Stack>
      </Stack>
      <Modal
        closable
        onClose={handleModalClose}
        open={isModalVisible}
        closeOnBackdropClick
        heading={t("catalogs.modalHeading")}
        primaryButtonLabel={t("catalogs.modalSave")}
        onPrimaryButtonClick={uploadCandidates}
        secondaryButtonLabel={t("catalogs.modalAboard")}
        onSecondaryButtonClick={handleModalClose}
      >
        <FjdStack spacing="l">
          <FjdHeading level={3} text={t("catalogs.modalLeikaUpload")} />
          <FjdStack orientation="horizontal" spacing="s">
            {uploadCandidate?.[LEIKA_CATALOG] === undefined ? (
              <FjdFileInput
                id="leikaCatalogFile"
                accept=".xlsx"
                label={t("catalogs.fileUpload")}
                appearance="outline"
                disabled={isFileUploading}
                onChange={addUploadCandidate(LEIKA_CATALOG)}
              />
            ) : (
              <FjdFilePreview
                onDelete={deleteUploadCandidate(LEIKA_CATALOG)}
                title={uploadCandidate?.[LEIKA_CATALOG]?.name ?? ""}
              />
            )}
          </FjdStack>
          <FjdHeading level={3} text={t("catalogs.modalOzgUpload")} />
          <FjdStack orientation="horizontal" spacing="s">
            {uploadCandidate?.[OZG_CATALOG] === undefined ? (
              <FjdFileInput
                id="ozgCatalogFile"
                accept=".xlsx"
                label={t("catalogs.fileUpload")}
                appearance="outline"
                disabled={isFileUploading}
                onChange={addUploadCandidate(OZG_CATALOG)}
              />
            ) : (
              <FjdFilePreview
                onDelete={deleteUploadCandidate(OZG_CATALOG)}
                title={uploadCandidate?.[OZG_CATALOG]?.name}
              />
            )}
          </FjdStack>
          <FjdHeading level={3} text={t("catalogs.modalMunicipalDirectoryUpload")} />
          <FjdStack orientation="horizontal" spacing="s">
            {uploadCandidate?.[MUNICIPAL_DIRECTORY_CATALOG] === undefined ? (
              <FjdFileInput
                id="municipal-directory-file"
                accept=".txt"
                label={t("catalogs.fileUpload")}
                appearance="outline"
                disabled={isFileUploading}
                onChange={addUploadCandidate(MUNICIPAL_DIRECTORY_CATALOG)}
              />
            ) : (
              <FjdFilePreview
                onDelete={deleteUploadCandidate(MUNICIPAL_DIRECTORY_CATALOG)}
                title={uploadCandidate[MUNICIPAL_DIRECTORY_CATALOG]?.name}
              />
            )}
          </FjdStack>
        </FjdStack>
      </Modal>
      {isFileUploading && (
        <FjdBackdrop>
          <FjdSpinner size="m" />
        </FjdBackdrop>
      )}
    </>
  );
}
