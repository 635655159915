import { Organizations } from "../libs/authentication/types/Organization";

export interface KeycloakAccessToken {
  email_verified: boolean;
  name: string;
  organizations: Organizations;
  preferred_username: string;
  given_name: string;
  family_name: string;
}

export function parseJWT(token: string): KeycloakAccessToken {
  const base64Url = token.split(".")[1];
  const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");

  const jsonPayload = decodeURIComponent(
    window
      .atob(base64 ?? "")
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );
  return JSON.parse(jsonPayload) as KeycloakAccessToken;
}
