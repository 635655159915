import React from "react";
import { useMediaQuery } from "react-responsive";
import { FjdSpacer } from "fjd-react-components";
import classNames from "classnames";
import { Stack } from "../Stack/Stack";
import { FjdDeviceDisplay } from "../../utils/FjdDeviceDisplay";
import "./TextAlignment.css";

interface WordDividerProps {
  words: string[];
  appearance?: "light" | "regular";
}

export function WordDivider({ words, appearance = "regular" }: WordDividerProps) {
  const isMobile = useMediaQuery({ maxWidth: FjdDeviceDisplay.M });
  const classes = classNames({ "word-divider-appearance_light": appearance === "light" });
  const Divider = isMobile ? <div /> : <div>|</div>;

  return (
    <Stack spacing={isMobile ? "xs" : "s"} orientation="horizontal" className={classes}>
      {words.map((word, index) => (
        <React.Fragment key={word}>
          <div>{word}</div>
          {index < words.length - 1 ? Divider : undefined}
        </React.Fragment>
      ))}
    </Stack>
  );
}

interface InlineDividerProps {
  elements: React.ReactElement[];
  appearance?: "light" | "regular";
  divider?: string;
}

export function InlineDivider({ elements, appearance = "regular", divider }: InlineDividerProps) {
  const isMobile = useMediaQuery({ maxWidth: FjdDeviceDisplay.M });
  const classes = classNames({ "word-divider-appearance_light": appearance === "light" });
  const Divider = isMobile ? "" : <span className="inline-divider__divider">{divider}</span>;

  return (
    <Stack spacing={isMobile ? "xs" : "s"} orientation="horizontal" className={classes}>
      {elements.map((word, index) => (
        <React.Fragment key={word.key}>
          {word}
          {index < elements.length - 1 ? Divider : undefined}
        </React.Fragment>
      ))}
    </Stack>
  );
}

interface HeadlineWithContentProps {
  text: { headline: string; content: string }[];
}

export function HeadlineWithContent({ text }: HeadlineWithContentProps) {
  return (
    <div>
      {text.map(({ headline, content }, index) => (
        <React.Fragment key={headline}>
          <div>{headline}</div>
          <div className="headline-with-content_content">{content}</div>
          {index < text.length - 1 ? <FjdSpacer size="xs" /> : undefined}
        </React.Fragment>
      ))}
    </div>
  );
}
