import React from "react";
import { Outlet } from "react-router-dom";
import "./Layout.css";
import { FjdButton, FjdStack } from "fjd-react-components";
import { Trans, useTranslation } from "react-i18next";
import { Header } from "../../common/components/Header/Header";
import { Footer } from "../../common/components/Footer/Footer";
import { NotificationProvider } from "../../common/libs/notifications/context/NotificationContext";
import {
  URL_CONTACT,
  URL_DATA_POLICY,
  URL_GTC,
  URL_LEGAL_NOTICE,
  URL_OZG_PLATFORM,
  URL_STATISTISCHES_BUNDESAMT
} from "../../common/url";
import { useMarketplaceAuthentication } from "../../common/libs/authentication/hooks/useMarketplaceAuthentication";

interface LayoutProps {
  children?: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const { t } = useTranslation();
  const { logout, login, userName, isAuthenticated } = useMarketplaceAuthentication();

  return (
    <NotificationProvider
      defaultModalHeading={t("globalNotifications.modalHeading")}
      defaultModalConfirmationButtonLabel={t("globalNotifications.modalConfirm")}
      defaultModalAbortButtonLabel={t("globalNotifications.modalAboard")}
      defaultModalContent={t("globalNotifications.modalHint")}
      defaultAlertErrorMessage={t("globalNotifications.alertError")}
      defaultAlertSuccessMessage={t("globalNotifications.alertSuccess")}
    >
      <div className="layout">
        <main className="layout-inner">
          <div>
            <Header login={login} logout={logout} userName={userName} isAuthenticated={isAuthenticated} />
            <FjdStack spacing="5xl" className="layout-content">
              {children ?? <Outlet />}
            </FjdStack>
          </div>

          <Footer>
            <Footer.Hint>
              <Trans i18nKey="footer.hint">
                <a className="footer-hint-link" href={URL_OZG_PLATFORM} target="_blank" rel="noreferrer">
                  OZG-Platform
                </a>
                <a className="footer-hint-link" href={URL_STATISTISCHES_BUNDESAMT} target="_blank" rel="noreferrer">
                  Statistisches-Bundesamt
                </a>
              </Trans>
            </Footer.Hint>
            <Footer.Navigation>
              <FjdButton href={URL_CONTACT} appearance="link" label={t("footer.contact")} size="s" />
              <FjdButton href={URL_GTC} appearance="link" label={t("footer.gtc")} size="s" />
              <FjdButton href={URL_LEGAL_NOTICE} appearance="link" label={t("footer.legalNotice")} size="s" />
              <FjdButton href={URL_DATA_POLICY} appearance="link" label={t("footer.dataPolicy")} size="s" />
            </Footer.Navigation>
            <Footer.Copyright>
              {t("footer.copyright")} {new Date().getFullYear()}
            </Footer.Copyright>
          </Footer>
        </main>
      </div>
    </NotificationProvider>
  );
}
