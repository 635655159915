export const OZG_CATALOG = "ozg-file";
export const LEIKA_CATALOG = "leika-file";
export const MUNICIPAL_DIRECTORY_CATALOG = "municipal-directory-file";
export const ONLINE_SERVICES_CATALOG = "onlineDiensteFile";

export type Catalog =
  | typeof OZG_CATALOG
  | typeof LEIKA_CATALOG
  | typeof MUNICIPAL_DIRECTORY_CATALOG
  | typeof ONLINE_SERVICES_CATALOG;

export const OZG_CATALOG_API_PATH = "ozg-file";
export const LEIKA_CATALOG_API_PATH = "leika-file";
export const MUNICIPAL_DIRECTORY_CATALOG_API_PATH = "municipal-directory-file";
export const ONLINE_SERVICES_CATALOG_API_PATH = "online-services-file";

export type CatalogApiPathType =
  | typeof OZG_CATALOG_API_PATH
  | typeof LEIKA_CATALOG_API_PATH
  | typeof MUNICIPAL_DIRECTORY_CATALOG_API_PATH
  | typeof ONLINE_SERVICES_CATALOG_API_PATH;

export interface LeikaEntity {
  id: string;
  description: string;
}

export interface OzgEntity {
  id: string;
  topic: string;
  description: string;
}

export interface MunicipalDirectoryRecord {
  regionalKey: string;
  municipalName: string;
  population?: number;
  textIndicatorDescription?: string;
  zipCode?: string;
}

export interface LeikaAndEntityCollection {
  leikaEntities: LeikaEntity[];
  ozgEntity?: OzgEntity;
}

export interface CatalogFileMetadata {
  id: string;
  name: string;
  createdAt: string;
  uploadedBy: string;
  uploadedByUserId: string;
}
