import React from "react";

interface SearchMenuItemProps {
  "data-testid"?: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLDivElement>;
  children: React.ReactNode | React.ReactNode[];
}

function SearchMenuItem({ children, onClick, onKeyUp, "data-testid": testId }: SearchMenuItemProps) {
  return (
    <div role="button" tabIndex={0} onClick={onClick} onKeyUp={onKeyUp} className="search-result" data-testid={testId}>
      {children}
    </div>
  );
}

interface SearchMenuProps {
  children: React.ReactNode | React.ReactNode[];
}

function SearchMenuComponent({ children }: SearchMenuProps) {
  return (
    <div data-testid="searchMenu" className="search-menu">
      {children}
    </div>
  );
}

SearchMenuComponent.SearchMenuItem = SearchMenuItem;
export const SearchMenu = SearchMenuComponent;
