import { FjdButton } from "fjd-react-components";
import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import "./OnlineDiensteVorlagenPage.css";
import { Stack } from "../../../../../common/components/Stack/Stack";
import {
  deleteOnlineDienstAndVersionsById,
  revokeReleaseById
} from "../../../../api/onlinedienste/onlineServiceTemplateApi";
import { useNotification } from "../../../../../common/libs/notifications/hooks/useNotification";
import { NewOnlineServiceDialog } from "./Dialogs/NewOnlineServiceDialog";
import { NewWorkingVersionDialog } from "./Dialogs/NewWorkingVersionDialog";
import { EditVersionsDialog } from "./Dialogs/EditVersionsDialog";
import { useFetchOnlineDiensteTemplates } from "../../../../api/onlinedienste/hooks/useFetchOnlineDiensteTemplates";
import { FlexSpacer } from "../../../../../common/components/FlexSpacer/FlexSpacer";
import { OnlineServiceTemplateFlat } from "../../../../api/onlinedienste/OnlineServicesV1";
import { MktTable } from "../../../../../common/components/Table/Table";
import { convertTimestampToLocaleDate } from "../../../../../common/utils/TimeUtils";
import { ReleaseWorkingVersionDialog } from "./Dialogs/ReleaseWorkingVersionDialog";
import { AddRightOfUseModal } from "../../rightsOfUse/addRightOfUseModal/AddRightOfUseModal";

interface OnlineServiceModalState {
  isOpen: "newOnlineService" | "editVersion" | "addWorkingVersion" | "releaseWorkingVersion" | "addRightOfUse" | null;
  id?: string;
  identifier?: string;
  workingVersion?: string;
  releasedVersionProducerVersion?: string;
  onlineServiceFlat?: OnlineServiceTemplateFlat;
}

export function OnlineDiensteVorlagenPage() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<OnlineServiceModalState | null>(null);

  const { notifyUserFromResponse, confirmByUser } = useNotification();

  const { data: onlineDiensteFlat, refetch: refetchOnlineDiensteFlat } = useFetchOnlineDiensteTemplates();

  const { mutate: revokeRelease } = useMutation({
    mutationKey: [revokeReleaseById.name],
    mutationFn: revokeReleaseById,
    onSettled: notifyUserFromResponse({
      successMessage: t("onlineServiceTemplates.actions.revokeRelease.successMessage")
    }),
    onSuccess: () => refetchOnlineDiensteFlat()
  });

  const { mutate: deleteOnlineDienstAndVersions } = useMutation({
    mutationKey: [deleteOnlineDienstAndVersionsById.name],
    mutationFn: deleteOnlineDienstAndVersionsById,
    onSettled: notifyUserFromResponse({ successMessage: t("onlineServiceTemplates.actions.delete.successMessage") }),
    onSuccess: () => refetchOnlineDiensteFlat()
  });

  const closeModal = () => {
    setModalState({ ...modalState, isOpen: null });
  };

  const handleConfirmationOfNewOnlineDienstDialog = (templateId: string) => {
    closeModal();
    refetchOnlineDiensteFlat()
      .then((response) => {
        const onlineService = response.data?.find((entry) => entry.id === templateId);
        if (onlineService) {
          setModalState((prevState) => ({
            ...prevState,
            isOpen: prevState?.isOpen === "addRightOfUse" ? "addRightOfUse" : null,
            onlineServiceFlat: onlineService
          }));
        }
      })
      .catch(() => {});
    confirmByUser({
      callback: () => {
        setModalState((prevState) => ({ ...prevState, isOpen: "addRightOfUse" }));
      },
      title: t("onlineServiceTemplates.redirectToRightOfUseModal.title"),
      content: t("onlineServiceTemplates.redirectToRightOfUseModal.content"),
      confirmationButtonLabel: t("onlineServiceTemplates.redirectToRightOfUseModal.confirm"),
      abortButtonLabel: t("onlineServiceTemplates.redirectToRightOfUseModal.abort")
    });
  };

  const menuButton = (row: {
    row: {
      original: OnlineServiceTemplateFlat;
    };
  }) => (
    <MktTable.MenuButton
      identifier={row.row.original.identifier}
      menuItems={[
        {
          testId: `od-action-create-working-version-${row.row.original.identifier}`,
          icon: "add",
          label: t("onlineServiceTemplates.actions.createWorkingVersion"),
          onClick: () =>
            setModalState({
              isOpen: "addWorkingVersion",
              id: row.row.original.id,
              identifier: row.row.original.identifier
            })
        },
        {
          testId: `od-action-release-${row.row.original.identifier}`,
          icon: "checkmark",
          hidden: row.row.original.workingVersionId === null || row.row.original.workingVersionId === undefined,
          label: t("onlineServiceTemplates.actions.release.buttonLabel"),
          onClick: () => {
            setModalState({
              isOpen: "releaseWorkingVersion",
              id: row.row.original.id,
              workingVersion: row.row.original.workingVersionProducerVersion,
              identifier: row.row.original.identifier,
              releasedVersionProducerVersion: row.row.original.releasedVersionProducerVersion
            });
          }
        },
        {
          testId: `od-action-edit-metadata-${row.row.original.identifier}`,
          icon: "edit",
          label: t("onlineServiceTemplates.actions.edit.buttonLabel"),
          onClick: () => {
            setModalState({
              isOpen: "editVersion",
              id: row.row.original.id,
              identifier: row.row.original.identifier
            } as OnlineServiceModalState);
          }
        },
        {
          testId: `od-action-revoke-release-${row.row.original.identifier}`,
          icon: "stop",
          hidden: row.row.original.releasedVersionId === null || row.row.original.workingVersionId === undefined,
          label: t("onlineServiceTemplates.actions.revokeRelease.buttonLabel"),
          onClick: () => {
            const { id, releasedVersionId, identifier, releasedVersionProducerVersion } = row.row.original;
            confirmByUser({
              callback: () =>
                revokeRelease({
                  onlineServiceVersionId: releasedVersionId,
                  onlineServiceId: id
                }),
              title: t("onlineServiceTemplates.actions.revokeRelease.confirmationHeading", {
                releasedVersion: releasedVersionProducerVersion
              }),
              content: t("onlineServiceTemplates.actions.revokeRelease.confirmationHint", { identifier }),
              confirmationButtonLabel: t("onlineServiceTemplates.actions.revokeRelease.confirmationButtonLabel")
            });
          }
        },
        {
          testId: `od-action-delete-${row.row.original.identifier}`,
          icon: "trashcan",
          label: t("onlineServiceTemplates.actions.delete.buttonLabel"),
          onClick: () => {
            const { id, identifier } = row.row.original;
            confirmByUser({
              callback: () => deleteOnlineDienstAndVersions({ onlineServiceId: id }),
              title: t("onlineServiceTemplates.actions.delete.confirmationHeading", { identifier }),
              content: t("onlineServiceTemplates.actions.delete.confirmationHint"),
              confirmationButtonLabel: t("onlineServiceTemplates.actions.delete.confirmationButtonLabel")
            });
          }
        }
      ]}
    />
  );

  return (
    <>
      <Stack spacing="5xl">
        <Stack orientation="vertical" spacing="5xl">
          <Stack spacing="m">
            <Stack orientation="horizontal">
              <FlexSpacer />
              <FjdButton
                type="button"
                onClick={() => window.open("/online-services", "_blank", "noreferrer,noopener")}
                label="Vorschau Online-Dienste Liste"
                iconLeft="launch"
                appearance="outline"
              />
              <FjdButton
                testId="new-online-service-button"
                label={t("onlineServiceTemplates.button.newOnlineService")}
                iconLeft="add-outline"
                appearance="default"
                onClick={() => {
                  setModalState({ isOpen: "newOnlineService", id: "", identifier: "" });
                }}
                size="m"
              />
            </Stack>
            <div>
              {onlineDiensteFlat && (
                <MktTable
                  data={onlineDiensteFlat}
                  columns={[
                    {
                      Header: "Kennung",
                      accessor: "identifier"
                    },
                    {
                      Header: "Freigegeben",
                      accessor: "releasedVersionProducerVersion"
                    },
                    {
                      Header: "Bearbeitung",
                      accessor: "lastModifiedByUser"
                    },
                    {
                      Header: "Bearbeitet",
                      accessor: "lastModifiedAt",
                      Cell: ({ value }: { value: string }) => (value ? convertTimestampToLocaleDate(value) : "")
                    },
                    {
                      Header: "Arbeitsversion",
                      accessor: "workingVersionProducerVersion"
                    },
                    {
                      Header: "",
                      accessor: "id",
                      Cell: menuButton
                    }
                  ]}
                />
              )}
            </div>
          </Stack>
        </Stack>
      </Stack>
      {modalState?.isOpen === "newOnlineService" && (
        <NewOnlineServiceDialog isOpen onClose={closeModal} onConfirmed={handleConfirmationOfNewOnlineDienstDialog} />
      )}
      {modalState && modalState.id && modalState.identifier && modalState.isOpen === "addWorkingVersion" && (
        <NewWorkingVersionDialog
          onlineServiceId={modalState.id}
          identifier={modalState.identifier}
          isOpen
          onClose={closeModal}
          onConfirmed={() => {
            closeModal();
            refetchOnlineDiensteFlat()
              .then(() => {})
              .catch(() => {});
          }}
        />
      )}
      {modalState &&
        modalState.workingVersion &&
        modalState.identifier &&
        modalState.id &&
        modalState.isOpen === "releaseWorkingVersion" && (
          <ReleaseWorkingVersionDialog
            isOpen
            onClose={closeModal}
            workingVersion={modalState.workingVersion}
            identifier={modalState.identifier}
            releasedVersion={modalState.releasedVersionProducerVersion}
            onConfirmed={() => {
              closeModal();
              refetchOnlineDiensteFlat()
                .then(() => {})
                .catch(() => {});
            }}
            onlineServiceId={modalState.id}
          />
        )}
      {modalState && modalState.id && modalState.identifier && modalState.isOpen === "editVersion" && (
        <EditVersionsDialog
          onlineServiceId={modalState.id}
          identifier={modalState.identifier}
          isOpen
          onClose={closeModal}
        />
      )}
      {modalState && modalState.isOpen === "addRightOfUse" && (
        <AddRightOfUseModal
          isModalVisible
          handleModalClose={closeModal}
          initialFormValues={{ onlineService: modalState.onlineServiceFlat }}
        />
      )}
    </>
  );
}
