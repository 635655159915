import React, { createContext, useCallback, useMemo, useState } from "react";
import { FjdAlert, FjdAlertContainer } from "fjd-react-components";
import { FjdAlertIntent, FjdAlertProps } from "fjd-react-components/build/components/Alert/Alert";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";

export interface AlertProps {
  intent: FjdAlertIntent;
  title?: string;
}

/**
 * Call this function, when you want to ask for confirmation of the customer to trigger a certain request (=callback)
 * @param callback request callback which is getting triggerd, after customer confirm within the dialog
 * @param title overrides the default title, provided in <NotificationProvider ... />
 * @param confirmationButtonLabel label of the confirmation button
 * @param abortButtonLabel label of the abort button
 * @param content the content of the notification modal.
 */
export interface ModalProps {
  callback: () => unknown;
  title?: string;
  confirmationButtonLabel?: string;
  abortButtonLabel?: string;
  content?: React.ReactNode;
}

interface NotificationContextProps {
  notifyUser: (props: AlertProps) => void;
  confirmByUser: ({ callback, title, confirmationButtonLabel, abortButtonLabel, content }: ModalProps) => void;
}

export const NotificationContext = createContext<NotificationContextProps>({
  notifyUser: () => {},
  confirmByUser: () => {}
});

interface NotificationProviderProps {
  children: React.ReactNode;
  defaultModalConfirmationButtonLabel: string;
  defaultModalAbortButtonLabel: string;
  defaultModalHeading: string;
  defaultModalContent: React.ReactNode;
  defaultAlertErrorMessage: string;
  defaultAlertSuccessMessage: string;
}

/**
 * Set the default label values for the global alert and modal. Example for 18next: modalHeading={t('modalHeading')}
 * @param children
 * @param defaultModalConfirmationButtonLabel label of the confirmation button of the confirmation modal
 * @param defaultModalAbortButtonLabel label of the abort button of the confirmation modal
 * @param defaultModalHeading heading of the confirmation modal
 * @param defaultModalContent content of the confirmation modal
 * @param defaultAlertErrorMessage error message shown in toast message
 * @param alertDefaultSuccessMessage success message shown in toast message
 * @constructor
 */
export function NotificationProvider({
  children,
  defaultModalConfirmationButtonLabel,
  defaultModalAbortButtonLabel,
  defaultModalHeading,
  defaultModalContent,
  defaultAlertErrorMessage,
  defaultAlertSuccessMessage
}: NotificationProviderProps) {
  const [isAlertVisible, setIsAlertVisible] = useState<null | FjdAlertProps>(null);
  const [shouldUserConfirm, setShouldUserConfirm] = useState<null | ModalProps>(null);

  function confirmByUser(props: ModalProps) {
    setShouldUserConfirm(props);
  }

  /**
   * Call this function, when an alert should appear after your request is settled.
   * @param status HTTP Status from the corresponding request. Necessary to determine the state of the response
   * @param message overrides the message from server
   */
  const notifyUser = useCallback(
    ({ intent, title }: AlertProps) => {
      if (intent === "success") setIsAlertVisible({ intent, title: title ?? defaultAlertSuccessMessage });
      if (intent === "error") setIsAlertVisible({ intent, title: title ?? defaultAlertErrorMessage });
    },
    [defaultAlertErrorMessage, defaultAlertSuccessMessage]
  );

  const notificationContextApi = useMemo(
    () => ({
      confirmByUser,
      notifyUser
    }),
    [notifyUser]
  );

  return (
    <NotificationContext.Provider value={notificationContextApi}>
      {children}
      <FjdAlertContainer>
        {isAlertVisible && (
          <FjdAlert
            closable
            onClose={() => setIsAlertVisible(null)}
            intent={isAlertVisible.intent}
            title={isAlertVisible.title}
          />
        )}
      </FjdAlertContainer>
      {shouldUserConfirm !== null && (
        <ConfirmationModal
          isConfirmationModalVisible
          confirmationButtonLabel={shouldUserConfirm?.confirmationButtonLabel ?? defaultModalConfirmationButtonLabel}
          abortButtonLabel={shouldUserConfirm?.abortButtonLabel ?? defaultModalAbortButtonLabel}
          content={shouldUserConfirm?.content ?? defaultModalContent}
          confirmationHeading={shouldUserConfirm?.title ?? defaultModalHeading}
          onConfirm={() => {
            shouldUserConfirm?.callback();
            setShouldUserConfirm(null);
          }}
          onAbort={() => setShouldUserConfirm(null)}
        />
      )}
    </NotificationContext.Provider>
  );
}
