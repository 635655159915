import { DependencyList, useEffect } from "react";

export const useDelayedEffectHook = (callback: () => void, deps: DependencyList, delay: number) => {
  useEffect(() => {
    const handler = setTimeout(callback, delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay]);
};
