import { MARKETPLACE_URL_BASE, mktClient } from "../../../common/libs/axiosClient";
import { PlatformByOperators } from "../onlinedienste/OnlineServiceV2";
import { Platform } from "./Platform";
import { PlatformCredentials } from "./PlatformCredentials";

export const PLATFORM_URL = `${MARKETPLACE_URL_BASE}/platform`;
export const PLATFORMS_BY_OPERATOR_URL = `${PLATFORM_URL}/platforms-by-operators`;

export const uploadPlatformData = (plaformData: Platform) => mktClient.post(PLATFORM_URL.toString(), plaformData);

export const fetchPlatformData = () =>
  mktClient.get<Platform[]>(PLATFORM_URL.toString()).then<Platform[]>((response) => response.data);

export const deletePlatformById = (id: string) => mktClient.delete<{ id: string }>(`${PLATFORM_URL.toString()}/${id}`);

export const updatePlatformById = (platformData: Platform) =>
  mktClient.put<{ id: string }>(`${PLATFORM_URL.toString()}/${platformData.id}`, platformData);

export const fetchClientSecret = ({ platformId }: { platformId: string }) =>
  mktClient.get<PlatformCredentials>(`${PLATFORM_URL.toString()}/${platformId}/credentials`, { timeout: 13000 });

export const regenerateClientSecret = ({ platformId }: { platformId: string }) =>
  mktClient.post<PlatformCredentials>(`${PLATFORM_URL.toString()}/${platformId}/credentials`, { timeout: 13000 });

export const fetchAllPlatformsByOperator = (afterUseModel?: string) =>
  mktClient
    .get<PlatformByOperators[]>(PLATFORMS_BY_OPERATOR_URL, { params: { afterUseModel } })
    .then((response) => response.data);
