import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FjdBackdrop,
  FjdButton,
  FjdButtonSelectGroup,
  FjdFileInput,
  FjdFilePreview,
  FjdFilePreviewList,
  FjdFormControl,
  FjdSpinner,
  FjdTextInput,
  FjdTooltip
} from "fjd-react-components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Modal } from "../../../../../../common/components/Modal/Modal";
import { Stack } from "../../../../../../common/components/Stack/Stack";
import {
  createNewWorkingversion,
  extractAndValidateMetaInfoRegardingTemplate
} from "../../../../../api/onlinedienste/onlineServiceTemplateApi";
import { useNotification } from "../../../../../../common/libs/notifications/hooks/useNotification";
import { KeyValueList } from "../../../../../../common/components/KeyValueList/KeyValueList";
import { HeadlineWithContent, WordDivider } from "../../../../../../common/components/TextAlignment/TextAlignment";
import { FileType, checkFileType } from "../../../../../../common/utils/validationUtils";
import { fetchLeikaData, fetchOzgData } from "../../../../../api/catalog/catalogApi";
import { removeAllWhitespacesFrom } from "../../../../../../common/utils/StringUtils";

export const VALID_FILE_TYPES: FileType[] = [
  "image/png",
  "image/jpeg",
  "text/xml",
  "text/csv",
  "application/zip",
  "application/pdf"
];

interface NewWorkingVersionDialogResult {
  onlineDienstPackage: File | null;
  title: string;
  fimId?: string;
  leikaIds?: string;
  ozgId?: string;
  previewLink: string;
  languages: string;
  herstellerVersion: string;
  releaseNote?: string;
  releaseNoteAttachments?: File[] | null;
}

type DialogResults = NewWorkingVersionDialogResult;

interface NewWorkingVersionDialogProps {
  onlineServiceId: string;
  identifier: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirmed: () => void;
}

interface DialogState {
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  page: number;
}

const initialDialogResultData: DialogResults = {
  herstellerVersion: "",
  languages: "",
  onlineDienstPackage: null,
  ozgId: "",
  releaseNoteAttachments: null,
  title: "",
  previewLink: "",
  fimId: "",
  releaseNote: "",
  leikaIds: ""
};

export function NewWorkingVersionDialog({
  onlineServiceId,
  identifier,
  isOpen,
  onClose,
  onConfirmed
}: NewWorkingVersionDialogProps) {
  const { t } = useTranslation();
  const continueButtonLabel = t("onlineServiceTemplates.newWorkingVersionDialog.primaryButtonPage1");
  const confirmationButtonLabel = t("onlineServiceTemplates.newWorkingVersionDialog.primaryButtonConfirmation");
  const editButtonLabel = t("onlineServiceTemplates.newWorkingVersionDialog.editButtonLabel");
  const cancelButtonLabel = t("onlineServiceTemplates.newWorkingVersionDialog.cancelButton");
  const pages = 2;
  const initialDialogState = {
    page: 1,
    primaryButtonLabel: continueButtonLabel,
    secondaryButtonLabel: cancelButtonLabel
  } as DialogState;
  const [dialogResult, setDialogResult] = useState<DialogResults>(initialDialogResultData);
  const [dialogState, setDialogState] = useState<DialogState>(initialDialogState);
  const [isLeikaIdAvailable, setIsLeikaIdAvailable] = useState(true);
  const [isOzgIdAvailable, setIsOzgIdAvailable] = useState(true);
  const [newReleaseNoteAttachments, setNewReleaseNoteAttachments] = useState<File[]>([]);
  const { notifyUserFromResponse, notifyUser } = useNotification();

  const onChange = (newOnlineDienstDialogResult: Partial<DialogResults>) => {
    setDialogResult((prevState) => ({
      ...prevState,
      ...newOnlineDienstDialogResult
    }));
  };

  const resetDialog = () => {
    setDialogState(initialDialogState);
    setDialogResult({} as DialogResults);
    setNewReleaseNoteAttachments([]);
  };

  const handleReleaseNoteFileUpload = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = changeEvent.target;

    if (files) {
      const areFilesValid = checkFileType(files, VALID_FILE_TYPES);
      if (areFilesValid) {
        const newFiles = Array.from(files);
        setNewReleaseNoteAttachments((prevFiles) => [...prevFiles, ...newFiles]);
        setDialogResult((prevState) => ({
          ...prevState,
          releaseNoteAttachments: [...(prevState.releaseNoteAttachments ?? []), ...newFiles]
        }));
      } else {
        notifyUser({
          intent: "error",
          title: t("common.wrongFileType", { fileType: ".jpeg, .png, .pdf, .txt, .zip, .xml" })
        });
      }
    }
  };

  const { mutate: extractAndValidateMetaInfo, isLoading: isExtractingMetaData } = useMutation({
    mutationKey: [extractAndValidateMetaInfoRegardingTemplate],
    mutationFn: extractAndValidateMetaInfoRegardingTemplate,
    onSuccess: (data, variables) => {
      onChange({
        herstellerVersion: data.data.version,
        title: data.data.title,
        languages: data.data.languages,
        onlineDienstPackage: variables.thePackage
      });
    },
    onSettled: notifyUserFromResponse()
  });

  const { mutateAsync: createWorkingVersion, isLoading: isSendingForm } = useMutation({
    mutationKey: [createNewWorkingversion],
    mutationFn: createNewWorkingversion,
    onSuccess: () => {
      resetDialog();
      onConfirmed();
    },
    onSettled: notifyUserFromResponse({
      successMessage: t("onlineServiceTemplates.newWorkingVersionDialog.successMessage")
    })
  });

  const onConfirmation = () => {
    const { leikaIds, ozgId, previewLink, onlineDienstPackage, releaseNote, releaseNoteAttachments } = dialogResult;

    if (onlineDienstPackage) {
      createWorkingVersion({
        onlineServiceId,
        newWorkingVersionProps: {
          leikaIds: leikaIds?.replaceAll(" ", "").split(",") ?? [],
          ozgId,
          previewLink,
          releaseNote
        },
        onlineServicePackage: onlineDienstPackage,
        releaseNoteAttachments
      }).catch(() => {});
    }
  };

  const { data: leikaEntities, refetch: fetchLeikaEntitesByIds } = useQuery({
    queryKey: [fetchLeikaData.name, dialogResult?.leikaIds],
    queryFn: () => fetchLeikaData(removeAllWhitespacesFrom(dialogResult?.leikaIds ?? "")),
    onSuccess: (data) => {
      // BE found an ozg id related to the given leika ids
      if (data?.ozgEntity !== undefined && data?.ozgEntity !== null) {
        const ozgId = data.ozgEntity.id;
        setDialogResult((prevState) => ({
          ...prevState,
          ozgId
        }));
      }
    },
    enabled: false
  });

  const { data: ozgEntity, refetch: fetchOzgEntitiesById } = useQuery({
    queryKey: [fetchOzgData.name, dialogResult.ozgId],
    queryFn: () => fetchOzgData(dialogResult.ozgId ?? ""),
    enabled: false
  });

  const abortOrCloseDialog = () => {
    resetDialog();
    onClose();
  };

  function computeUpdateDialogView(nextPage: number) {
    const secondaryButtonLabel = nextPage === pages ? editButtonLabel : cancelButtonLabel;
    const primaryButtonLabel = nextPage === pages ? confirmationButtonLabel : continueButtonLabel;
    return { page: nextPage, primaryButtonLabel, secondaryButtonLabel } as DialogState;
  }

  const pageUp = () => {
    setDialogState((prevState) => {
      const newPage = Math.max(prevState.page + 1, initialDialogState.page);
      return computeUpdateDialogView(newPage);
    });
  };

  const pageDown = () => {
    setDialogState((prevState) => {
      const newPage = Math.max(prevState.page - 1, initialDialogState.page);
      return computeUpdateDialogView(newPage);
    });
  };

  const page1 = (
    <Stack>
      {dialogResult.onlineDienstPackage === undefined || dialogResult.onlineDienstPackage === null ? (
        <FjdFormControl
          inputId="packagePath"
          label={t("onlineServiceTemplates.newOnlineServiceDialog.formItems.onlineServicePackageLabel")}
        >
          <FjdFileInput
            data-testid="newWorkingVersionZipOnlineServiceUpload"
            id="newWorkingVersionPackage"
            label={t("common.selectFileButtonLabel")}
            onChange={(changeEvent) => {
              const thePackage = changeEvent.target.files?.item(0);
              if (thePackage) {
                extractAndValidateMetaInfo({ thePackage, onlineServiceId });
              }
            }}
          />
        </FjdFormControl>
      ) : (
        <FjdFilePreview
          onDelete={() => onChange({ onlineDienstPackage: undefined, title: "", herstellerVersion: "", languages: "" })}
          title={dialogResult.onlineDienstPackage ? dialogResult.onlineDienstPackage.name : ""}
        />
      )}
      <FjdFormControl optional inputId="releaseNote" label={t("common.releaseNote")}>
        <FjdTextInput
          id="releaseNote"
          testId="newWorkingVersionReleaseNote"
          onChange={(changeEvent) => {
            onChange({ releaseNote: changeEvent.target.value });
            if (changeEvent.target.value.length >= 1000) {
              notifyUser({ intent: "error", title: t("common.maxAmountCharaktersReached") });
            }
          }}
          placeholder="Release-Note..."
          value={dialogResult.releaseNote}
          rows={3}
          maxLength={1000}
        />
      </FjdFormControl>
      <FjdFormControl optional label={t("common.releaseNoteAttachment")}>
        {newReleaseNoteAttachments.length === 0 ? (
          <FjdFileInput
            id="newWorkingVersionReleaseNoteFileUpload"
            data-testid="newWorkingVersionReleaseNoteFileUpload"
            label={t("common.selectFileButtonLabel")}
            onChange={handleReleaseNoteFileUpload}
            multiple
          />
        ) : (
          <Stack>
            <FjdFilePreviewList
              items={
                newReleaseNoteAttachments?.map((file) => ({
                  icon: "document",
                  onDelete: () => {
                    const remainingFiles = newReleaseNoteAttachments.filter((f) => f !== file);
                    onChange({
                      releaseNoteAttachments: remainingFiles
                    });
                    setNewReleaseNoteAttachments(remainingFiles);
                  },
                  title: file.name
                })) ?? []
              }
            />
            <FjdFileInput
              id="newWorkingVersionReleaseNoteFileUpload"
              data-testid="newWorkingVersionReleaseNoteFileUpload"
              label={t("common.selectNextFile")}
              onChange={handleReleaseNoteFileUpload}
              appearance="outline"
              multiple
            />
          </Stack>
        )}
      </FjdFormControl>
      <FjdFormControl inputId="previewLink" label={t("onlineServiceTemplates.previewLink")}>
        <FjdTextInput
          testId="newWorkingVersionPreviewLink"
          id="newWorkingVersionPreviewLink"
          label={t("onlineServiceTemplates.previewLink")}
          placeholder={t("onlineServiceTemplates.previewLink")}
          onChange={(changeEvent) => onChange({ previewLink: changeEvent.target.value })}
          value={dialogResult.previewLink}
        />
      </FjdFormControl>
      <FjdFormControl inputId="leikaIdFormLabel" label={t("onlineServiceTemplates.leikaIdFormLabel")}>
        <FjdButtonSelectGroup
          size="m"
          id="leikaIdSwitch"
          onChange={(checked) => {
            setIsLeikaIdAvailable(checked.target.value === "yes");
            if (checked.target.value === "no") {
              setDialogResult((prevState) => ({ ...prevState, leikaId: undefined }));
            } else if (checked.target.value === "yes") {
              setDialogResult((prevState) => ({ ...prevState, ozgId: undefined }));
            }
          }}
          options={[
            {
              text: t("onlineServiceTemplates.button.yes"),
              value: "yes",
              selected: isLeikaIdAvailable
            },
            {
              text: t("onlineServiceTemplates.button.no"),
              value: "no",
              selected: !isLeikaIdAvailable
            }
          ]}
        />
        {isLeikaIdAvailable && (
          <>
            <FjdTextInput
              id="leikaIdInput"
              testId="leikaIdInput"
              label={t("onlineServiceTemplates.leikaIdInput")}
              placeholder={t("onlineServiceTemplates.leikaIdInput")}
              value={dialogResult.leikaIds}
              onChange={(changeEvent) => onChange({ leikaIds: changeEvent.target.value })}
            />
            <FjdTooltip placement="auto" tooltip={<div>{t("onlineServiceTemplates.leikaIdInputTooltip")}</div>}>
              <FjdButton label="" appearance="primary-link" iconRight="information-outline" hideLabel />
            </FjdTooltip>
          </>
        )}
      </FjdFormControl>
      {!isLeikaIdAvailable && (
        <FjdFormControl inputId="ozgIdFormLabel" label={t("onlineServiceTemplates.ozgIdFormLabel")}>
          <FjdButtonSelectGroup
            id="ozgIdSwitch"
            onChange={(checked) => {
              setIsOzgIdAvailable(checked.target.value === "yes");
              if (checked.target.value === "no") setDialogResult((prevState) => ({ ...prevState, ozgId: undefined }));
            }}
            options={[
              {
                text: t("onlineServiceTemplates.button.yes"),
                value: "yes",
                selected: isOzgIdAvailable
              },
              {
                text: t("onlineServiceTemplates.button.no"),
                value: "no",
                selected: !isOzgIdAvailable
              }
            ]}
          />
          {isOzgIdAvailable && (
            <FjdTextInput
              id="ozgIdInput"
              testId="ozgIdInput"
              label={t("onlineServiceTemplates.ozgIdInput")}
              placeholder={t("onlineServiceTemplates.ozgIdInput")}
              value={dialogResult.ozgId}
              onChange={(changeEvent) => onChange({ ozgId: changeEvent.target.value })}
            />
          )}
        </FjdFormControl>
      )}
    </Stack>
  );

  const page2 = (
    <KeyValueList heading={t("onlineServiceTemplates.summarySectionLabel")}>
      <KeyValueList.Section key="summary">
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.zipFileLabel")}
          value={dialogResult.onlineDienstPackage?.name ?? ""}
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.versionLabel")}
          value={dialogResult.herstellerVersion}
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.titleLabel")}
          value={dialogResult.title}
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.languagesLabel")}
          value={<WordDivider words={dialogResult.languages?.split(",") ?? []} />}
        />
        {/*  */}
        <KeyValueList.ListItem term={t("common.releaseNote")} value={dialogResult.releaseNote || "-"} />
        <KeyValueList.ListItem
          term={t("common.releaseNoteAttachment")}
          value={
            dialogResult.releaseNoteAttachments && dialogResult.releaseNoteAttachments.length > 0
              ? dialogResult.releaseNoteAttachments.map((file) => file.name).join(", ")
              : "-"
          }
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.previewLink")}
          value={
            dialogResult.previewLink === undefined || dialogResult.previewLink === "" ? "-" : dialogResult.previewLink
          }
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.leikaIdFormLabel")}
          value={
            dialogResult.leikaIds ? (
              <HeadlineWithContent
                text={[
                  ...dialogResult.leikaIds.split(",").map((id) => ({
                    headline: id,
                    content: leikaEntities?.leikaEntities.find((entity) => entity.id === id.trim())?.description ?? ""
                  }))
                ]}
              />
            ) : (
              "-"
            )
          }
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.ozgIdFormLabel")}
          value={
            dialogResult.ozgId ? (
              <HeadlineWithContent
                text={[
                  {
                    headline: dialogResult.ozgId,
                    content: ozgEntity?.description ?? leikaEntities?.ozgEntity?.description ?? ""
                  }
                ]}
              />
            ) : (
              "-"
            )
          }
        />
      </KeyValueList.Section>
    </KeyValueList>
  );

  function getCurrentPage(pageIndex: number) {
    if (isExtractingMetaData || isSendingForm) {
      return (
        <FjdBackdrop>
          <FjdSpinner size="m" />
        </FjdBackdrop>
      );
    }
    switch (pageIndex) {
      case 2:
        return page2;
      default:
        return page1;
    }
  }

  return (
    <Modal
      data-testid="new-working-version-dialog"
      closable
      onClose={abortOrCloseDialog}
      open={isOpen}
      closeOnBackdropClick
      heading={t("onlineServiceTemplates.newWorkingVersionDialog.heading", { identifier })}
      primaryButtonLabel={dialogState.primaryButtonLabel}
      onBackButtonClick={() => {
        pageDown();
      }}
      onPrimaryButtonClick={() => {
        if (dialogResult.onlineDienstPackage === undefined || dialogResult.onlineDienstPackage === null) {
          notifyUser({ intent: "error", title: t("onlineServiceTemplates.missingPackage") });
          return;
        }
        if (dialogState.page === pages) {
          onConfirmation();
        } else {
          if (isLeikaIdAvailable) {
            fetchLeikaEntitesByIds().catch(() => {});
          } else if (isOzgIdAvailable) {
            fetchOzgEntitiesById().catch(() => {});
          }
          pageUp();
        }
      }}
      secondaryButtonLabel={dialogState.secondaryButtonLabel}
      onSecondaryButtonClick={() => {
        if (dialogState.page === initialDialogState.page) {
          abortOrCloseDialog();
          return;
        }
        pageDown();
      }}
    >
      {getCurrentPage(dialogState.page)}
    </Modal>
  );
}
