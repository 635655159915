import { FjdHeading } from "fjd-react-components";
import { FjdHeadingProps } from "fjd-react-components/build/components/Heading/Heading";
import React from "react";

export interface HeadingProps extends FjdHeadingProps {
  className?: string;
}

export function Heading({
  disabled,
  ellipsis,
  icon,
  id,
  level,
  light,
  maxLines,
  subheading,
  text,
  visualLevel,
  tabIndex,
  className
}: HeadingProps) {
  return (
    <div className={className}>
      <FjdHeading
        disabled={disabled}
        ellipsis={ellipsis}
        icon={icon}
        id={id}
        level={level}
        light={light}
        maxLines={maxLines}
        subheading={subheading}
        text={text}
        visualLevel={visualLevel}
        tabIndex={tabIndex}
      />
    </div>
  );
}
