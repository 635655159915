import classnames from "classnames";
import * as React from "react";
import { FjdButton, FjdHeading } from "fjd-react-components";
import { FjdIconGlyph } from "fjd-react-components/build/components/Icon/Icon";
import { FjdDeviceDisplay } from "../../utils/FjdDeviceDisplay";
import "./Banner.css";

export interface BannerProps {
  actionIcon?: FjdIconGlyph;
  actionLabel?: string;
  heading: string;
  subheading?: string;
  imageUrl: string;
  hint?: string;
  onActionClick?: React.MouseEventHandler;
  testId?: string;
}

export function Banner({
  actionIcon,
  actionLabel,
  subheading,
  heading,
  imageUrl,
  onActionClick,
  hint,
  testId
}: BannerProps) {
  const className = classnames("fjd-hero-image", "fjd-hero-heading");
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const isMobile = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < FjdDeviceDisplay.L;

  return (
    <div
      className={className}
      style={{ backgroundImage: `url(${imageUrl})`, backgroundPositionX: "right" }}
      data-testid={testId}
    >
      <div className="fjd-hero-image__heading">
        {hint && <div className="count_online_services">{hint} </div>}
        <FjdHeading level={1} text={heading} light />
        {subheading && <FjdHeading level={4} text={subheading} light />}
        {actionLabel && (
          <div className="fjd-hero-image__action">
            <FjdButton iconRight={actionIcon} label={actionLabel} onClick={onActionClick} size={isMobile ? "m" : "l"} />
          </div>
        )}
      </div>
    </div>
  );
}
