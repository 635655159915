import {
  FjdButton,
  FjdButtonSelectGroup,
  FjdFileInput,
  FjdFilePreviewList,
  FjdFormControl,
  FjdTextInput,
  FjdTooltip
} from "fjd-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchOnlineServiceTemplateById,
  updateTemplateVersion
} from "../../../../../api/onlinedienste/onlineServiceTemplateApi";
import { Heading } from "../../../../../../common/components/Heading/Heading";
import { KeyValueList } from "../../../../../../common/components/KeyValueList/KeyValueList";
import { Stack } from "../../../../../../common/components/Stack/Stack";
import { Button } from "../../../../../../common/components/Button/Button";
import { FlexSpacer } from "../../../../../../common/components/FlexSpacer/FlexSpacer";
import { Modal } from "../../../../../../common/components/Modal/Modal";
import { MultiPageForm } from "../../../../../../common/components/MultiPageForm/MultiPageForm";
import {
  FileMetadata,
  OnlineServiceTemplateVersion,
  OnlineServiceVersionCreateOrUpdateProps
} from "../../../../../api/onlinedienste/OnlineServicesV1";
import { HeadlineWithContent } from "../../../../../../common/components/TextAlignment/TextAlignment";
import { fetchLeikaData, fetchOzgData } from "../../../../../api/catalog/catalogApi";
import { removeAllWhitespacesFrom } from "../../../../../../common/utils/StringUtils";
import { useNotification } from "../../../../../../common/libs/notifications/hooks/useNotification";
import { checkFileType } from "../../../../../../common/utils/validationUtils";
import { VALID_FILE_TYPES } from "./NewWorkingVersionDialog";

interface VersionsOverviewProps {
  onlineServiceId: string;
  identifier: string;
}

export interface EditVersionProps extends VersionsOverviewProps {
  version: OnlineServiceTemplateVersion;
  isOpen: boolean;
  onClose: () => void;
}

export function EditModal({ onlineServiceId, identifier, version, isOpen, onClose }: EditVersionProps) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<OnlineServiceVersionCreateOrUpdateProps>({
    releaseNote: version.releaseNote ?? "",
    releaseNoteAttachments: version.releaseNoteAttachments,
    previewLink: version.previewLink ?? "",
    ozgId: version.ozgId ?? "",
    leikaIds: version.leikaIds ?? [],
    fimId: version.fimId ?? ""
  });
  const [isLeikaIdAvailable, setIsLeikaIdAvailable] = useState<boolean>(version.leikaIds.length !== 0);
  const [isOzgIdAvailable, setIsOzgIdAvailable] = useState<boolean>(version.leikaIds.length === 0 && !!version.ozgId);

  const [newReleaseNoteAttachments, setNewReleaseNoteAttachments] = useState<File[]>([]);
  const { notifyUserFromResponse, notifyUser } = useNotification();

  const handleReleaseNoteFileUpload = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = changeEvent.target;

    if (files) {
      const areFilesValid = checkFileType(files, VALID_FILE_TYPES);

      if (areFilesValid) {
        const newFiles: File[] = Array.from(files);

        setNewReleaseNoteAttachments((prevFiles) => [...prevFiles, ...newFiles]);

        const newReleaseNoteMetadata: FileMetadata[] = newFiles.map((file) => ({
          fileName: file.name
        }));
        setFormData((prevState) => ({
          ...prevState,
          releaseNoteAttachments: prevState.releaseNoteAttachments
            ? [...prevState.releaseNoteAttachments, ...newReleaseNoteMetadata]
            : newReleaseNoteMetadata
        }));
      } else {
        notifyUser({
          intent: "error",
          title: t("common.wrongFileType", { fileType: ".jpeg, .png, .pdf, .txt, .zip, .xml" })
        });
      }
    }
  };

  const updateForm = (formUpdate: OnlineServiceVersionCreateOrUpdateProps) =>
    setFormData((prevState) => ({
      ...prevState,
      ...formUpdate
    }));

  const { data: leikaEntities, refetch: fetchLeikaEntitesByIds } = useQuery({
    queryKey: [fetchLeikaData.name, formData?.leikaIds],
    queryFn: () => fetchLeikaData(removeAllWhitespacesFrom(formData?.leikaIds?.join(",") ?? "")),
    onSuccess: (data) => {
      // BE found an ozg id related to the given leika ids
      if (data?.ozgEntity !== undefined && data?.ozgEntity !== null) {
        const ozgId = data.ozgEntity.id;
        updateForm({
          ozgId
        });
      }
    },
    enabled: false
  });

  const { data: ozgEntity, refetch: fetchOzgEntitiesById } = useQuery({
    queryKey: [fetchOzgData.name, formData.ozgId],
    queryFn: () => fetchOzgData(formData.ozgId ?? ""),
    enabled: false
  });

  const resetForm = () => {
    setFormData({});
  };

  const closeForm = () => {
    resetForm();
    onClose();
  };

  const { mutateAsync: doUpdateTemplateVersion, isLoading: isSendingForm } = useMutation({
    mutationKey: [updateTemplateVersion.name, onlineServiceId, version.templateVersionId],
    mutationFn: updateTemplateVersion,
    onSettled: notifyUserFromResponse({
      successMessage: t("onlineServiceTemplates.edit.editModal.successMessage", { version: version.producerVersion })
    })
  });

  return (
    <MultiPageForm
      formHeading={t("onlineServiceTemplates.edit.editModal.heading", { identifier })}
      onClose={closeForm}
      onConfirmed={() => {
        doUpdateTemplateVersion({
          templateId: onlineServiceId,
          versionId: version.templateVersionId,
          formData,
          releaseNoteAttachments: newReleaseNoteAttachments
        })
          .then(() => {
            closeForm();
          })
          .catch(() => {});
      }}
      isOpen={isOpen}
      isBusy={isSendingForm}
      testId="edit-modal"
    >
      <MultiPageForm.FormPage
        nextPageButtonLabel={t("onlineServiceTemplates.summarySectionLabel")}
        testId="edit-page1"
        onPageRender={() => {
          updateForm({ ozgId: "" });
          return Promise.resolve();
        }}
      >
        <Stack spacing="xl">
          <FjdFormControl optional inputId="releaseNote" label={t("common.releaseNote")}>
            <FjdTextInput
              id="releaseNote"
              testId="editVersionDialogReleaseNote"
              onChange={(changeEvent) => {
                updateForm({ releaseNote: changeEvent.target.value });
                if (changeEvent.target.value.length >= 1000) {
                  notifyUser({ intent: "error", title: t("common.maxAmountCharaktersReached") });
                }
              }}
              placeholder="Release-Note..."
              value={formData.releaseNote}
              rows={3}
              maxLength={1000}
            />
          </FjdFormControl>
          <FjdFormControl optional label={t("common.releaseNoteAttachment")}>
            {formData.releaseNoteAttachments?.length === 0 ? (
              <FjdFileInput
                id="editVersionDialogReleaseNoteFileUpload"
                data-testid="editVersionDialogReleaseNoteFileUpload"
                label={t("common.selectFileButtonLabel")}
                onChange={handleReleaseNoteFileUpload}
                accept="pdf"
                multiple
              />
            ) : (
              <Stack>
                <FjdFilePreviewList
                  items={
                    formData.releaseNoteAttachments?.map((file) => ({
                      icon: "document",
                      onDelete: () => {
                        const remainingFiles = formData.releaseNoteAttachments?.filter((f) => f !== file) || [];
                        setFormData((prevState) => ({
                          ...prevState,
                          releaseNoteAttachments: remainingFiles
                        }));
                      },
                      title: file.fileName
                    })) ?? []
                  }
                />
                <FjdFileInput
                  id="editOnlineServiceReleaseNoteFileUpload"
                  data-testid="editOnlineServiceReleaseNoteFileUpload"
                  label={t("common.selectNextFile")}
                  onChange={handleReleaseNoteFileUpload}
                  appearance="outline"
                  multiple
                />
              </Stack>
            )}
          </FjdFormControl>
          <FjdFormControl inputId="previewLink" label={t("onlineServiceTemplates.previewLink")}>
            <FjdTextInput
              id="previewLink"
              testId="previewLinkInput"
              label={t("onlineServiceTemplates.previewLink")}
              placeholder={t("onlineServiceTemplates.previewLink")}
              onChange={(changeEvent) => updateForm({ previewLink: changeEvent.target.value })}
              value={formData.previewLink}
            />
          </FjdFormControl>
          <FjdFormControl inputId="fimId" label={t("onlineServiceTemplates.fimIdFormLabel")}>
            <FjdTextInput
              id="fimId"
              label={t("onlineServiceTemplates.fimId")}
              onChange={(changeEvent) => updateForm({ fimId: changeEvent.target.value })}
              value={formData.fimId}
              testId="fimIdInput"
            />
          </FjdFormControl>
          <FjdFormControl inputId="leikaId" label={t("onlineServiceTemplates.leikaIdFormLabel")}>
            <FjdButtonSelectGroup
              size="m"
              id="leikaIdSwitch"
              onChange={(checked) => {
                setIsLeikaIdAvailable(checked.target.value === "yes");
                if (checked.target.value === "no") {
                  updateForm({ leikaIds: undefined });
                } else if (checked.target.value === "yes") {
                  updateForm({ ozgId: undefined });
                }
              }}
              options={[
                {
                  text: t("onlineServiceTemplates.button.yes"),
                  value: "yes",
                  selected: isLeikaIdAvailable
                },
                {
                  text: t("onlineServiceTemplates.button.no"),
                  value: "no",
                  selected: !isLeikaIdAvailable
                }
              ]}
            />
            {isLeikaIdAvailable && (
              <>
                <FjdTextInput
                  id="leikaIdInput"
                  testId="leikaIdInput"
                  label={t("onlineServiceTemplates.leikaIdInput")}
                  placeholder={t("onlineServiceTemplates.leikaIdInput")}
                  value={formData.leikaIds?.join(",")}
                  onChange={(changeEvent) => updateForm({ leikaIds: changeEvent.target.value.split(",") })}
                />
                <FjdTooltip placement="auto" tooltip={<div>{t("onlineServiceTemplates.leikaIdInputTooltip")}</div>}>
                  <FjdButton label="" appearance="primary-link" iconRight="information-outline" hideLabel />
                </FjdTooltip>
              </>
            )}
          </FjdFormControl>
          {!isLeikaIdAvailable && (
            <FjdFormControl inputId="ozgId" label={t("onlineServiceTemplates.ozgIdFormLabel")}>
              <FjdButtonSelectGroup
                id="ozgIdSwitch"
                onChange={(checked) => {
                  setIsOzgIdAvailable(checked.target.value === "yes");
                  if (checked.target.value === "no") updateForm({ ozgId: "" });
                }}
                options={[
                  {
                    text: t("onlineServiceTemplates.button.yes"),
                    value: "yes",
                    selected: isOzgIdAvailable
                  },
                  {
                    text: t("onlineServiceTemplates.button.no"),
                    value: "no",
                    selected: !isOzgIdAvailable
                  }
                ]}
              />
              {isOzgIdAvailable && (
                <FjdTextInput
                  id="ozgId"
                  testId="ozgIdInput"
                  label={t("onlineServiceTemplates.ozgIdInput")}
                  placeholder={t("onlineServiceTemplates.ozgIdInput")}
                  value={formData.ozgId}
                  onChange={(changeEvent) => updateForm({ ozgId: changeEvent.target.value })}
                />
              )}
            </FjdFormControl>
          )}
        </Stack>
      </MultiPageForm.FormPage>
      <MultiPageForm.FormPage
        onPageRender={() => {
          const promises: Promise<unknown>[] = [];
          if (isLeikaIdAvailable) promises.push(fetchLeikaEntitesByIds());
          if (isOzgIdAvailable) promises.push(fetchOzgEntitiesById());
          return Promise.all(promises)
            .then(() => {})
            .catch(() => {});
        }}
        testId="edit-page2"
      >
        <KeyValueList heading={t("onlineServiceTemplates.summarySectionLabel")}>
          <KeyValueList.Section key="summary">
            <KeyValueList.ListItem term={t("common.releaseNote")} value={formData.releaseNote ?? "-"} />
            <KeyValueList.ListItem
              term={t("common.releaseNoteAttachment")}
              value={
                formData.releaseNoteAttachments && formData.releaseNoteAttachments.length > 0
                  ? formData.releaseNoteAttachments.map((file) => file.fileName).join(", ")
                  : "-"
              }
            />
            <KeyValueList.ListItem
              term={t("onlineServiceTemplates.previewLink")}
              value={formData.previewLink === undefined || formData.previewLink === "" ? "-" : formData.previewLink}
            />
            <KeyValueList.ListItem
              term={t("onlineServiceTemplates.fimIdFormLabel")}
              value={formData.fimId === undefined || formData.fimId === "" ? "-" : formData.fimId}
            />
            <KeyValueList.ListItem
              term={t("onlineServiceTemplates.leikaIdFormLabel")}
              value={
                formData.leikaIds ? (
                  <HeadlineWithContent
                    text={[
                      ...formData.leikaIds.map((id) => ({
                        headline: id,
                        content:
                          (leikaEntities?.leikaEntities ?? []).find((entity) => entity.id === id.trim())?.description ??
                          ""
                      }))
                    ]}
                  />
                ) : (
                  "-"
                )
              }
            />
            <KeyValueList.ListItem
              term={t("onlineServiceTemplates.ozgIdFormLabel")}
              value={
                formData.ozgId ? (
                  <HeadlineWithContent
                    text={[
                      {
                        headline: formData.ozgId,
                        content: ozgEntity?.description ?? leikaEntities?.ozgEntity?.description ?? ""
                      }
                    ]}
                  />
                ) : (
                  "-"
                )
              }
            />
          </KeyValueList.Section>
        </KeyValueList>
      </MultiPageForm.FormPage>
    </MultiPageForm>
  );
}

export function VersionsOverview({ onlineServiceId, identifier }: VersionsOverviewProps) {
  const { t } = useTranslation();

  const [doesShowAllVersions, setDoesShowAllVersions] = useState<boolean>(false);
  const [editVersion, setEditVersion] = useState<EditVersionProps | null>(null);

  const { data: onlineServiceTemplate, refetch: refetchTemplate } = useQuery({
    queryKey: [fetchOnlineServiceTemplateById, onlineServiceId],
    queryFn: () => fetchOnlineServiceTemplateById(onlineServiceId)
  });

  const statusTranslation = new Map<string, string>([
    ["WORKED_ON", t("onlineServiceTemplates.status.workedOn")],
    ["RELEASED", t("onlineServiceTemplates.status.released")],
    ["RELEASE_REVOKED", t("onlineServiceTemplates.status.releaseRevoked")]
  ]);

  const minimalVisibleVersions = 3;
  const visibleVersions = onlineServiceTemplate?.versions ?? [];
  const maximumVisibleVersions = visibleVersions.length;
  const amountOfVisibleVersions = doesShowAllVersions ? maximumVisibleVersions : minimalVisibleVersions;
  const toggleVersionVisibility = () => setDoesShowAllVersions((prevState) => !prevState);

  return (
    <>
      <Heading text={t("onlineServiceTemplates.edit.overview.heading")} />
      <Heading text="" subheading={t("onlineServiceTemplates.edit.overview.subHeading", { identifier })} level={3} />
      <KeyValueList heading="" data-testid="version-list">
        <KeyValueList.Section>
          {visibleVersions.slice(0, amountOfVisibleVersions).map((templateVersion) => (
            <Stack orientation="horizontal" key={templateVersion.producerVersion} spacing="s">
              <div>
                <strong>{templateVersion.producerVersion}</strong>
              </div>
              <div>{statusTranslation.get(templateVersion.status)}</div>
              <Button
                label="edit"
                iconLeft="edit"
                hideLabel
                appearance="primary-link"
                size="s"
                testId={`edit-version-${templateVersion.producerVersion}`}
                onClick={() =>
                  setEditVersion({
                    version: templateVersion,
                    isOpen: true,
                    onClose: () => setEditVersion(null),
                    onlineServiceId,
                    identifier
                  })
                }
              />
            </Stack>
          ))}
        </KeyValueList.Section>
      </KeyValueList>
      {maximumVisibleVersions <= minimalVisibleVersions ? null : (
        <Stack orientation="vertical" spacing="xxl">
          <FlexSpacer />
          <Button
            label={
              doesShowAllVersions
                ? t("onlineServiceTemplates.edit.overview.showLatestVersionsButtonLabel")
                : t("onlineServiceTemplates.edit.overview.showOlderVersionsButtonLabel")
            }
            onClick={toggleVersionVisibility}
            testId="toggle-version-visibility-button"
            appearance="primary-link"
            size="s"
          />
        </Stack>
      )}
      {editVersion ? (
        <EditModal
          isOpen
          identifier={editVersion?.identifier || ""}
          onlineServiceId={editVersion?.onlineServiceId || ""}
          onClose={() => {
            setEditVersion(null);
            refetchTemplate()
              .then(() => {})
              .catch(() => {});
          }}
          version={editVersion?.version || ({} as OnlineServiceVersionCreateOrUpdateProps)}
        />
      ) : null}
    </>
  );
}

interface VersionsOverviewModalProps extends VersionsOverviewProps {
  isOpen: boolean;
  onClose: () => void;
}

export function EditVersionsDialog({ onlineServiceId, identifier, isOpen, onClose }: VersionsOverviewModalProps) {
  return (
    <Modal data-testid="edit-versions-dialog" closable onClose={onClose} open={isOpen} closeOnBackdropClick heading="">
      <VersionsOverview onlineServiceId={onlineServiceId} identifier={identifier} />
    </Modal>
  );
}
