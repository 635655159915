import React from "react";
import { FjdButtonGroup } from "fjd-react-components";
import "./Footer.css";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import { FjdDeviceDisplay } from "../../utils/FjdDeviceDisplay";

function Hint({ children }: { children: React.ReactNode }) {
  const isMobile = useMediaQuery({ maxWidth: FjdDeviceDisplay.M });
  const footerStyles = classNames("footer-hint", {
    "footer-hint-mobile": isMobile,
    "footer-hint-desktop": !isMobile
  });

  return <span className={footerStyles}>{children}</span>;
}

function Navigation({ children }: { children: React.ReactNode }) {
  return (
    <div className="fjd-base-layout__footer">
      <FjdButtonGroup>{children}</FjdButtonGroup>
    </div>
  );
}

export function Copyright({ children }: { children: React.ReactNode }) {
  return <span className="footer-copyright">{children}</span>;
}

export function FooterComponent({ children }: { children: React.ReactNode }) {
  return <footer className="footer">{children}</footer>;
}

FooterComponent.Hint = Hint;
FooterComponent.Navigation = Navigation;
FooterComponent.Copyright = Copyright;

export const Footer = FooterComponent;
