import React from "react";
import { FjdButton, FjdColumns, FjdSpacer } from "fjd-react-components";
import { useTranslation } from "react-i18next";
import "./NachnutzungsModal.css";
import { URL_NACHNUTZUNG_MODELS } from "../../../../common/url";
import { FlexSpacer } from "../../../../common/components/FlexSpacer/FlexSpacer";
import { Markdown } from "../../../../common/components/Markdown/Markdown";
import { Stack } from "../../../../common/components/Stack/Stack";
import { Heading } from "../../../../common/components/Heading/Heading";
import { Modal } from "../../../../common/components/Modal/Modal";
import { CallToAction } from "../../../../common/components/CallToAction/CallToAction";
import { FJD_OZG_CONTACT_MAILTO } from "../../../../common/contact";

interface ModalProps {
  isOpen: boolean;
  handleModal: () => void;
}

export function NachnutzungsModal({ isOpen, handleModal }: ModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={handleModal}
      closable
      width="wider"
      closeOnBackdropClick
      data={{
        test: "NachnutzungsModalHeader"
      }}
      heading={t("nachnutzungsModal.modalHeader")}
    >
      <Stack orientation="vertical" spacing="xxl">
        <Markdown className="header-description" source={t("nachnutzungsModal.modalHeaderDescription")} />
        <Stack className="model-container">
          <FjdColumns>
            {["A", "B", "C"].map((character, index) => (
              <Stack orientation="vertical" spacing="l" className="model-description" key={character}>
                <Markdown
                  className="model-variant"
                  source={t(`nachnutzungsModal.modelVariant`, {
                    stamp: character
                  })}
                />
                <Heading level={3} maxLines={2} text={t(`nachnutzungsModal.modelHeader${index + 1}`)} />
                <Markdown className="model-condition" source={t(`nachnutzungsModal.modelFirstCondition${index + 1}`)} />
                <Markdown
                  className="model-condition"
                  source={t(`nachnutzungsModal.modelSecondCondition${index + 1}`)}
                />
                <FlexSpacer />
                <Markdown source={t(`nachnutzungsModal.modelDescription${index + 1}`)} useParagraphs />
                <FlexSpacer />
              </Stack>
            ))}
          </FjdColumns>
          <FjdButton
            size="s"
            iconLeft="launch"
            label={t("nachnutzungsModal.detailsNachnutzungsmodelle")}
            appearance="primary-link"
            onClick={() => window.open(URL_NACHNUTZUNG_MODELS, "_blank", "noreferrer,noopener")}
          />
        </Stack>
        <FjdSpacer size="xs" />
        <CallToAction
          appeal={t("nachnutzungsModal.mktServicesNotAvailableHeader")}
          note={t(`nachnutzungsModal.mktServicesNotAvailableDescription`)}
          action={
            <FjdButton
              appearance="outline"
              label={t("nachnutzungsModal.buttonContact")}
              href={FJD_OZG_CONTACT_MAILTO}
            />
          }
        />
      </Stack>
    </Modal>
  );
}
