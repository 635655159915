import axios, { CreateAxiosDefaults } from "axios";

export const MARKETPLACE_URL_BASE = process.env.REACT_APP_MARKETPLACE_API_URL || "ENV_MARKETPLACE_API_URL";

const apiConfig: CreateAxiosDefaults = {
  baseURL: MARKETPLACE_URL_BASE.toString()
};

export const mktClient = axios.create(apiConfig);
export const unauthorizedMktClient = axios.create(apiConfig);
