import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Modal } from "../../../../../common/components/Modal/Modal";
import { RightOfUse } from "../../../../api/rightOfUse/RightOfUse";
import { KeyValueList } from "../../../../../common/components/KeyValueList/KeyValueList";
import { fetchOnlineServiceTemplateById } from "../../../../api/onlinedienste/onlineServiceTemplateApi";
import { OnlineServiceStatus } from "../../../../api/onlinedienste/OnlineServiceV2";
import { fetchArsDataById } from "../../../../api/catalog/catalogApi";

interface ModalProps {
  isModalVisible: boolean;
  rightOfUse: RightOfUse;
  onClose: () => void;
}

export function RightOfUseInformationModal({ isModalVisible, rightOfUse, onClose }: ModalProps) {
  const { t } = useTranslation();
  const { data: onlineService } = useQuery({
    queryFn: () => fetchOnlineServiceTemplateById(rightOfUse.onlineServiceId),
    queryKey: [rightOfUse.onlineServiceId],
    select: (template) =>
      template.versions
        .filter((version) => version.status === OnlineServiceStatus.Released || OnlineServiceStatus.WorkedOn)
        .shift()
  });

  const { data: arsData } = useQuery({
    queryKey: [fetchArsDataById.name, rightOfUse.regionalKey],
    queryFn: () => fetchArsDataById(rightOfUse.regionalKey)
  });

  return (
    <Modal
      open={isModalVisible}
      heading={t("rightsOfUse.actions.details.modalHeading", { kennung: rightOfUse.onlineServiceIdentifier })}
      onClose={onClose}
    >
      <KeyValueList heading="">
        <KeyValueList.Section sectionHeader={t("common.onlineService")}>
          <KeyValueList.ListItem term={t("common.title")} value={onlineService?.title ?? ""} />
          <KeyValueList.ListItem term={t("common.identifier")} value={rightOfUse.onlineServiceIdentifier} />
        </KeyValueList.Section>
        <KeyValueList.Section sectionHeader={t("rightsOfUse.areaOfUse")}>
          <KeyValueList.ListItem term={t("municipalDirectory.name")} value={arsData?.municipalName ?? "-"} />
          <KeyValueList.ListItem term={t("municipalDirectory.regionalKey")} value={arsData?.regionalKey ?? "-"} />
          <KeyValueList.ListItem term={t("municipalDirectory.zipCode")} value={arsData?.zipCode ?? "-"} />
          <KeyValueList.ListItem term={t("municipalDirectory.population")} value={arsData?.population ?? "-"} />
          <KeyValueList.ListItem
            term={t("municipalDirectory.textIndicator")}
            value={arsData?.textIndicatorDescription ?? "-"}
          />
        </KeyValueList.Section>
      </KeyValueList>
    </Modal>
  );
}
