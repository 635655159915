import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../../common/components/Modal/Modal";
import { Stack } from "../../../../common/components/Stack/Stack";
import "../OnlineServiceInformation/OnlineServiceInformationModal.css";
import { KeyValueList } from "../../../../common/components/KeyValueList/KeyValueList";
import { TabBar } from "../../../../common/components/TabBar/TabBar";
import { OzgRequestDetailsData } from "../OnlineServiceInformation/OnlineServiceInformationModal";

interface ModalProps {
  isOpen: boolean;
  handleModal: () => void;
  formular: OzgRequestDetailsData;
}

export function FormularInformationModal({ isOpen, handleModal, formular }: ModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      data-testid="OnlineServiceInformation"
      onClose={handleModal}
      closable
      width="wide"
      closeOnBackdropClick
      data={{
        test: "OnlineServiceInformation"
      }}
      heading={formular.serviceTitle}
    >
      <Stack orientation="vertical" spacing="4xl">
        <TabBar>
          <TabBar.Tab label={t("formularInformation.businessInformation")} index={0} id="businessInformation" />
          <TabBar.Tab label={t("formularInformation.features")} index={1} id="features" />
          <TabBar.Panel index={0}>
            <Stack orientation="vertical" spacing="xl">
              <KeyValueList>
                <KeyValueList.Section>
                  <KeyValueList.ListItem
                    term={t("formularInformation.businessInformationContent.state")}
                    value={formular?.state}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.businessInformationContent.section")}
                    value={formular?.topic}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.businessInformationContent.identifier")}
                    value={formular?.identifier}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.businessInformationContent.producer")}
                    value={t("formularInformation.businessInformationContent.producerContent")}
                  />
                </KeyValueList.Section>
              </KeyValueList>
            </Stack>
          </TabBar.Panel>
          <TabBar.Panel index={1}>
            <Stack orientation="vertical" spacing="xl">
              <KeyValueList>
                <KeyValueList.Section>
                  <KeyValueList.ListItem
                    term={t("formularInformation.featureContent.targetPlatform")}
                    value={t("formularInformation.featureContent.targetPlatformContent")}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.featureContent.availableFileTypes")}
                    value={t("formularInformation.featureContent.availableFileTypesContent")}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.featureContent.interfaces")}
                    value={t("formularInformation.featureContent.interfacesContent")}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.featureContent.legalSecurity")}
                    value={t("formularInformation.featureContent.legalSecurityContent")}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.featureContent.automaticUpdates")}
                    value={t("formularInformation.featureContent.automaticUpdatesContent")}
                  />
                  <KeyValueList.ListItem
                    term={t("formularInformation.featureContent.onTheFlyTransformation")}
                    value={t("formularInformation.featureContent.onTheFlyTransformationContent")}
                  />
                </KeyValueList.Section>
              </KeyValueList>
            </Stack>
          </TabBar.Panel>
        </TabBar>
      </Stack>
    </Modal>
  );
}
