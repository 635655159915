import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  FjdBackdrop,
  FjdButtonSelectGroup,
  FjdCheckbox,
  FjdFormControl,
  FjdIcon,
  FjdSpinner,
  FjdTextInput,
  FjdTooltip
} from "fjd-react-components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Modal } from "../../../../../common/components/Modal/Modal";
import { fetchClientSecret, updatePlatformById, uploadPlatformData } from "../../../../api/platforms/platformApi";
import { useNotification } from "../../../../../common/libs/notifications/hooks/useNotification";
import { Stack } from "../../../../../common/components/Stack/Stack";
import { Platform, PlatformEnvironment } from "../../../../api/platforms/Platform";
import "./CreateOrUpdatePlatformModal.css";
import { MktHidableTextInput } from "../../../../../common/components/HidableTextInput/HidableTextInput";

interface ModalProps {
  isModalVisible: boolean;
  handleModalClose: () => void;
  platform: Platform | null;
  type: "Add" | "Edit";
  environment: PlatformEnvironment;
  selectedOrganizationUuid: string;
}

export function CreateOrUpdatePlatformModal({
  isModalVisible,
  handleModalClose,
  platform,
  type,
  environment,
  selectedOrganizationUuid
}: ModalProps) {
  const { t } = useTranslation();
  const { notifyUserFromResponse, notifyUser } = useNotification();
  const [platformFormular, setPlatformFormular] = useState<Platform>(
    platform ?? { id: "", environment, name: "", operatorId: "" }
  );

  const { mutate: platformUpdateById } = useMutation({
    mutationKey: [updatePlatformById.name],
    mutationFn: updatePlatformById,
    onSuccess: handleModalClose,
    onSettled: notifyUserFromResponse({
      successMessage: t("platform.successMessage.platformEdited")
    })
  });

  const { mutate: uploadPlatformState } = useMutation({
    mutationKey: [uploadPlatformData.name],
    mutationFn: uploadPlatformData,
    onSuccess: handleModalClose,
    onSettled: notifyUserFromResponse({
      successMessage: t("platform.successMessage.platformAdded")
    })
  });

  const handleClickOnCreateOrUpdatePlatform = () => {
    if (platformFormular?.name.length === 0) {
      notifyUser({ title: t("platform.errorMessage.noPlatformName"), intent: "error" });
      return;
    }
    if (type === "Edit") {
      platformUpdateById(platformFormular);
    } else {
      uploadPlatformState({ ...platformFormular, operatorId: selectedOrganizationUuid });
    }
  };

  const { data: thePlatformSecret, isFetching: isFetchinPlatformSecret } = useQuery({
    queryKey: ["client-secret-edit-modal", platform?.id],
    queryFn: () => fetchClientSecret({ platformId: platform?.id ?? "" }),
    onSettled: notifyUserFromResponse({
      errorMessage: t("platform.errorMessage.fetchSecretFailed")
    }),
    enabled: !!platform?.id,
    staleTime: 5000
  });

  return (
    <Modal
      closable
      onClose={handleModalClose}
      open={isModalVisible}
      closeOnBackdropClick
      appElement={document.getElementById("root") ?? document.body}
      heading={
        type === "Edit"
          ? t("platform.modalEditPlatform.headerAddPlatform", { name: platformFormular?.name ?? "-" })
          : t("platform.modalAddPlatform.headerAddPlatform")
      }
      primaryButtonLabel={t(`platform.modal${type}Platform.savePlatform`)}
      onPrimaryButtonClick={handleClickOnCreateOrUpdatePlatform}
      secondaryButtonLabel={t(`platform.modal${type}Platform.abort`)}
      onSecondaryButtonClick={handleModalClose}
    >
      {isFetchinPlatformSecret ?? (
        <FjdBackdrop>
          <FjdSpinner size="m" />
        </FjdBackdrop>
      )}
      <Stack spacing="s" orientation="vertical">
        <Stack orientation="vertical" spacing="m">
          <FjdFormControl label={t(`platform.modal${type}Platform.platformName`)} inputId="platform_name">
            <FjdTextInput
              id="platform_name"
              testId="inputField_platformName"
              name="inputField_platformName"
              value={platformFormular?.name}
              onChange={(e) =>
                setPlatformFormular({
                  ...platformFormular,
                  name: e.target.value
                })
              }
            />
          </FjdFormControl>
          <FjdFormControl label={t(`platform.environment.title`)} inputId="platform_environment">
            <FjdButtonSelectGroup
              size="m"
              id="platformEnvironment"
              onChange={(checked) => {
                if (
                  checked.target.value === PlatformEnvironment.Prod ||
                  checked.target.value === PlatformEnvironment.Test
                ) {
                  setPlatformFormular({
                    ...platformFormular,
                    workingVersionsRequested:
                      checked.target.value === PlatformEnvironment.Prod
                        ? false
                        : platformFormular.workingVersionsRequested,
                    environment: checked.target.value
                  });
                }
              }}
              options={[
                {
                  text: t(`platform.environment.prod`),
                  value: "PROD",
                  selected: platformFormular?.environment === PlatformEnvironment.Prod
                },
                {
                  text: t(`platform.environment.test`),
                  value: "TEST",
                  selected: platformFormular?.environment === PlatformEnvironment.Test
                }
              ]}
            />
            <FjdTooltip
              key="tooltip"
              placement="top"
              tooltip={
                <span>
                  <Trans i18nKey="platform.environment.additionalInformation">
                    <strong />
                    <br />
                  </Trans>
                </span>
              }
            >
              <FjdIcon glyph="information-outline" size="m" appearance="primary" />
            </FjdTooltip>
          </FjdFormControl>
          {platformFormular?.environment === PlatformEnvironment.Test && (
            <FjdCheckbox
              id="shouldUseWorkingVersion"
              data-testid="shouldUseWorkingVersion"
              label={t(`platform.environment.shouldUseWorkingVersion`)}
              defaultChecked={!!platformFormular?.workingVersionsRequested}
              onChange={(e) => {
                setPlatformFormular({ ...platformFormular, workingVersionsRequested: e.target.checked });
              }}
            />
          )}
          <FjdFormControl
            optional
            label={t(`platform.modal${type}Platform.platformDescription`)}
            inputId="platform_description"
          >
            <FjdTextInput
              id="platform_description"
              testId="textArea_platformDescription"
              label="Test"
              max={5}
              name="textArea_platformDescription"
              rows={3}
              value={platformFormular?.description}
              onChange={(e) =>
                setPlatformFormular({
                  ...platformFormular,
                  description: e.target.value
                })
              }
            />
          </FjdFormControl>
          {type === "Edit" && (
            <Stack orientation="horizontal" className="create-platform-modal__platform-credentials">
              <FjdFormControl label={t(`platform.modal${type}Platform.clientId`)} inputId="platform_clientId">
                <FjdTextInput
                  id="inputField_clientId"
                  testId="inputField_clientId"
                  value={platformFormular?.clientId}
                  disabled
                />
              </FjdFormControl>
              <FjdFormControl label={t(`platform.modal${type}Platform.clientSecret`)} inputId="platform_clientSecret">
                <MktHidableTextInput label="platform_clientSecret" actualValue={thePlatformSecret?.data.clientSecret} />
              </FjdFormControl>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
}
