import { StringMap } from "../../utils/StringMap";

// Currently it is not possible to explicitly provide a type that allows only string values
// https://stackoverflow.com/questions/37006008/typescript-index-signature-is-missing-in-type
export type SearchableContentType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const findUniqueFilterBadges = <T extends SearchableContentType>(data: T[], filter: keyof T) =>
  data
    .map((entry) => entry[filter] as string)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((entry) => entry);

export const findAllAvailableFilter = <T extends SearchableContentType>(data: T[], columnFilters: (keyof T)[]) =>
  columnFilters.reduce(
    (previousFilter, currentFilter) => ({
      ...previousFilter,
      [currentFilter]: findUniqueFilterBadges(data, currentFilter)
    }),
    {} as StringMap<T>
  );

const applyActivatedFilterToSearchResults =
  <T extends SearchableContentType>(activatedFilter: StringMap<T>) =>
  (entry: T): boolean => {
    if (Object.values(activatedFilter).flatMap((activeFilter) => activeFilter).length === 0) return true;
    return Object.entries(activatedFilter).reduce((previousFilter, currentFilter) => {
      const doesKeyOfCurrentFilterExistsInDataEntry = Object.hasOwn(entry, currentFilter[0]);
      const isCurrentFilterValueEmpty = currentFilter[1].length === 0;
      if (isCurrentFilterValueEmpty) return true;
      if (!doesKeyOfCurrentFilterExistsInDataEntry) return true;

      const isCurrentFilterInDataEntryIncluded = currentFilter[1].includes(entry[currentFilter[0] as keyof T]);
      return previousFilter && isCurrentFilterInDataEntryIncluded;
    }, true);
  };

const searchMultipleColumns =
  <T extends SearchableContentType>(searchInput: string, searchColumns: (keyof T)[]) =>
  (entry: T) =>
    Object.entries(entry)
      .filter(([key]) => searchColumns.includes(key as keyof T))
      .filter(([, value]: [string, string | undefined]) => (value ?? "").toLowerCase().includes(searchInput)).length >
    0;

export const excludeActivatedFiltersFromFoundFilters = <T extends SearchableContentType>(
  activatedFilter: StringMap<T> | null,
  foundFilters: StringMap<T>
) => {
  if (!activatedFilter) {
    return foundFilters;
  }

  return Object.entries(foundFilters).reduce(
    (previousFilter, currentFilter) => ({
      ...previousFilter,
      [currentFilter[0]]: currentFilter[1].filter(
        (foundFilter) =>
          Object.values(activatedFilter)
            .flatMap((entry) => entry)
            .filter((activeFilter) => activeFilter.includes(foundFilter)).length === 0
      )
    }),
    {} as StringMap<T>
  );
};

export const searchForAvailableFilter = <T extends SearchableContentType>(
  searchInput: string,
  availableFilter: StringMap<T>
) =>
  Object.entries(availableFilter).reduce(
    (previousFilter, [filterTopic, filter]) => ({
      ...previousFilter,
      [filterTopic]: filter.filter((entry) => entry.toLowerCase().includes(searchInput))
    }),
    {} as StringMap<T>
  );

export const executeSearch = <T extends SearchableContentType>(
  data: T[],
  searchInput: string,
  filterTopics: (keyof T)[],
  activatedFilter: StringMap<T> | null
) => {
  const searchInputLowerCase = searchInput.toLowerCase();

  if (searchInput.length === 0 && !activatedFilter) return data;

  let searchResults: T[] = data;
  if (activatedFilter) {
    searchResults = searchResults.filter(applyActivatedFilterToSearchResults(activatedFilter));
  }
  if (searchInput.length !== 0) {
    searchResults = searchResults.filter(searchMultipleColumns(searchInputLowerCase, filterTopics));
  }

  return searchResults;
};
