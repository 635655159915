import { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { mktClient } from "../../axiosClient";

export function useMarketplaceAuthentication() {
  const auth = useAuth();

  const login = () => {
    auth.signinRedirect().catch(() => {});
  };
  const logout = () => {
    auth
      .signoutRedirect({ redirectMethod: "replace", post_logout_redirect_uri: `${window.location.origin}` })
      .then(() => {
        auth.stopSilentRenew();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        auth.removeUser();
        mktClient.defaults.headers.common.Authorization = "";
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (auth.user?.access_token) {
      mktClient.defaults.headers.common.Authorization = `Bearer ${auth.user?.access_token}`;
    }
  }, [auth.user?.access_token]);

  return {
    login,
    logout,
    userName: auth.user?.profile.name ?? "",
    isAuthenticated: auth.isAuthenticated,
    isLoading: auth.isLoading
  };
}
