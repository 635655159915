import { mktClient } from "../../../common/libs/axiosClient";

export interface AppAccessProps {
  producerId: string;
  clientId: string;
}

const PRODUCER_APP_ACCESS_URL_TEMPLATE = (producerId: string) => `/producer/${producerId}/app-access`;
const SPECIFIC_PRODUCER_APP_ACCESS_URL_TEMPLATE = (props: AppAccessProps) =>
  `/producer/${props.producerId}/app-access/${props.clientId}`;
const PRODUCER_APP_ACCESS_CREDENTIALS_URL_TEMPLATE = (props: AppAccessProps) =>
  `${SPECIFIC_PRODUCER_APP_ACCESS_URL_TEMPLATE({
    producerId: props.producerId,
    clientId: props.clientId
  })}/credentials`;

export interface ProducerAppAccessView {
  name: string;
  clientId: string;
  producerId: string;
  updatedAt: number;
  createdAt: number;
  createdBy: string;
  lastModifiedBy: string;
}

export interface ProducerAppAccessCredentialView {
  clientId: string;
  clientSecret: string;
}

export interface CreateAppAccessRequest {
  name: string;
  producerId: string;
}

export interface UpdateAppAccessRequest extends CreateAppAccessRequest {
  clientId: string;
}

export interface DeleteAppAccessRequest {
  producerId: string;
  clientId: string;
}

export const createOrUpdateAppAccess = (command: CreateAppAccessRequest | UpdateAppAccessRequest) => {
  if ("clientId" in command && command.clientId !== undefined) {
    return mktClient.put(
      SPECIFIC_PRODUCER_APP_ACCESS_URL_TEMPLATE({ producerId: command.producerId, clientId: command.clientId }),
      { name: command.name }
    );
  }
  return mktClient.post(PRODUCER_APP_ACCESS_URL_TEMPLATE(command.producerId), { name: command.name });
};

export const deleteAppAccess = (command: DeleteAppAccessRequest) =>
  mktClient.delete(
    SPECIFIC_PRODUCER_APP_ACCESS_URL_TEMPLATE({ producerId: command.producerId, clientId: command.clientId })
  );

export const listAppAccess = (producerId: string) =>
  mktClient
    .get<ProducerAppAccessView[]>(PRODUCER_APP_ACCESS_URL_TEMPLATE(producerId))
    .then((response) => response.data);

export const appAccessCredentials = (props: AppAccessProps) =>
  mktClient
    .get<ProducerAppAccessCredentialView>(
      PRODUCER_APP_ACCESS_CREDENTIALS_URL_TEMPLATE({ producerId: props.producerId, clientId: props.clientId })
    )
    .then((response) => response.data);

export const regenerateAppAccessCredentials = (props: AppAccessProps) =>
  mktClient
    .post<ProducerAppAccessCredentialView>(
      PRODUCER_APP_ACCESS_CREDENTIALS_URL_TEMPLATE({ producerId: props.producerId, clientId: props.clientId })
    )
    .then((response) => response.data);
