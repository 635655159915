import { FjdCard, FjdFlyout, FjdNavigationBarItem, FjdStack } from "fjd-react-components";
import React from "react";
import { FjdIconGlyph } from "fjd-react-components/build/components/Icon/Icon";

interface DropdownProps {
  children?: React.ReactNode;
  label: string;
  icon?: FjdIconGlyph;
  className?: string;
}

export function Dropdown({ children, label, icon, className }: DropdownProps) {
  return (
    <div className={className}>
      <FjdFlyout
        flyout={
          <FjdCard elevated solid>
            <FjdStack spacing="s">{children}</FjdStack>
          </FjdCard>
        }
        offset={[16, 8]}
        placement="bottom-start"
      >
        <FjdNavigationBarItem highlighted iconRight={icon} label={label} />
      </FjdFlyout>
    </div>
  );
}
