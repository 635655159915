import React from "react";
import { FjdNavigationBar, FjdNavigationBarItem } from "fjd-react-components";
import { Dropdown } from "../Dropdown/Dropdown";

interface NavbarProps {
  children: React.ReactNode;
}

function NavigationsBar({ children }: NavbarProps) {
  return <FjdNavigationBar>{children}</FjdNavigationBar>;
}

NavigationsBar.Dropdown = Dropdown;
NavigationsBar.NavbarItem = FjdNavigationBarItem;

export const Navbar = NavigationsBar;
