export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function capitalize(stringToCapitalize: string | undefined): string | undefined {
  if (stringToCapitalize === undefined) return stringToCapitalize;
  const tail = stringToCapitalize.toLowerCase().slice(1);
  const capitalLetter = stringToCapitalize.slice(0, 1).toUpperCase();
  return `${capitalLetter}${tail}`;
}

export function removeAllWhitespacesFrom(s: string) {
  return s.replace(/\s/g, "");
}

export interface NestedStringObject {
  [key: string]: unknown | undefined | NestedStringObject;
}

export function makeEmptyStringsUndefined(obj: unknown): unknown {
  if (obj === null) return obj;
  if (typeof obj === "undefined") return obj;
  if (Array.isArray(obj)) {
    return obj
      .filter((element) => {
        if (element === null || element === undefined) {
          return false;
        }
        if (typeof element === "string") {
          return element.replaceAll(" ", "").length !== 0;
        }
        return true;
      })
      .map((e) => {
        if (typeof e === "object") {
          return makeEmptyStringsUndefined(e);
        }
        return e as unknown;
      });
  }
  const newObj: NestedStringObject = {};
  if (obj && typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      const value = obj[key as keyof typeof obj];
      if (typeof value === "object") {
        newObj[key as keyof typeof obj] = makeEmptyStringsUndefined(value);
      } else if (typeof value === "string" && (value as string).trim() === "") {
        newObj[key as keyof typeof obj] = undefined;
      } else {
        newObj[key as keyof typeof obj] = value;
      }
    });
  }
  return newObj;
}

export async function copyTextToClipboard(theText: string) {
  if ("clipboard" in navigator) {
    return navigator.clipboard.writeText(theText);
  }
  return document.execCommand("copy", true, theText);
}

export function hasOnlyNumbers(str: string) {
  return /^\d+$/.test(str);
}
