/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */

import React from "react";
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ReactRouterError {
  status?: number;
  statusText?: string;
  message?: string;
}

export function ErrorPage() {
  const error = useRouteError() as ReactRouterError;
  const { t } = useTranslation();

  // eslint-disable-next-line no-console
  console.error(error.statusText || error.message);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>{t("error.unhandled.message")}</p>
    </div>
  );
}
