import { FjdButton, FjdIcon, FjdSectionHeader } from "fjd-react-components";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FlexSpacer } from "../../../../common/components/FlexSpacer/FlexSpacer";
import { Heading } from "../../../../common/components/Heading/Heading";
import { Stack } from "../../../../common/components/Stack/Stack";
import { MaintainPlatformModal } from "./Dialogs/MaintainPlatformModal";
import { MktTable, RowProps } from "../../../../common/components/Table/Table";
import { RightOfPlatformAssignmentView, RightOfUse } from "../../../api/rightOfUse/RightOfUse";
import {
  deleteProducerRightOfPlatformAssignment,
  fetchRightsOfUse,
  getProducerRightsOfPlatformAssignment
} from "../../../api/rightOfUse/rightOfUseApi";
import { UserContext } from "../../../../common/libs/authentication/context/UserContext";
import { CallToAction } from "../../../../common/components/CallToAction/CallToAction";
import { AssignPlatformToRightOfUseModal } from "./Dialogs/AssignPlatformToRightOfUseModal";
import { EditRightOfUsePlatformAssignmentModal } from "./Dialogs/EditRightOfUsePlatformAssignmentModal";
import { RightOfUseInformationModal } from "./Dialogs/RightOfUseInformationModal";
import { useAfterUseModelMap, usePricingModelMap } from "../../../api/rightOfUse/RightOfUsePropertyMap";
import { ProducerRightOfPlatformAssignmentModal } from "./Dialogs/ProducerRightOfPlatformAssignmentModal";
import { useNotification } from "../../../../common/libs/notifications/hooks/useNotification";

type RightOfUseModal = "managePlatform" | "assignPlatform";

const icon = () => <FjdIcon glyph="arrow-right" />;

export function RightsOfUsePage() {
  const [isProducerAssignmentRightsModalOpen, setIsProducerAssignmentRightsModalOpen] = useState<boolean>(false);
  const [isManagePlatformModalVisible, setIsManagePlatformModalVisible] = useState<RightOfUseModal | null>(null);
  const [rightOfUseToEdit, setRightOfUseToEdit] = useState<RightOfUse | null>(null);
  const { notifyUserFromResponse } = useNotification();

  const { t } = useTranslation();
  const { selectedOrganizationId } = useContext(UserContext);
  const [rightOfUseSelected, selectRightOfUse] = useState<RightOfUse | null>(null);
  const { mapper: pricingModelMapper } = usePricingModelMap();
  const { mapper: afterUseModelMapper } = useAfterUseModelMap();

  const { data: rightsOfUse, refetch: refetchRightsOfUse } = useQuery<RightOfUse[], AxiosError>({
    queryKey: [fetchRightsOfUse.name, selectedOrganizationId],
    queryFn: () => fetchRightsOfUse({ operatorId: selectedOrganizationId ?? undefined }),
    enabled: !!selectedOrganizationId
  });

  const { data: rightsOfPlatformAssignment, refetch: refetchRightsOfPlatformAssignments } = useQuery({
    queryFn: () => getProducerRightsOfPlatformAssignment(selectedOrganizationId),
    queryKey: [getProducerRightsOfPlatformAssignment.name, selectedOrganizationId],
    enabled: !!selectedOrganizationId
  });

  const { mutate: deleteRightOfPlatformAssignment } = useMutation({
    mutationKey: [deleteProducerRightOfPlatformAssignment.name],
    mutationFn: deleteProducerRightOfPlatformAssignment,
    onSettled: notifyUserFromResponse(),
    onSuccess: () => refetchRightsOfPlatformAssignments()
  });

  const handleModalClose = () => {
    setIsManagePlatformModalVisible(null);
    refetchRightsOfUse().catch(() => {});
  };

  const menuButton = (row: RowProps<RightOfUse>) => (
    <MktTable.MenuButton
      identifier={row.row.original.id}
      menuItems={[
        {
          testId: `rou-action-edit-${row.row.original.id}`,
          icon: "edit",
          label: t("rightsOfUse.actions.editPlatformAssignment.buttonLabel"),
          hidden: row.row.original.platformId === undefined || row.row.original.platformId === null,
          onClick: () => setRightOfUseToEdit(row.row.original)
        },
        {
          testId: `rou-action-show-details-${row.row.original.id}`,
          icon: "information-outline",
          label: t("rightsOfUse.actions.details.buttonLabel"),
          onClick: () => selectRightOfUse(row.row.original)
        }
      ]}
    />
  );

  const automaticAssignmentMenuButton = (row: RowProps<RightOfPlatformAssignmentView>) => (
    <MktTable.MenuButton
      identifier={`${row.row.original.platformId}-${row.row.original.producerId}`}
      menuItems={[
        {
          testId: `autoAssign-${row.row.original.platformId}-${row.row.original.producerId}`,
          icon: "trashcan",
          label: t("löschen"),
          onClick: () => {
            deleteRightOfPlatformAssignment(row.row.original);
          }
        }
      ]}
    />
  );

  return (
    <>
      <Stack spacing="5xl">
        <Stack orientation="horizontal">
          <FjdSectionHeader
            heading={<Heading level={1} text={t("rightsOfUse.automaticPlatformAssignmentForProducer")} />}
          />
          <FlexSpacer />
          <FjdButton
            onClick={() => setIsProducerAssignmentRightsModalOpen(true)}
            appearance="outline"
            iconLeft="edit"
            label={t("rightsOfUse.addButtonRightForAutomaticAssignment")}
            size="m"
            testId="button-open-manageplatforms-modal"
          />
        </Stack>
        <MktTable
          data={rightsOfPlatformAssignment ?? []}
          enableGlobalFilter={false}
          clickableRows={false}
          columns={[
            {
              Header: t("common.producer"),
              accessor: "producerName"
            },
            {
              Header: "",
              accessor: " ",
              Cell: icon
            },
            {
              Header: t("common.platform"),
              accessor: "platformName"
            },
            {
              Header: t("common.afterUseModel"),
              accessor: (row: RightOfPlatformAssignmentView) => afterUseModelMapper(row.afterUseModel)
            },
            {
              Header: t("common.pricingModel"),
              accessor: (row: RightOfPlatformAssignmentView) => pricingModelMapper(row.pricingModel)
            },
            {
              Header: "",
              accessor: "id",
              Cell: automaticAssignmentMenuButton
            }
          ]}
        />
        <Stack orientation="horizontal">
          <FjdSectionHeader heading={<Heading level={1} text={t("platform.manageUsageRights")} />} />
          <FlexSpacer />
          <FjdButton
            onClick={() => setIsManagePlatformModalVisible("managePlatform")}
            appearance="outline"
            iconLeft="edit"
            label={t("platform.platforms")}
            size="m"
            testId="button-open-manageplatforms-modal"
          />
        </Stack>
        {rightsOfUse?.some((rightOfUse) => (rightOfUse?.platformName?.length ?? 0) === 0) && (
          <CallToAction
            appeal={t("rightsOfUse.platform.callToAction.headline", {
              notAssignedAmount:
                rightsOfUse?.filter((rightOfUse) => (rightOfUse.platformName?.length ?? 0) === 0)?.length ?? 0
            })}
            note={t("rightsOfUse.platform.callToAction.note")}
            action={
              <FjdButton
                label={t("rightsOfUse.platform.callToAction.buttonLabel")}
                onClick={() => setIsManagePlatformModalVisible("assignPlatform")}
              />
            }
          />
        )}
        {rightsOfUse && (
          <MktTable
            data={rightsOfUse}
            className="RightOfUseTable"
            clickableRows={false}
            enableColumnFilter
            columns={[
              {
                Header: `${t("rightsOfUse.table.identifier")}`,
                accessor: "onlineServiceIdentifier"
              },
              {
                Header: `${t("rightsOfUse.table.area")}`,
                accessor: "rightOfUseArea"
              },
              {
                Header: `${t("rightsOfUse.table.platform")}`,
                accessor: "platformName"
              },
              {
                Header: `${t("rightsOfUse.table.pricingModel")}`,
                accessor: (row: RightOfUse) => pricingModelMapper(row.pricingModel)
              },
              {
                Header: `${t("rightsOfUse.table.afterUseModel")}`,
                accessor: (row: RightOfUse) => afterUseModelMapper(row.afterUseModel)
              },
              {
                Header: "",
                accessor: "_id",
                Cell: menuButton
              }
            ]}
          />
        )}
      </Stack>

      {isManagePlatformModalVisible === "managePlatform" && (
        <MaintainPlatformModal isModalVisible handleModalClose={handleModalClose} />
      )}
      {isManagePlatformModalVisible === "assignPlatform" && (
        <AssignPlatformToRightOfUseModal isModalVisible handleModalClose={handleModalClose} />
      )}
      {rightOfUseToEdit && (
        <EditRightOfUsePlatformAssignmentModal
          isModalVisible
          handleModalClose={() => {
            setRightOfUseToEdit(null);
            refetchRightsOfUse().catch(() => {});
          }}
          rightOfUse={rightOfUseToEdit}
        />
      )}
      {rightOfUseSelected && (
        <RightOfUseInformationModal
          rightOfUse={rightOfUseSelected}
          isModalVisible
          onClose={() => selectRightOfUse(null)}
        />
      )}
      {isProducerAssignmentRightsModalOpen && (
        <ProducerRightOfPlatformAssignmentModal
          onClose={() => setIsProducerAssignmentRightsModalOpen(false)}
          onSuccess={() => {
            refetchRightsOfPlatformAssignments().catch(() => {});
          }}
        />
      )}
    </>
  );
}
