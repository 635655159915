import React from "react";
import "./CircledCharacter.css";

interface CircledCharacterProps {
  character: string;
  size: number;
  bold?: boolean;
}

export function CircledCharacter({ character, size, bold }: CircledCharacterProps) {
  const sizeInRem = `${2.1 * size}rem`;
  return (
    <div
      className="circled-character"
      style={{
        fontSize: `${size}rem`,
        borderRadius: sizeInRem,
        width: sizeInRem,
        height: sizeInRem,
        borderWidth: `${0.09 * size}rem`,
        fontWeight: bold ? "bold" : "normal"
      }}
    >
      {character}
    </div>
  );
}
