import { matchPath, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { FjdBackdrop, FjdButton, FjdSpinner } from "fjd-react-components";
import { useTranslation } from "react-i18next";
import { FjdIconGlyph } from "fjd-react-components/build/components/Icon/Icon";
import { Navbar } from "../../common/components/Navbar/Navbar";
import { useMarketplaceAuthentication } from "../../common/libs/authentication/hooks/useMarketplaceAuthentication";
import { UserContext } from "../../common/libs/authentication/context/UserContext";
import {
  BEHOERDEN_INDEX_PATH,
  BETREIBER_INDEX_PATH,
  CATALOG_OF_PRODUCER_PATH,
  HERSTELLER_INDEX_PATH,
  ONLINE_SERVICES_OF_PRODUCER_PATH,
  ONLINE_SERVICES_PATH,
  RIGHTS_OF_USE_OF_OPERATOR_PATH,
  RIGHTS_OF_USE_OF_PRODUCER_PATH,
  RIGHTS_OF_USE_PATH,
  APPS_CONFIGURATION_OF_PRODUCER_PATH
} from "../Routes";
import { BEHOERDE, BETREIBER, HERSTELLER, OrganizationRole } from "../../common/libs/authentication/types/Organization";
import { capitalizeFirstLetter } from "../../common/utils/StringUtils";

interface NavigationBarItemWithRole {
  label: string;
  path: (orgId?: string) => string;
  icon: FjdIconGlyph;
}

const herstellerNavigation: NavigationBarItemWithRole[] = [
  {
    label: "navigation.onlineServices",
    path: (orgId) => ONLINE_SERVICES_OF_PRODUCER_PATH(orgId),
    icon: "list"
  },
  {
    label: "navigation.rightsOfUse",
    path: (orgId) => RIGHTS_OF_USE_OF_PRODUCER_PATH(orgId),
    icon: "virtual-column-key"
  },
  { label: "navigation.catalogs", path: (orgId) => CATALOG_OF_PRODUCER_PATH(orgId), icon: "folder" },
  {
    label: "navigation.appsConfiguration",
    path: (orgId) => APPS_CONFIGURATION_OF_PRODUCER_PATH(orgId),
    icon: "settings"
  }
];
const behoerdenNavigation: NavigationBarItemWithRole[] = [];
const betreiberNavigation: NavigationBarItemWithRole[] = [
  {
    label: "navigation.useofrights",
    path: (orgId) => RIGHTS_OF_USE_OF_OPERATOR_PATH(orgId),
    icon: "virtual-column-key"
  }
];

interface ProtectedLayoutProps {
  fallbackRoute?: string;
}

export function ProtectedLayout({ fallbackRoute }: ProtectedLayoutProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, isLoading } = useMarketplaceAuthentication();
  const { setSelectedOrganization, availableOrganizations, selectedOrganization } = useContext(UserContext);
  const [navigationBar, setNavigationBar] = useState<NavigationBarItemWithRole[] | null>(null);

  function navigateBasedOnSelectedRole(role: OrganizationRole, uuid: string) {
    if (role.replace("ö", "oe") === BEHOERDE) navigate(BEHOERDEN_INDEX_PATH(uuid), { relative: "route" });
    if (role === BETREIBER) navigate(`${BETREIBER_INDEX_PATH(uuid)}${RIGHTS_OF_USE_PATH}`, { relative: "route" });
    if (role === HERSTELLER) navigate(`${HERSTELLER_INDEX_PATH(uuid)}${ONLINE_SERVICES_PATH}`, { relative: "route" });
  }

  function buildNavigationBasedOnSelectedRole(role: OrganizationRole) {
    if (role === BEHOERDE) return behoerdenNavigation;
    if (role === BETREIBER) return betreiberNavigation;
    if (role === HERSTELLER) return herstellerNavigation;
    return [];
  }

  const onOrganizationSelected = (role: OrganizationRole, name: string, uuid: string) => () => {
    setSelectedOrganization({ role, name, uuid });
    navigateBasedOnSelectedRole(role, uuid);
    setNavigationBar(buildNavigationBasedOnSelectedRole(role));
  };

  useEffect(() => {
    if (selectedOrganization && navigationBar === null) {
      setNavigationBar(buildNavigationBasedOnSelectedRole(selectedOrganization.role));
    }
  }, [navigationBar, selectedOrganization]);

  // Condition checks if customer is logged in or if he is trying to log in
  if (!isAuthenticated && !isLoading) {
    return <Navigate to={fallbackRoute ?? "/"} replace />;
  }

  if (isLoading) {
    return (
      <FjdBackdrop>
        <FjdSpinner />
      </FjdBackdrop>
    );
  }

  return (
    <>
      <Navbar>
        <Navbar.Dropdown
          label={capitalizeFirstLetter(
            selectedOrganization
              ? `${t("protectedLayout.".concat(selectedOrganization.role))} ${selectedOrganization?.name ?? ""}`
              : t("protectedLayout.choose")
          )}
          icon="caret-down"
        >
          {availableOrganizations !== null
            ? Object.entries(availableOrganizations).flatMap(([uuid, organization]) =>
                organization.roles.map((role) => (
                  <FjdButton
                    key={role}
                    appearance="primary-link"
                    label={capitalizeFirstLetter(`${t("protectedLayout.".concat(role))} ${organization.name}`)}
                    onClick={onOrganizationSelected(role, organization.name, uuid)}
                  />
                ))
              )
            : null}
        </Navbar.Dropdown>
        {navigationBar?.map((navbarElement) => (
          <Navbar.NavbarItem
            key={navbarElement.label}
            active={
              matchPath(
                {
                  caseSensitive: false,
                  path: navbarElement.path()
                },
                location.pathname
              ) !== null
            }
            onClick={() => navigate(navbarElement.path(selectedOrganization?.uuid))}
            label={t(navbarElement.label)}
            icon={navbarElement.icon}
          />
        ))}
      </Navbar>
      <Outlet />
    </>
  );
}
