import { MARKETPLACE_URL_BASE, mktClient } from "../../../common/libs/axiosClient";
import { downloadFileViaContentDisposition } from "../../../common/utils/HttpUtils";
import {
  Catalog,
  CatalogApiPathType,
  LeikaAndEntityCollection,
  CatalogFileMetadata,
  OzgEntity,
  MunicipalDirectoryRecord
} from "./Catalog";

export const CATALOG_URL = `${MARKETPLACE_URL_BASE}/catalog`;
export const CATALOG_METADATA_URL = `${CATALOG_URL}/metadata`;
export const CATALOG_OZG_DATA_URL = `${CATALOG_URL}/ozg`;
export const CATALOG_LEIKA_DATA_URL = `${CATALOG_URL}/leika`;

export const MUNICIPAL_DIRECTORY_URL = `/municipal-directory`;
export const MUNICIPAL_DIRECTORY_RECORD_BY_ID_URL = (id: string) => `${MUNICIPAL_DIRECTORY_URL}/${id}`;

const uploadMultiPartFile = (files: Record<string, File>) => {
  const data = new FormData();
  Object.entries(files).forEach(([name, file]) => data.append(name, file));
  return mktClient.post<CatalogFileMetadata>(CATALOG_URL.toString(), data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*"
    }
  });
};
export const fetchOnlineServicesMetadata = () =>
  mktClient
    .get<Record<Catalog, CatalogFileMetadata>>(CATALOG_METADATA_URL.toString())
    .then<Record<Catalog, CatalogFileMetadata>>((response) => response.data);
export const uploadCatalogFile = uploadMultiPartFile;

export const downloadCatalog = (fileType: CatalogApiPathType) =>
  mktClient.get<Blob>(`${CATALOG_URL}/${fileType}`, { responseType: "blob" }).then(downloadFileViaContentDisposition);

export const fetchOzgData = (ozgId: string) =>
  mktClient
    .get<OzgEntity>(CATALOG_OZG_DATA_URL.toString(), {
      params: {
        ozgId
      }
    })
    .then((response) => response.data);

export const fetchLeikaData = (leikaIds: string) =>
  mktClient
    .get<LeikaAndEntityCollection>(CATALOG_LEIKA_DATA_URL.toString(), {
      params: {
        leikaIds
      }
    })
    .then((response) => response.data);

export const fetchArsData = () =>
  mktClient.get<MunicipalDirectoryRecord[]>(MUNICIPAL_DIRECTORY_URL.toString()).then((response) => response.data);

export const fetchArsDataById = (id: string) =>
  mktClient.get<MunicipalDirectoryRecord>(MUNICIPAL_DIRECTORY_RECORD_BY_ID_URL(id)).then((response) => response.data);
