/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { AuthProvider } from "react-oidc-context";
import { User, WebStorageStateStore } from "oidc-client-ts";
import config from "../../../../config.json";
import { REDIRECT_PATH } from "../../../../app/Routes";
import { OrganizationRole, Organizations, SelectedOrganization } from "../types/Organization";
import { parseJWT } from "../../../utils/jwtTokenUtils";
import { mktClient } from "../../axiosClient";

const AUTHORITY = process.env.REACT_APP_OAUTH2_REALM_URL || config.REACT_APP_OAUTH2_REALM_URL;
const CLIENT_ID = process.env.REACT_APP_OAUTH2_CLIENT_ID || config.REACT_APP_OAUTH2_CLIENT_ID;
export const OIDC_STORAGE_KEY = `oidc.user:${AUTHORITY}:${CLIENT_ID}`;
const localWebStorage = new WebStorageStateStore({ store: window.localStorage });

interface UserProviderProps {
  children: React.ReactNode;
}

export interface UserContextProps {
  availableOrganizations: Organizations | null;
  selectedOrganization: SelectedOrganization | null;
  selectedOrganizationId: string | null;
  setSelectedOrganization: Dispatch<SetStateAction<SelectedOrganization | null>>;
}

export const UserContext = createContext<UserContextProps>({
  availableOrganizations: null,
  selectedOrganization: null,
  selectedOrganizationId: null,
  setSelectedOrganization: () => {}
});

export function UserProvider({ children }: UserProviderProps) {
  const [availableOrganizations, setAvailableOrganizations] = useState<Organizations | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<SelectedOrganization | null>(null);

  const setSelectedOrganizationBasedOnUrl = (organizations: Organizations) => {
    Object.entries(organizations).flatMap(([uuid, organization]) => {
      const { name } = organization;
      const organizationRole = organization.roles.find((role: OrganizationRole) =>
        window.location.pathname.includes(role.replace("ö", "oe"))
      );
      if (organizationRole && window.location.pathname.includes(uuid)) {
        setSelectedOrganization({ role: organizationRole, name, uuid });
      }
      return null;
    });
  };

  const userContextApi = useMemo(
    () => ({
      availableOrganizations,
      selectedOrganization,
      selectedOrganizationId: selectedOrganization?.uuid ?? null,
      setSelectedOrganization
    }),
    [availableOrganizations, selectedOrganization]
  );

  const onSignin = (user: void | User) => {
    if (user instanceof User) {
      mktClient.defaults.headers.common.Authorization = `Bearer ${user.access_token}`;
      const { organizations } = parseJWT(user.access_token);
      setAvailableOrganizations(organizations);
      setSelectedOrganizationBasedOnUrl(organizations);
    }
  };

  useEffect(() => {
    const oidcStorage = localStorage.getItem(OIDC_STORAGE_KEY);
    if (oidcStorage) {
      onSignin(User.fromStorageString(oidcStorage));
    }
  }, []);

  return (
    <AuthProvider
      authority={AUTHORITY}
      client_id={CLIENT_ID}
      redirect_uri={`${window.location.origin}${REDIRECT_PATH}`}
      response_type="code"
      onSigninCallback={onSignin}
      automaticSilentRenew
      userStore={localWebStorage}
    >
      <UserContext.Provider value={userContextApi}>{children}</UserContext.Provider>
    </AuthProvider>
  );
}
