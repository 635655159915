import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { OnlineServicesV1 } from "../OnlineServicesV1";
import { fetchOnlineServices, fetchOnlineServicesV2 } from "../onlineDiensteApi";
import { FilterValues, OnlineServiceV2FlatendLeikaIds } from "../OnlineServiceV2";

export const useFetchOnlineDienste = (onSuccess?: (data: OnlineServicesV1[]) => void) =>
  useQuery<OnlineServicesV1[], AxiosError>({
    queryKey: [fetchOnlineServices.name],
    queryFn: fetchOnlineServices,
    onSuccess
  });

export const useFetchOnlineServicesV2 = (
  queryParams: FilterValues,
  onSuccess?: ((data: OnlineServiceV2FlatendLeikaIds[]) => void) | undefined
) =>
  useQuery({
    retryDelay: 5000,
    queryKey: [fetchOnlineServicesV2.name, queryParams],
    queryFn: () => fetchOnlineServicesV2(queryParams),
    staleTime: Infinity,
    select: (data) =>
      data.map<OnlineServiceV2FlatendLeikaIds>((onlineService) => ({
        ...onlineService,
        leikaIds: onlineService?.leikaIds?.join(", ") ?? null
      })),
    onSuccess
  });
