import React from "react";
import { useTranslation } from "react-i18next";
import "./NotFoundPage.css";
import { Stack } from "../../../common/components/Stack/Stack";

export function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <div id="not-found-page" className="not-found-page">
      <Stack>
        <h1>Ooops ...</h1>
        <h2>{t("error.notFound.message")}</h2>
      </Stack>
    </div>
  );
}
