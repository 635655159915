import React, { ReactElement } from "react";
import { FjdHeading, FjdMarkdown } from "fjd-react-components";
import "./CallToAction.css";
import { FjdButtonProps } from "fjd-react-components/build/components/Button/Button";
import { Stack } from "../Stack/Stack";

interface CallToActionProps {
  appeal: string;
  note?: string;
  action: ReactElement<FjdButtonProps<HTMLButtonElement>>;
}

export function CallToAction({ appeal, note, action }: CallToActionProps) {
  return (
    <Stack spacing="m" orientation="horizontal" className="call-to-action">
      <Stack className="call-to-action-button-alignemnt" orientation="vertical">
        <FjdHeading level={3} maxLines={2} text={appeal} />
        <FjdMarkdown source={note} useParagraphs />
      </Stack>
      {action}
    </Stack>
  );
}
