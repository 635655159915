import React from "react";
import { FjdButtonProps } from "fjd-react-components/build/components/Button/Button";
import { FjdButton } from "fjd-react-components";

interface ButtonProps<T = Element> extends FjdButtonProps<T> {
  className?: string;
}

export function Button({
  appearance,
  cursor,
  descriptiveLabel,
  disabled,
  hideLabel,
  href,
  id,
  iconLeft,
  iconRight,
  label,
  onClick,
  onMouseDown,
  onKeyDown,
  size,
  tabIndex,
  testId,
  type,
  role,
  className
}: ButtonProps) {
  return (
    <div className={className}>
      <FjdButton
        appearance={appearance}
        cursor={cursor}
        descriptiveLabel={descriptiveLabel}
        disabled={disabled}
        hideLabel={hideLabel}
        href={href}
        id={id}
        iconLeft={iconLeft}
        iconRight={iconRight}
        label={label}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onKeyDown={onKeyDown}
        size={size}
        tabIndex={tabIndex}
        testId={testId}
        type={type}
        role={role}
      />
    </div>
  );
}
