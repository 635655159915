import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FjdButtonSelectGroup, FjdCheckbox, FjdFormControl, FjdRadio } from "fjd-react-components";
import { Modal } from "../../../../../common/components/Modal/Modal";
import { UserContext } from "../../../../../common/libs/authentication/context/UserContext";
import { fetchPlatformAssignment, updatePlatformAssignment } from "../../../../api/rightOfUse/rightOfUseApi";
import { fetchPlatformData } from "../../../../api/platforms/platformApi";
import { AfterUseModel, PlatformAssignment, PricingModel, RightOfUse } from "../../../../api/rightOfUse/RightOfUse";
import { useNotification } from "../../../../../common/libs/notifications/hooks/useNotification";
import { PlatformEnvironment } from "../../../../api/platforms/Platform";
import { Stack } from "../../../../../common/components/Stack/Stack";
import { KeyValueList } from "../../../../../common/components/KeyValueList/KeyValueList";

interface ModalProps {
  isModalVisible: boolean;
  handleModalClose: () => void;
  rightOfUse: RightOfUse;
}

export function EditRightOfUsePlatformAssignmentModal({ isModalVisible, handleModalClose, rightOfUse }: ModalProps) {
  const [platformAssignment, setPlatformAssignment] = useState<PlatformAssignment | null>(null);

  const { t } = useTranslation();
  const { selectedOrganizationId } = useContext(UserContext);
  const { notifyUserFromResponse, notifyUser } = useNotification();

  useQuery({
    queryFn: () =>
      fetchPlatformAssignment({
        areaOfUseId: rightOfUse.areaOfUseId,
        rightOfUseId: rightOfUse.id,
        operatorId: selectedOrganizationId
      }),
    queryKey: [rightOfUse.areaOfUseId, rightOfUse.id, selectedOrganizationId],
    onSuccess: (data) =>
      setPlatformAssignment({
        platformId: data.platformId,
        afterUseModel: data.afterUseModel,
        pricingModel: data.pricingModel
      }),
    enabled: platformAssignment === null
  });

  const { data: fetchedPlatforms } = useQuery({
    queryKey: [fetchPlatformData.name],
    queryFn: fetchPlatformData,
    select: (data) =>
      data?.filter(
        (platform) =>
          platform?.operatorId === selectedOrganizationId && platform.environment === PlatformEnvironment.Prod
      ),
    enabled: platformAssignment === null
  });

  const { mutate: assignPlatform } = useMutation({
    mutationKey: [updatePlatformAssignment.name],
    mutationFn: updatePlatformAssignment,
    onSettled: notifyUserFromResponse({ successMessage: t("platform.assignPlatformModal.platformAssigned") }),
    onSuccess: handleModalClose
  });

  return (
    <Modal
      closable
      onClose={handleModalClose}
      open={isModalVisible}
      data-testid="assign-platform-modal"
      closeOnBackdropClick
      // heading=""
      heading={t("platform.assignPlatformModal.heading")}
      onSecondaryButtonClick={handleModalClose}
      secondaryButtonLabel={t("platform.assignPlatformModal.secondaryButton")}
      primaryButtonLabel={t("platform.assignPlatformModal.primaryButton")}
      onPrimaryButtonClick={() => {
        if (platformAssignment == null || platformAssignment.pricingModel === undefined) {
          notifyUser({ intent: "error", title: t("platform.assignPlatformModal.missingPricingModel") });
          return;
        }
        assignPlatform({
          areaOfUseId: rightOfUse.areaOfUseId,
          rightOfUseId: rightOfUse.id,
          operatorId: selectedOrganizationId,
          platformAssignment
        });
      }}
    >
      {platformAssignment === null ? (
        <div />
      ) : (
        <div>
          <Stack spacing="xl">
            <KeyValueList heading="">
              <KeyValueList.Section>
                <KeyValueList.ListItem term={t("rightsOfUse.areaOfUse")} value={rightOfUse.rightOfUseArea} />
                <KeyValueList.ListItem term={t("common.identifier")} value={rightOfUse.onlineServiceIdentifier} />
              </KeyValueList.Section>
            </KeyValueList>
            <FjdFormControl label={t("platform.assignPlatformModal.pricingModel")} testId="form-pricing-model">
              <FjdButtonSelectGroup
                size="m"
                id="form-pricingModel"
                onChange={(event) => {
                  if (
                    event.target.value === PricingModel.NOT_SPECIFIED ||
                    event.target.value === PricingModel.FREE ||
                    event.target.value === PricingModel.CHARGEABLE
                  )
                    setPlatformAssignment({ ...platformAssignment, pricingModel: event.target.value });
                }}
                options={[
                  {
                    text: t("platform.assignPlatformModal.pricingModelNotSpecified"),
                    value: PricingModel.NOT_SPECIFIED,
                    selected: platformAssignment?.pricingModel?.toString() === PricingModel.NOT_SPECIFIED.toString()
                  },
                  {
                    text: t("platform.assignPlatformModal.pricingModelFree"),
                    value: PricingModel.FREE,
                    selected: platformAssignment.pricingModel?.toString() === PricingModel.FREE.toString()
                  },
                  {
                    text: t("platform.assignPlatformModal.pricingModelChargeable"),
                    value: PricingModel.CHARGEABLE,
                    selected: platformAssignment?.pricingModel?.toString() === PricingModel.CHARGEABLE.toString()
                  }
                ]}
              />
            </FjdFormControl>
            <FjdFormControl label={t("platform.assignPlatformModal.afterUseModel")} testId="form-after-after-use-model">
              <FjdCheckbox
                id="afterUseModel"
                data-testid="form-afterUseModel-checkbox"
                label={t("platform.assignPlatformModal.efaModel")}
                defaultChecked={platformAssignment.afterUseModel === AfterUseModel.MODEL_A}
                onChange={(e) =>
                  setPlatformAssignment({
                    ...platformAssignment,
                    afterUseModel: e.target.checked ? AfterUseModel.MODEL_A : undefined
                  })
                }
              />
            </FjdFormControl>
            <FjdFormControl label={t("platform.assignPlatformModal.platform")} testId="form-platform-selection">
              {fetchedPlatforms && (
                <div className="fjd-form-control__inputs" style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {(fetchedPlatforms?.length ?? 0) > 0 &&
                    fetchedPlatforms.map((platform) => (
                      <FjdRadio
                        key={platform.name}
                        onChange={(e) => setPlatformAssignment({ ...platformAssignment, platformId: e.target.value })}
                        id={platform.name}
                        name="fetchedPlatforms"
                        label={platform.name}
                        value={platform.id}
                        defaultChecked={platformAssignment.platformId === platform.id}
                      />
                    ))}
                  <FjdRadio
                    key="no-platform"
                    onChange={() => setPlatformAssignment({ ...platformAssignment, platformId: undefined })}
                    id="no-platform"
                    name="fetchedPlatforms"
                    label={t("rightsOfUse.noPlatform")}
                    value="no-platform"
                  />
                </div>
              )}
            </FjdFormControl>
          </Stack>
        </div>
      )}
    </Modal>
  );
}
