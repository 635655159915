import React from "react";
import { useTranslation } from "react-i18next";
import { FjdBackdrop, FjdSpinner } from "fjd-react-components";
import { Modal } from "../Modal/Modal";

type FormPageProps = {
  children: React.ReactNode[] | React.ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  nextPageButtonLabel?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  previousPageButtonLabel?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  onPageRender?: () => Promise<unknown>;
  // eslint-disable-next-line react/no-unused-prop-types
  validate?: () => boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  testId?: string;
};

type FormPage = React.ReactElement<FormPageProps>;

function FormPageComponent({ children }: FormPageProps): FormPage {
  return <>{children}</>;
}

interface MultiPageFormState {
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  currentPageIndex: number;
}

interface ComponentProps {
  formHeading: string;
  defaultPageUpButtonLabel?: string;
  defaultPageDownButtonLabel?: string;
  abortButtonLabel?: string;
  confirmButtonLabel?: string;
  onClose: () => void;
  onConfirmed: () => void;
  children: FormPage[] | FormPage;
  isOpen: boolean;
}

type MultiPageFormProps = ComponentProps & {
  children: FormPage[] | FormPage;
  isBusy?: boolean;
  testId?: string;
};

function BusyPage() {
  return (
    <div className="mkt-modal-backdrop">
      <FjdBackdrop>
        <FjdSpinner size="m" />
      </FjdBackdrop>
    </div>
  );
}

function MultiPageFormComponent({
  formHeading,
  abortButtonLabel,
  confirmButtonLabel,
  onClose,
  onConfirmed,
  defaultPageUpButtonLabel,
  defaultPageDownButtonLabel,
  children,
  isOpen,
  isBusy = false,
  testId
}: MultiPageFormProps) {
  const { t } = useTranslation();
  const initialPageIndex = 0;
  const numberOfPages = Array.isArray(children) ? children.length : 1;
  const lastPageIndex = numberOfPages - 1;
  const theDefaultPageUpButtonLabel = defaultPageUpButtonLabel ?? t("commonComponents.multiPageForm.pageUpButtonLabel");
  const theDefaultPageDownButtonLabel =
    defaultPageDownButtonLabel ?? t("commonComponents.multiPageForm.pageDownButtonLabel");
  const theDefaultAbortButtonLabel = abortButtonLabel ?? t("commonComponents.multiPageForm.abortButtonLabel");
  const theDefaultConfirmButtonLabel = confirmButtonLabel ?? t("commonComponents.multiPageForm.confirmButtonLabel");
  const [formState, setFormState] = React.useState<MultiPageFormState>(computeNextDialogLayout(0));

  const closeForm = () => {
    setFormState(computeNextDialogLayout(0));
    onClose();
  };

  function getPage(pageIndex: number): FormPage {
    return Array.isArray(children) ? children[pageIndex] : children;
  }

  function getOnEntryFunctionFromPage(pageIndex: number): () => Promise<unknown> {
    return getPage(pageIndex).props.onPageRender ?? (() => new Promise(() => {}));
  }

  function computeNextDialogLayout(nextPage: number) {
    const currentPage = Array.isArray(children) ? children[nextPage] : children;
    const currentPageUpButtonLabel = currentPage.props.nextPageButtonLabel ?? theDefaultPageUpButtonLabel;
    const currentPageDownButtonLabel = currentPage.props.previousPageButtonLabel ?? theDefaultPageDownButtonLabel;
    const secondaryButtonLabel =
      nextPage === initialPageIndex ? theDefaultAbortButtonLabel : currentPageDownButtonLabel;
    const primaryButtonLabel = nextPage === lastPageIndex ? theDefaultConfirmButtonLabel : currentPageUpButtonLabel;
    return { currentPageIndex: nextPage, primaryButtonLabel, secondaryButtonLabel } as MultiPageFormState;
  }

  const pageUp = () => {
    const validationFunction = getPage(formState.currentPageIndex).props.validate ?? (() => true);
    if (validationFunction()) {
      setFormState((prevState) => {
        const newPage = Math.max(prevState.currentPageIndex + 1, initialPageIndex);
        getOnEntryFunctionFromPage(newPage)()
          .then(() => {})
          .catch(() => {});
        return computeNextDialogLayout(newPage);
      });
    }
  };

  const pageDown = () => {
    setFormState((prevState) => {
      const newPage = Math.max(prevState.currentPageIndex - 1, initialPageIndex);
      getOnEntryFunctionFromPage(newPage)()
        .then(() => {})
        .catch(() => {});
      return computeNextDialogLayout(newPage);
    });
  };

  const nextFormPage = Array.isArray(children) ? children[formState.currentPageIndex] : children;
  const currentFormPage = isBusy ? (
    <BusyPage />
  ) : (
    <div data-testid={nextFormPage.props.testId ?? "multi-form-page"}>{nextFormPage}</div>
  );

  return (
    <Modal
      data-testid={testId ?? "multi-page-form-modal"}
      closable
      onClose={closeForm}
      open={isOpen}
      closeOnBackdropClick
      heading={formHeading}
      primaryButtonLabel={formState.primaryButtonLabel}
      onBackButtonClick={pageDown}
      onPrimaryButtonClick={() => {
        if (formState.currentPageIndex === lastPageIndex) {
          onConfirmed();
        } else {
          pageUp();
        }
      }}
      secondaryButtonLabel={formState.secondaryButtonLabel}
      onSecondaryButtonClick={() => {
        if (formState.currentPageIndex === initialPageIndex) {
          closeForm();
          return;
        }
        pageDown();
      }}
    >
      {currentFormPage}
    </Modal>
  );
}

MultiPageFormComponent.FormPage = FormPageComponent;

export const MultiPageForm = MultiPageFormComponent;
