export enum PlatformEnvironment {
  Prod = "PROD",
  Test = "TEST"
}

export interface Platform {
  id: string;
  name: string;
  environment: PlatformEnvironment;
  description?: string;
  operatorId: string;
  clientId?: string;
  workingVersionsRequested?: boolean;
}
