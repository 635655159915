import React, { ReactNode } from "react";
import { FjdBadge } from "fjd-react-components";

export interface Label {
  key: string;
  displayName: string;
}

interface BadgeListProps {
  children?: ReactNode;
  labels: Array<Label>;
  clickOnBadge: (label: Label) => React.MouseEventHandler<HTMLButtonElement>;
  hasRemoveIcon: boolean;
}

export function BadgeList({ children, labels, clickOnBadge, hasRemoveIcon }: BadgeListProps) {
  return (
    <div className="tag-list">
      {labels.map((label) => (
        <FjdBadge
          data-testid={label.displayName}
          appearance="primary"
          key={label.key}
          onClick={clickOnBadge(label)}
          label={label.displayName}
          deletable={hasRemoveIcon}
        />
      ))}
      {children}
    </div>
  );
}
