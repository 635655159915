import React, { useState } from "react";
import { FjdButton, FjdTooltip } from "fjd-react-components";
import { useMediaQuery } from "react-responsive";
import "./OnlineServiceList.css";
import classNames from "classnames";
import { FjdIconGlyph } from "fjd-react-components/build/components/Icon/Icon";
import { Stack } from "../Stack/Stack";
import { FlexSpacer } from "../FlexSpacer/FlexSpacer";
import { FjdDeviceDisplay } from "../../utils/FjdDeviceDisplay";
import { InlineDivider } from "../TextAlignment/TextAlignment";
import { MktBadge } from "../Badge/MktBadge";

const INITIAL_PAGE = 1;

function Headline({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
}

function List({
  children,
  visibleItems,
  loadAdditionItemsLabel
}: {
  children: React.ReactNode | React.ReactNode[];
  visibleItems: number;
  loadAdditionItemsLabel: string;
}) {
  const [onlineServicePage, setOnlineServicePage] = useState(INITIAL_PAGE);

  return (
    <div>
      {children instanceof Array && children.slice(0, visibleItems * onlineServicePage)}
      <div className="load-button-container">
        {visibleItems > (children instanceof Array && children.length) ? undefined : (
          <FjdButton
            type="button"
            onClick={() => setOnlineServicePage(onlineServicePage + 1)}
            label={loadAdditionItemsLabel}
          />
        )}
      </div>
    </div>
  );
}

interface ListItemProps {
  listItemKey: string;
  avatar?: React.JSX.Element;
  headline: string;
  subHeadlines: string[];
  subHeadlineBadges?: string[];
  isEnabled: boolean;
  previewButtonTooltipLabel: string;
  onPreviewButtonClick: (() => void) | undefined;
  onDetailsButtonClick: () => void;
  detailButtonTooltipLabel: string;
  preparationTooltipLabel: string;
}

function ListItem({
  headline,
  avatar,
  subHeadlines,
  subHeadlineBadges,
  isEnabled,
  listItemKey,
  onPreviewButtonClick,
  onDetailsButtonClick,
  previewButtonTooltipLabel,
  detailButtonTooltipLabel,
  preparationTooltipLabel
}: ListItemProps) {
  const isMobile = useMediaQuery({ maxWidth: FjdDeviceDisplay.M });
  const listItemStyle = classNames({
    "result-list-disabled": isEnabled,
    "result-list-border-4Sides": true
  });
  const listItemButtonStyle = classNames("horizontal-flex-container", { "listitem-button-mobile": isMobile });

  return (
    <Stack className={listItemStyle} key={listItemKey} orientation="horizontal">
      {avatar}
      <div>
        <h3>{headline}</h3>
        <Stack spacing={isMobile ? "xs" : "s"} orientation="horizontal">
          {subHeadlineBadges ? subHeadlineBadges.map((badge) => <MktBadge label={badge} key={badge} />) : []}
          <InlineDivider
            elements={subHeadlines.map((value) => (
              <div className="online-list-item__subline-item" key={value}>
                {value}
              </div>
            ))}
            appearance="light"
            divider=","
          />
        </Stack>
      </div>
      <FlexSpacer />
      <Stack className={listItemButtonStyle} spacing="none" orientation="horizontal">
        {!isEnabled ? (
          <>
            <FjdTooltip placement="left" tooltip={<div>{previewButtonTooltipLabel}</div>}>
              {onPreviewButtonClick && (
                <FjdButton
                  onClick={onPreviewButtonClick}
                  testId={`${listItemKey}-preview`}
                  label=""
                  appearance="primary-link"
                  iconRight="view"
                  hideLabel
                />
              )}
            </FjdTooltip>
            <FjdTooltip placement="left" tooltip={<div>{detailButtonTooltipLabel}</div>}>
              <FjdButton
                onClick={onDetailsButtonClick}
                testId={`${listItemKey}-details`}
                label=""
                appearance="primary-link"
                iconRight="information-outline"
                hideLabel
              />
            </FjdTooltip>
          </>
        ) : (
          <>
            {["view", "information-outline"].map((icon) => (
              <FjdTooltip key={icon} placement="auto" tooltip={<div>{preparationTooltipLabel}</div>}>
                <FjdButton label="" disabled appearance="primary-link" iconRight={icon as FjdIconGlyph} hideLabel />
              </FjdTooltip>
            ))}
          </>
        )}
      </Stack>
    </Stack>
  );
}

interface OnlineServiceListProps {
  children: React.ReactNode;
}

OnlineServiceList.ListItem = ListItem;
OnlineServiceList.List = List;
OnlineServiceList.Headline = Headline;

export function OnlineServiceList({ children }: OnlineServiceListProps) {
  return <Stack spacing="s">{children}</Stack>;
}
