import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FjdBackdrop,
  FjdButton,
  FjdButtonSelectGroup,
  FjdCheckbox,
  FjdFileInput,
  FjdFilePreview,
  FjdFilePreviewList,
  FjdFormControl,
  FjdSpinner,
  FjdTextInput,
  FjdTooltip
} from "fjd-react-components";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Modal } from "../../../../../../common/components/Modal/Modal";
import { Stack } from "../../../../../../common/components/Stack/Stack";
import {
  createNewOnlineDienst,
  extractAndValidateMetaInfo
} from "../../../../../api/onlinedienste/onlineServiceTemplateApi";
import { useNotification } from "../../../../../../common/libs/notifications/hooks/useNotification";
import { KeyValueList } from "../../../../../../common/components/KeyValueList/KeyValueList";
import { HeadlineWithContent, WordDivider } from "../../../../../../common/components/TextAlignment/TextAlignment";
import { fetchLeikaData, fetchOzgData } from "../../../../../api/catalog/catalogApi";
import { removeAllWhitespacesFrom } from "../../../../../../common/utils/StringUtils";
import { checkFileType } from "../../../../../../common/utils/validationUtils";
import { VALID_FILE_TYPES } from "./NewWorkingVersionDialog";

interface NewArbeitsVersionDialogResult {
  onlineDienstPackage: File | null;
  title: string;
  fimId?: string;
  leikaIds?: string;
  ozgId: string | undefined;
  previewLink: string;
  languages: string;
  herstellerVersion: string;
}

interface NewOnlineDienstDialogResult {
  kennung: string;
  releaseNote?: string;
  releaseNoteAttachments: File[] | null;
}

type DialogResults = NewArbeitsVersionDialogResult & NewOnlineDienstDialogResult;

interface NewOnlineDienstDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirmed: (templateId: string) => void;
}

interface DialogState {
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
  page: number;
}

const initialDialogResultData: DialogResults = {
  herstellerVersion: "",
  languages: "",
  onlineDienstPackage: null,
  ozgId: "",
  releaseNoteAttachments: null,
  title: "",
  previewLink: "",
  kennung: "",
  fimId: "",
  releaseNote: "",
  leikaIds: ""
};

export function NewOnlineServiceDialog({ isOpen, onClose, onConfirmed }: NewOnlineDienstDialogProps) {
  const pages = 2;
  const { t } = useTranslation();
  const continueButtonLabel = t("onlineServiceTemplates.newOnlineServiceDialog.primaryButtonPage1");
  const confirmationButtonLabel = t("onlineServiceTemplates.newOnlineServiceDialog.primaryButtonConfirmation");
  const editButtonLabel = t("onlineServiceTemplates.newOnlineServiceDialog.editButtonLabel");
  const cancelButtonLabel = t("onlineServiceTemplates.newOnlineServiceDialog.cancelButton");
  const initalDialogState = {
    page: 1,
    primaryButtonLabel: continueButtonLabel,
    secondaryButtonLabel: cancelButtonLabel
  } as DialogState;
  const [isIdentifierRequired, setIsIdentifierRequired] = useState(false);
  const [dialogResult, setDialogResult] = useState<DialogResults>(initialDialogResultData);
  const [isTemplateIdEqualFimId, setIsTemplateIdEqualFimId] = useState(true);
  const [isLeikaIdAvailable, setIsLeikaIdAvailable] = useState(true);
  const [isOzgIdAvailable, setIsOzgIdAvailable] = useState(true);
  const [diagLogState, setDialogState] = useState<DialogState>(initalDialogState);
  const [uploadedReleaseNoteFiles, setUploadedReleaseNoteFiles] = useState<File[]>([]);
  const { notifyUserFromResponse, notifyUser } = useNotification();

  const onChange = (newOnlineDienstDialogResult: Partial<DialogResults>) =>
    setDialogResult((prevState) => ({
      ...prevState,
      ...newOnlineDienstDialogResult
    }));

  const handleReleaseNoteFileUpload = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = changeEvent.target;

    if (files) {
      const areFilesValid = checkFileType(files, VALID_FILE_TYPES);
      if (areFilesValid) {
        const newFiles = Array.from(files);
        setUploadedReleaseNoteFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setDialogResult((prevState) => ({
          ...prevState,
          releaseNoteAttachments: [...(prevState.releaseNoteAttachments ?? []), ...newFiles]
        }));
      } else {
        notifyUser({
          intent: "error",
          title: t("common.wrongFileType", { fileType: ".jpeg, .png, .pdf, .txt, .zip, .xml" })
        });
      }
    }
  };

  const { mutate: extractMetaInfoFromOnlineDienstPackageNow, isLoading: isExtractingMetaData } = useMutation({
    mutationKey: [extractAndValidateMetaInfo],
    mutationFn: extractAndValidateMetaInfo,
    onSuccess: ({ data }, variables) => {
      onChange({
        herstellerVersion: data.version,
        title: data.title,
        languages: data.languages,
        onlineDienstPackage: variables
      });
    },
    onSettled: notifyUserFromResponse()
  });

  const { data: leikaEntities, refetch: fetchLeikaEntitesByIds } = useQuery({
    queryKey: [fetchLeikaData.name, dialogResult?.leikaIds],
    queryFn: () => fetchLeikaData(removeAllWhitespacesFrom(dialogResult?.leikaIds ?? "")),
    onSuccess: (data) => {
      // BE found an ozg id related to the given leika ids
      if (data?.ozgEntity !== undefined && data?.ozgEntity !== null) {
        const ozgId = data.ozgEntity.id;
        setDialogResult((prevState) => ({
          ...prevState,
          ozgId
        }));
      }
    },
    enabled: false
  });

  const { data: ozgEntity, refetch: fetchOzgEntitiesById } = useQuery({
    queryKey: [fetchOzgData.name, dialogResult.ozgId],
    queryFn: () => fetchOzgData(dialogResult.ozgId ?? ""),
    enabled: false
  });

  const resetDialog = () => {
    setDialogState(initalDialogState);
    setDialogResult({} as DialogResults);
    setIsLeikaIdAvailable(true);
    setIsOzgIdAvailable(true);
    setIsTemplateIdEqualFimId(true);
    setUploadedReleaseNoteFiles([]);
  };

  const { mutate: createOnlineDienst, isLoading: isSendingForm } = useMutation({
    mutationKey: [createNewOnlineDienst],
    mutationFn: createNewOnlineDienst,
    onSuccess: (templateId) => {
      resetDialog();
      onConfirmed(templateId);
    },
    onSettled: notifyUserFromResponse({
      successMessage: t("onlineServiceTemplates.newOnlineServiceDialog.successMessage")
    })
  });

  const onConfirmation = () => {
    const { fimId, leikaIds, ozgId, releaseNote, onlineDienstPackage, releaseNoteAttachments } = dialogResult;

    if (onlineDienstPackage) {
      createOnlineDienst({
        newOnlineServiceProps: {
          identifier: dialogResult.kennung,
          previewLink: dialogResult.previewLink,
          leikaIds: leikaIds?.replaceAll(" ", "").split(",") ?? [],
          ozgId,
          fimId,
          releaseNote
        },
        onlineServicePackage: onlineDienstPackage,
        releaseNoteAttachments
      });
    }
  };

  const abortOrCloseDialog = () => {
    resetDialog();
    onClose();
  };

  function computeUpdateDialogView(nextPage: number) {
    const secondaryButtonLabel = nextPage === pages ? editButtonLabel : cancelButtonLabel;
    const primaryButtonLabel = nextPage === pages ? confirmationButtonLabel : continueButtonLabel;
    return { page: nextPage, primaryButtonLabel, secondaryButtonLabel } as DialogState;
  }

  const pageUp = () => {
    setDialogState((prevState) => {
      const newPage = Math.max(prevState.page + 1, initalDialogState.page);
      return computeUpdateDialogView(newPage);
    });
  };

  const pageDown = () => {
    setDialogState((prevState) => {
      const newPage = Math.max(prevState.page - 1, initalDialogState.page);
      return computeUpdateDialogView(newPage);
    });
    onChange({ ozgId: "" });
  };

  const page1 = (
    <Stack>
      <FjdFormControl inputId="identifier" label={t("common.identifier")}>
        <FjdTextInput
          id="newOnlineServiceIdentifier"
          testId="newOnlineServiceIdentifier"
          label={t("common.identifier")}
          placeholder={t("common.identifier")}
          value={dialogResult.kennung}
          onChange={(changeEvent) => onChange({ kennung: changeEvent.target.value })}
          required={isIdentifierRequired}
        />
      </FjdFormControl>
      <FjdCheckbox
        id="templateIdIsNotFimId"
        data-testid="templateIdIsNotFimId"
        label={t("onlineServiceTemplates.templateIdIsNotFimId")}
        defaultChecked={!isTemplateIdEqualFimId}
        onChange={(e) => {
          setIsTemplateIdEqualFimId(!e.target.checked);
          if (!e.target.checked) setDialogResult((prevState) => ({ ...prevState, fimId: undefined }));
        }}
      />
      {!isTemplateIdEqualFimId && (
        <FjdFormControl inputId="fimInput" label="FIM-ID" optional>
          <FjdTextInput
            id="newOnlineServicePreviewLink"
            testId="newOnlineServiceFimId"
            label={t("onlineServiceTemplates.fimdIdInput")}
            value={dialogResult.fimId}
            onChange={(changeEvent) => onChange({ fimId: changeEvent.target.value })}
          />
        </FjdFormControl>
      )}
      <FjdFormControl
        inputId="packagePath"
        label={t("onlineServiceTemplates.newOnlineServiceDialog.formItems.onlineServicePackageLabel")}
      >
        {!dialogResult.onlineDienstPackage ? (
          <FjdFileInput
            id="newOnlineServicePackage"
            data-testid="newOnlineServicePackage"
            label={t("common.selectFileButtonLabel")}
            onChange={(changeEvent) => {
              const file = changeEvent.target.files?.item(0);
              if (file) {
                extractMetaInfoFromOnlineDienstPackageNow(file);
              }
            }}
          />
        ) : (
          <FjdFilePreview
            onDelete={() =>
              onChange({ onlineDienstPackage: undefined, title: "", herstellerVersion: "", languages: "" })
            }
            title={dialogResult.onlineDienstPackage ? dialogResult.onlineDienstPackage.name : ""}
          />
        )}
      </FjdFormControl>
      <FjdFormControl optional inputId="releaseNote" label={t("common.releaseNote")}>
        <FjdTextInput
          testId="newOnlineServiceDialogReleaseNote"
          id="releaseNote"
          onChange={(changeEvent) => {
            onChange({ releaseNote: changeEvent.target.value });
            if (changeEvent.target.value.length >= 1000) {
              notifyUser({ intent: "error", title: t("common.maxAmountCharaktersReached") });
            }
          }}
          placeholder="Release-Note..."
          value={dialogResult.releaseNote}
          rows={3}
          maxLength={1000}
        />
      </FjdFormControl>
      <FjdFormControl optional label={t("common.releaseNoteAttachment")}>
        {uploadedReleaseNoteFiles.length === 0 ? (
          <FjdFileInput
            id="newOnlineServiceReleaseNoteFileUpload"
            data-testid="newOnlineServiceDialogNoteFileUpload"
            label={t("common.selectFileButtonLabel")}
            onChange={handleReleaseNoteFileUpload}
            multiple
          />
        ) : (
          <Stack>
            <FjdFilePreviewList
              items={uploadedReleaseNoteFiles?.map(
                (file) =>
                  ({
                    icon: "document",
                    onDelete: () => {
                      const remainingFiles = uploadedReleaseNoteFiles.filter(
                        (uploadedFile) => uploadedFile.name !== file.name
                      );
                      onChange({
                        releaseNoteAttachments: remainingFiles
                      });
                      setUploadedReleaseNoteFiles(remainingFiles);
                    },
                    title: file.name
                  } ?? [])
              )}
            />
            <FjdFileInput
              id="newOnlineServiceReleaseNoteFileUpload"
              data-testid="newOnlineServiceReleaseNoteFileUpload"
              label={t("common.selectNextFile")}
              onChange={handleReleaseNoteFileUpload}
              appearance="outline"
              multiple
            />
          </Stack>
        )}
      </FjdFormControl>
      <FjdFormControl optional inputId="previewLink" label="VorschauLink">
        <FjdTextInput
          id="newOnlineServicePreviewLink"
          testId="newOnlineServicePreviewLink"
          label={t("onlineServiceTemplates.previewLink")}
          placeholder={t("onlineServiceTemplates.previewLink")}
          value={dialogResult.previewLink}
          onChange={(changeEvent) => onChange({ previewLink: changeEvent.target.value })}
        />
      </FjdFormControl>
      <FjdFormControl inputId="leikaIdFormLabel" label={t("onlineServiceTemplates.leikaIdFormLabel")}>
        <FjdButtonSelectGroup
          size="m"
          id="leikaIdSwitch"
          onChange={(checked) => {
            setIsLeikaIdAvailable(checked.target.value === "yes");
            if (checked.target.value === "no") {
              setDialogResult((prevState) => ({ ...prevState, leikaIds: undefined }));
            } else if (checked.target.value === "yes") {
              setDialogResult((prevState) => ({ ...prevState, ozgId: undefined }));
            }
          }}
          options={[
            {
              text: t("onlineServiceTemplates.button.yes"),
              value: "yes",
              selected: isLeikaIdAvailable
            },
            {
              text: t("onlineServiceTemplates.button.no"),
              value: "no",
              selected: !isLeikaIdAvailable
            }
          ]}
        />
        {isLeikaIdAvailable && (
          <>
            <FjdTextInput
              id="leikaIdInput"
              testId="leikaIdInput"
              label={t("onlineServiceTemplates.leikaIdInput")}
              placeholder={t("onlineServiceTemplates.leikaIdInput")}
              value={dialogResult.leikaIds}
              onChange={(changeEvent) => onChange({ leikaIds: changeEvent.target.value })}
            />
            <FjdTooltip placement="auto" tooltip={<div>{t("onlineServiceTemplates.leikaIdInputTooltip")}</div>}>
              <FjdButton label="" appearance="primary-link" iconRight="information-outline" hideLabel />
            </FjdTooltip>
          </>
        )}
      </FjdFormControl>
      {!isLeikaIdAvailable && (
        <FjdFormControl inputId="ozgIdFormLabel" label={t("onlineServiceTemplates.ozgIdFormLabel")}>
          <FjdButtonSelectGroup
            id="ozgIdSwitch"
            onChange={(checked) => {
              setIsOzgIdAvailable(checked.target.value === "yes");
              if (checked.target.value === "no") setDialogResult((prevState) => ({ ...prevState, ozgId: undefined }));
            }}
            options={[
              {
                text: t("onlineServiceTemplates.button.yes"),
                value: "yes",
                selected: isOzgIdAvailable
              },
              {
                text: t("onlineServiceTemplates.button.no"),
                value: "no",
                selected: !isOzgIdAvailable
              }
            ]}
          />
          {isOzgIdAvailable && (
            <FjdTextInput
              id="ozgIdInput"
              testId="ozgIdInput"
              label={t("onlineServiceTemplates.ozgIdInput")}
              placeholder={t("onlineServiceTemplates.ozgIdInput")}
              value={dialogResult.ozgId}
              onChange={(changeEvent) => onChange({ ozgId: changeEvent.target.value })}
            />
          )}
        </FjdFormControl>
      )}
    </Stack>
  );

  const page2 = (
    <KeyValueList heading={t("onlineServiceInformation.businessInformationContent.classification")}>
      <KeyValueList.Section key="summary">
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.identifierLabel")}
          value={dialogResult.kennung}
        />
        {dialogResult.fimId ? (
          <KeyValueList.ListItem
            term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.fimLabel")}
            value={dialogResult.fimId}
          />
        ) : (
          /* eslint-disable-next-line react/jsx-no-useless-fragment */
          <></>
        )}
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.zipFileLabel")}
          value={dialogResult.onlineDienstPackage?.name ?? "-"}
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.versionLabel")}
          value={dialogResult.herstellerVersion}
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.titleLabel")}
          value={dialogResult.title}
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.newOnlineServiceDialog.summary.languagesLabel")}
          value={<WordDivider words={dialogResult.languages?.split(",") ?? []} />}
        />
        <KeyValueList.ListItem term={t("common.releaseNote")} value={dialogResult.releaseNote || "-"} />
        <KeyValueList.ListItem
          term={t("common.releaseNoteAttachment")}
          value={
            dialogResult.releaseNoteAttachments && dialogResult.releaseNoteAttachments.length > 0
              ? dialogResult.releaseNoteAttachments.map((file) => file.name).join(", ")
              : "-"
          }
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.previewLink")}
          value={
            dialogResult.previewLink === undefined || dialogResult.previewLink === "" ? "-" : dialogResult.previewLink
          }
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.leikaIdFormLabel")}
          value={
            dialogResult.leikaIds ? (
              <HeadlineWithContent
                text={[
                  ...dialogResult.leikaIds.split(",").map((id) => ({
                    headline: id,
                    content: leikaEntities?.leikaEntities.find((entity) => entity.id === id.trim())?.description ?? ""
                  }))
                ]}
              />
            ) : (
              "-"
            )
          }
        />
        <KeyValueList.ListItem
          term={t("onlineServiceTemplates.ozgIdFormLabel")}
          value={
            dialogResult.ozgId ? (
              <HeadlineWithContent
                text={[
                  {
                    headline: dialogResult.ozgId,
                    content: ozgEntity?.description ?? leikaEntities?.ozgEntity?.description ?? ""
                  }
                ]}
              />
            ) : (
              "-"
            )
          }
        />
      </KeyValueList.Section>
    </KeyValueList>
  );

  function getCurrentPage(pageIndex: number) {
    if (isExtractingMetaData || isSendingForm) {
      return (
        <div className="mkt-modal-backdrop">
          <FjdBackdrop>
            <FjdSpinner size="m" />
          </FjdBackdrop>
        </div>
      );
    }
    switch (pageIndex) {
      case 2:
        return page2;
      default:
        return page1;
    }
  }

  return (
    <Modal
      data-testid="new-online-service-dialog"
      closable
      onClose={abortOrCloseDialog}
      open={isOpen}
      closeOnBackdropClick
      heading={t("onlineServiceTemplates.newOnlineServiceDialog.heading")}
      primaryButtonLabel={diagLogState.primaryButtonLabel}
      onBackButtonClick={() => {
        pageDown();
      }}
      onPrimaryButtonClick={() => {
        const { onlineDienstPackage, kennung } = dialogResult;

        if (!onlineDienstPackage) {
          notifyUser({ intent: "error", title: t("onlineServiceTemplates.missingPackage") });
          return;
        }

        if (!kennung) {
          setIsIdentifierRequired(true);
          notifyUser({ intent: "error", title: t("onlineServiceTemplates.missingIdentifier") });
          return;
        }

        if (diagLogState.page === pages) {
          onConfirmation();
          return;
        }

        if (isLeikaIdAvailable) {
          fetchLeikaEntitesByIds().catch(() => {});
        } else if (isOzgIdAvailable) {
          fetchOzgEntitiesById().catch(() => {});
        }

        pageUp();
      }}
      secondaryButtonLabel={diagLogState.secondaryButtonLabel}
      onSecondaryButtonClick={() => {
        if (diagLogState.page === initalDialogState.page) {
          abortOrCloseDialog();
          return;
        }
        pageDown();
      }}
    >
      {getCurrentPage(diagLogState.page)}
    </Modal>
  );
}
